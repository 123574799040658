var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-val-inspeccion","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Validación Inspección")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-body p-0"},[(
                Object.keys(_vm.inspeccionesSupport).length > 0 &&
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.validarInspeccion'
                  )
              )?_c('div',[_c('div',{staticClass:"alert",class:_vm.inspeccionesSupport.estado != 3
                    ? 'alert-default-success'
                    : 'alert-default-warning'},[_c('h5',[_c('i',{staticClass:"icon",class:_vm.inspeccionesSupport.estado != 3
                        ? 'fas fa-check'
                        : 'fas fa-exclamation-triangle'}),_c('strong',[_vm._v("Atención.")])]),_c('p',{staticClass:"pt-2 mt-2"},[_vm._v(" "+_vm._s(_vm.inspeccionesSupport.estado != 3 ? "Se encuentra con una inspección vigente." : "Se encuentra con una inspección vigente, pero con novedad.")+" ")])]),_c('table',{staticClass:"table table-striped table-bordered table-hover table-md"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.inspeccionesSupport.nFormato)+" "),_c('p',[_vm._v(" Fecha Inicial : "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(_vm.inspeccionesSupport.fecha_inicial))]),_vm._v(" - Fecha Final : "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(_vm.inspeccionesSupport.fecha_final))])])]),_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-danger",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.linkIn + _vm.inspeId,"target":"_blank"}},[_c('i',{staticClass:"fas fa-file-pdf"})])])])])])]):(
                Object.keys(_vm.inspeccionesSupport).length > 0 &&
                  !_vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.validarInspeccion'
                  )
              )?_c('div',[_vm._m(1)]):_c('div',[(
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                  ) ||
                    _vm.$store.getters.can(
                      'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                    )
                )?_c('div',[(_vm.viaje.val_inspeccion == 1)?_c('div',{staticClass:"alert alert-default-primary"},[_vm._m(2)]):(_vm.viaje.val_inspeccion == 2)?_c('div',{staticClass:"alert alert-default-success"},[_vm._m(3)]):(_vm.viaje.val_inspeccion == 3)?_c('div',{staticClass:"alert alert-default-danger"},[_vm._m(4)]):_c('div',[_c('div',{staticClass:"alert alert-default-warning"},[_vm._m(5),_c('p',[_vm._v(" No se encuentra con una inspección vigente. "),(_vm.viaje.val_inspeccion == null)?_c('span',{staticClass:"bagde bg-gradient-info btn-sm",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.mailSolicitar(_vm.viaje.id)}}},[_c('i',{staticClass:"fas fa-envelope"}),_vm._v(" Solicitar Inspección ")]):_vm._e()])]),(_vm.correosEmpresa.length && _vm.correosEmpresa.length > 0)?_c('div',[_c('table',{staticClass:"table table-striped table-bordered table-hover table-md"},[_c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.viaje.transportadora.razon_social)+" ")])]),_vm._m(6)]),_c('tbody',_vm._l((_vm.correosEmpresa),function(correoEmpresa,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(correoEmpresa.correo)+" ")])])}),0)])]):_c('span',{staticClass:"badge bg-warning text-center"},[_vm._v(" No se encontrarón correos asociados, comunicarse con los administradores. ")])]),_c('table',{directives:[{name:"show",rawName:"v-show",value:(_vm.viaje.val_inspeccion == 2),expression:"viaje.val_inspeccion == 2"}],staticClass:"table table-striped table-bordered table-hover table-md"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Inspección")]),(
                          _vm.viaje.inspecciones && _vm.viaje.inspecciones.length > 0
                        )?_c('th',{staticClass:"text-center"},[_vm._v(" PDF ")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])]),(
                      (_vm.$store.getters.can(
                        'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                      ) ||
                        _vm.$store.getters.can(
                          'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                        )) &&
                        (!_vm.viaje.inspecciones ||
                          _vm.viaje.inspecciones.length == 0)
                    )?_c('tbody',[_c('td',[_vm._v("Inspección Preoperacional")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-info",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.inspeccionPreoperacional(_vm.viaje)}}},[_c('i',{staticClass:"fas fa-plus-square"})])])]):(
                      _vm.$store.getters.can(
                        'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                      ) &&
                        _vm.viaje.inspecciones &&
                        _vm.viaje.inspecciones.length > 0
                    )?_c('tbody',[_c('td',[_vm._v(" "+_vm._s(_vm.viaje.inspecciones[0].formato_inspeccion.nombre)+" "),_c('p',[_vm._v(" Fecha Inicial : "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(_vm.viaje.inspecciones[0].fecha_inicio)+" "+_vm._s(_vm.viaje.inspecciones[0].hora_inicio))])])]),_c('td',[_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.verPDF(_vm.viaje.inspecciones[0].id)}}},[_c('i',{staticClass:"fas fa-file-pdf"})])]),_c('td',[_c('div',{staticClass:"custom-control custom-switch custom-switch-off-danger custom-switch-on-success"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.verifica),expression:"form.verifica"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"vencimiento","disabled":!_vm.habilitarCheck},domProps:{"checked":Array.isArray(_vm.form.verifica)?_vm._i(_vm.form.verifica,null)>-1:(_vm.form.verifica)},on:{"change":function($event){var $$a=_vm.form.verifica,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "verifica", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "verifica", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "verifica", $$c)}}}}),_vm._m(7),(!_vm.habilitarCheck)?_c('span',{staticClass:"badge bg-warning"},[_vm._v("Abrir Pdf")]):_c('span',{staticClass:"badge bg-success"},[_vm._v("Ya puedes Verificar")])])])]):(
                      _vm.$store.getters.can(
                        'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                      ) && _vm.viaje.inspecciones.length > 0
                    )?_c('tbody',[_c('td',[_vm._v(" "+_vm._s(_vm.viaje.inspecciones[0].formato_inspeccion.nombre)+" "),_c('p',[_vm._v(" Fecha Inicial : "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(_vm.viaje.inspecciones[0].fecha_inicio)+" "+_vm._s(_vm.viaje.inspecciones[0].hora_inicio))])])]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.verPDF(_vm.viaje.inspecciones[0].id)}}},[_c('i',{staticClass:"fas fa-file-pdf"})])]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm bg-navy",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.edit(_vm.viaje, _vm.viaje.inspecciones[0].id)}}},[_c('i',{staticClass:"fas fa-edit"})])])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.habilitarCheck),expression:"habilitarCheck"}],staticClass:"col-12"},[_c('label',{attrs:{"for":""}},[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observacion),expression:"form.observacion"}],staticClass:"form-control form-control-sm",class:_vm.form.observacion == null || _vm.form.observacion == ''
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.observacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observacion", $event.target.value)}}})])]):_c('div',[_vm._m(8)])])])]),_c('div',{staticClass:"modal-footer"},[(Object.keys(_vm.inspeccionesSupport).length > 0)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.crearInspeccion()}}},[_vm._v(" "+_vm._s(_vm.inspeccionesSupport.estado != 3 ? "Crear Inspección" : "Enviar Correo")+" ")]):_vm._e(),(
              _vm.form.observacion &&
                _vm.$store.getters.can(
                  'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                )
            )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Aprobar Inspección ")]):_vm._e(),_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cerrar ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Inspección")]),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-default-warning"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_c('strong',[_vm._v("Atención.")])]),_c('p',[_vm._v("No existe solicitud de inspección.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-envelope-open"}),_c('strong',[_vm._v("Atención.")]),_c('p',{staticClass:"mt-2 pt-2"},[_vm._v("Solicitud inspección enviada.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-check"}),_c('strong',[_vm._v("Atención.")]),_c('p',{staticClass:"mt-2 pt-2"},[_vm._v("Solicitud inspección aceptada.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-times-circle"}),_c('strong',[_vm._v("Atención.")]),_c('p',{staticClass:"mt-2 pt-2"},[_vm._v("Solicitud inspección rechazada.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_c('strong',[_vm._v("Atención.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("#")]),_c('th',{staticClass:"text-center"},[_vm._v("Correos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"custom-control-label",attrs:{"for":"vencimiento"}},[_vm._v("Verificar "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-default-warning"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_c('strong',[_vm._v("Atención.")]),_c('p',{staticClass:"mt-2 pt-2"},[_vm._v(" No cuenta con permisos, Por favor comunicarse con los administradores. ")])])])
}]

export { render, staticRenderFns }