<template>
  <div>
    <!-- Modal Adjunto Foto-->
    <div
      class="modal fade"
      id="modal-escaner-guia"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="width: 380px">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Escáner Guía</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal4"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="card-body rounded"
              :class="
                file_pdf
                  ? 'alert-default-success border border-success'
                  : !file_pdf && escaner_guia
                  ? 'border border-primary'
                  : 'alert-default-warning border border-warning'
              "
            >
              <div v-if="escaner_guia">
                <div class="row">
                  <div class="col-md-6">
                    <b>Guía: {{ numero_guia }}</b>
                    <br />
                    <b>Placa: {{ placa }}</b>
                  </div>
                  <div class="col-md-6 text-right">
                    <button
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.eliminarEscanerGuia'
                        )
                      "
                      type="button"
                      class="btn btn-sm bg-danger"
                      @click="destroyfile"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  !escaner_guia &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cargarEscaner'
                    )
                "
              >
                <label for="link_pdf">Subir PDF</label>
                <br />
                <div class="form-group">
                  <input
                    type="file"
                    id="link_pdf"
                    ref="link_pdf"
                    class="form-control-file"
                    accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                    style="min-width: 320px;"
                    :class="
                      file_pdf
                        ? 'btn btn-sm border border-success alert-success'
                        : 'btn btn-sm border border-warning alert-warning'
                    "
                    @change="elegirDirectorio()"
                  />
                </div>
                <div
                  v-if="loading"
                  class="spinner-border text-success"
                  role="status"
                ></div>
                <b v-if="loading" class="text-success"> Cargando...</b>
                <button
                  v-if="!loading && file_pdf"
                  id="guardar_documento"
                  type="button"
                  class="btn btn-lg btn-success"
                  data-html="true"
                  title="Guardar Documento"
                  data-toggle="tooltip"
                  @click="saveFiles()"
                >
                  <span>Cargar</span>
                </button>
              </div>
              <div class="progress mt-3" v-if="loading">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  :class="
                    progress < 50
                      ? 'bg-danger'
                      : progress > 50 && progress < 98
                      ? 'bg-warning'
                      : 'bg-success'
                  "
                  role="progressbar"
                  :style="'width:' + progress + '%'"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "VentanaOperacionesEscaner",
  components: {},
  data() {
    return {
      cargar: null,
      progress: null,
      loading: false,
      imagenCargada: null,
      escaner_guia: null,
      file_pdf: null,
      viaje_id: null,
      viaje_type: null,
      numero_guia: null,
      escaner_id: null,
      link: null,
      placa: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    destroyfile() {
      let data = {
        "0": {
          id: this.escaner_id,
          viaje_id: this.viaje_id,
          viaje_type: this.viaje_type,
          link: this.link,
          estado: 2,
        },
      };
      this.$swal({
        title: "Esta seguro de eliminar este Archivo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .post(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/escanerGuias/descartarPdf",
              data
            )
            .then(() => {
              this.escaner_guia = null;
              this.link = null;
              this.numero_guia = null;
              this.escaner_id = null;
              this.file_pdf = null;
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            });
        }
      });
    },

    elegirDirectorio() {
      this.file_pdf = this.$refs.link_pdf.files[0];
    },

    saveFiles() {
      var vm = this;
      vm.loading = true;
      let formData = new FormData();
      if (vm.file_pdf) {
        formData.append("name_pdf", vm.file_pdf.name.replace(/\.[^/.]+$/, ""));
        formData.append("link_pdf", vm.file_pdf);
        formData.append("viaje_id", this.viaje_id);
        formData.append("ext", "." + vm.file_pdf.name.split(".").pop());
        formData.append("viaje_type", this.viaje_type);
        formData.append("total", 0);
        axios
          .post(
            "api/hidrocarburos/ventanasEnturnamientoOperacion/escanerGuias/cargarArchivos",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                vm.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then((response) => {
            let icon = null;
            let title = null;
            let timer = null;
            if (response.data.length < 1 || response.data.length == undefined) {
              icon = "warning";
              title =
                "La guía " + vm.file_pdf.name + " no concuerda con el viaje.";
              timer = 4000;
            } else {
              vm.link = response.data[0].link;
              vm.escaner_guia = vm.uri_docs + response.data[0].link;
              vm.numero_guia = response.data[0].numero_guia;
              vm.escaner_id = response.data[0].id;
            }
            vm.file_pdf = null;
            vm.$refs.link_pdf.files = null;
            let inputPdf = document.getElementById("link_pdf");
            inputPdf.value = "";
            vm.progress == 100 ? (vm.loading = false) : (vm.loading = true);
            vm.$swal({
              icon: icon ? icon : "success",
              title: title ? title : "El archivo se cargó correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: timer ? timer : 3000,
              timerProgressBar: true,
            });
          })
          .catch(function(error) {
            vm.loading = false;
            vm.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        vm.loading = false;
        vm.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    llenar_modal_escaner_guia(viaje, viaje_type) {
      this.$parent.cargando = true;
      this.escaner_guia = null;
      this.link = null;
      this.numero_guia = null;
      this.escaner_id = null;
      this.viaje_id = null;
      this.placa = null;
      this.viaje_type = null;
      this.viaje_id = viaje.id;
      this.viaje_type = viaje_type;
      this.placa =
        this.viaje_type == "App\\Viaje"
          ? viaje.vehiculo.placa
          : this.viaje_type == "App\\DetGuiaRegalias"
          ? viaje.vehiculo
          : null;
      axios
        .get(
          "api/hidrocarburos/ventanasEnturnamientoOperacion/escanerGuias?page=" +
            1,
          {
            params: { viaje_id: viaje.id, viaje_type: viaje_type },
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.escaner_guia = this.uri_docs + response.data[0].escaner_guia;
            this.link = response.data[0].link;
            this.numero_guia =
              this.viaje_type == "App\\Viaje"
                ? viaje.numero_guia
                : this.viaje_type == "App\\DetGuiaRegalias"
                ? viaje.numero_guia
                : response.data[0].numero_guia;
            this.escaner_id = response.data[0].id;
          }
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
