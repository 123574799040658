var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-validate-viaje","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Validar Programación")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"vehiculo"}},[_vm._v("Vehiculo")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                    _vm.$v.form.vehiculo_id.$invalid ? 'is-invalid' : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.vehiculos,"filterable":false},on:{"search":_vm.buscarVehiculos,"input":function($event){return _vm.selectVehiculo()}},model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"remolque"}},[_vm._v("Remolque")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                    _vm.$v.form.remolque_id.$invalid ? 'is-invalid' : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.remolques,"filterable":false},on:{"search":_vm.buscarRemolques,"input":function($event){return _vm.selectRemolque()}},model:{value:(_vm.remolque),callback:function ($$v) {_vm.remolque=$$v},expression:"remolque"}})],1)])])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                  !_vm.$v.form.vehiculo_id.$invalid ||
                    !_vm.$v.form.remolque_id.$invalid
                ),expression:"\n                  !$v.form.vehiculo_id.$invalid ||\n                    !$v.form.remolque_id.$invalid\n                "}],staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.validarProgrmacion()}}},[_c('i',{staticClass:"fas fa-check-double col-md-12"}),_c('strong',[_vm._v(" Validar")])])]),_c('div',{staticClass:"col-md-4"},[(
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.crearViaje'
                  ) && _vm.$parent.filtros.tipo_control == 1
                )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.crearViajeManual()}}},[_c('i',{staticClass:"fas fa-spell-check col-md-12"}),_c('strong',[_vm._v(" Viaje manual")])]):_vm._e()]),_c('div',{staticClass:"col-md-5"},[(
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.crearViajeRegalias'
                  ) && _vm.$parent.filtros.tipo_control == 3
                )?_c('button',{staticClass:"btn btn-dark",attrs:{"type":"button"},on:{"click":function($event){return _vm.crearViajeManualRegalias()}}},[_c('i',{staticClass:"fas fa-route col-md-12"}),_c('strong',[_vm._v(" Viaje Manual Regalias / Terceros")])]):_vm._e()])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }