<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-estado">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              {{ nombreVentana }} # {{ form_estado.id }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div
                  class="col-md-12"
                  v-if="opcionVentana == 2 || opcionVentana == 3"
                >
                  <label for="estado"> Tipo:</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form_estado.estadoLista"
                    @change="cambiarEstado"
                    :class="
                      $v.form_estado.estadoLista.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estados in listasForms.estados"
                      :key="estados.numeracion"
                      :value="estados.numeracion"
                    >
                      {{ estados.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="fecha_final"> Fecha final:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form_estado.fecha_final"
                    :class="
                      $v.form_estado.fecha_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div div class="col-md-6">
                  <label for="hora_final"> Hora:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form_estado.hora_final"
                    :class="
                      $v.form_estado.hora_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                  />
                </div>
              </div>
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength,helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "VentanaOperacionesViajesEstado",
  data() {
    return {
      form_estado: {},
      nombreVentana: null,
      opcionVentana: null,
      listasForms: {
        estados: [],
      },
    };
  },
  validations: {
    form_estado: {
      estadoLista: {
        required,
      },
      estado: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
      fecha_final: {
        required,
      },
      hora_final: {
        required,timeFormat,
      },
      entidad_id: {
        required,
      },
      entidad: {
        required,
      },
    },
  },
  methods: {
    cambiarEstado() {
      if (this.opcionVentana == 1 || this.opcionVentana == 2) {
        if (this.form_estado.estadoLista == 2) {
          this.form_estado.estado = 14;
        } else {
          this.form_estado.estado = 13;
        }
      }

      if (this.opcionVentana == 3) {
        if (this.form_estado.estadoLista == 2) {
          this.form_estado.estado = 3;
        } else {
          this.form_estado.estado = 7;
        }
      }
    },

    save() {
      if (!this.$v.form_estado.$invalid) {
        axios({
          method: "PUT",
          url:
            "/api/hidrocarburos/ventanasEnturnamientoOperacion/suspenderViaje",
          data: this.form_estado,
        })
          .then(() => {
            this.form_estado = {};
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se realizó el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },

    getEstadosAnulacionViaje() {
      axios.get("/api/lista/68").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEstadosAnulacionPostulacion() {
      axios.get("/api/lista/69").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenar_modal_estado(
      id,
      valor_ant,
      estadoCierre,
      nombreVentana,
      opcionVentana
    ) {
      if (opcionVentana == 1 || opcionVentana == 2) {
        this.form_estado = {
          entidad: "Viajes",
          estado: estadoCierre,
          entidad_id: id,
          id,
          valor_ant: valor_ant,
          user: this.$store.state.user.id,
        };
        this.nombreVentana = nombreVentana;
        this.opcionVentana = opcionVentana;
        if (opcionVentana == 1) {
          this.form_estado.estadoLista = 1;
        }
        this.getEstadosAnulacionViaje();
      }
      if (opcionVentana == 3) {
        this.form_estado = {
          entidad: "Postulacion",
          estado: estadoCierre,
          entidad_id: id,
          id,
          valor_ant: valor_ant,
          user: this.$store.state.user.id,
        };
        this.nombreVentana = nombreVentana;
        this.opcionVentana = opcionVentana;
        if (opcionVentana == 1) {
          this.form_estado.estadoLista = 1;
        }
        this.getEstadosAnulacionPostulacion();
      }
    },
  },
};
</script>
