<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-cambio-ventana"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
      v-if="viaje != null"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambiar Ventana</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="card-body"
              v-if="viaje.estado !== null && viaje.estado !== undefined"
            >
              <div class="row" v-if="accionVentana == 0">
                <div class="col-md-3"></div>
                <div class="col-md-6" v-if="viaje.estado === 5">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.cambiarVentana'
                      )
                    "
                    @click="selectVentana(1)"
                  >
                    Ventana cargue
                  </button>
                </div>
                <div class="col-md-6" v-if="viaje.estado === 10">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.cambiarVentana'
                      )
                    "
                    @click="selectVentana(2)"
                  >
                    Ventana descargue
                  </button>
                </div>
              </div>
              <div class="row" v-else>
                <div v-if="accionVentana == 2">
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento actual</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoDescargue"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      disabled="disabled"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento (cambio)</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoNueva"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      :options="listasForms.ventanasEnturneDescargue"
                      :filterable="false"
                    ></v-select>
                  </div>
                </div>
                <div v-if="accionVentana == 1">
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento actual</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoCargue"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      disabled="disabled"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento (cambio)</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoNueva"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      :options="listasForms.ventanasEnturneCargue"
                      :filterable="true"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" v-else>
              <p>
                <span class="badge badge-danger"> No se encuentran viajes</span>
              </p>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="accionVentana !== 0"
          >
            <div v-if="accionVentana == 2">
              <button
                type="button"
                class="btn btn-primary"
                v-show="
                  !$v.ventanaEnturnamientoNueva.$invalid &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cambiarVentana'
                    )
                "
                @click="cambiarVentanaDescargue()"
              >
                Cambiar
              </button>
            </div>
            <div v-if="accionVentana == 1">
              <button
                type="button"
                class="btn btn-primary"
                v-show="
                  !$v.ventanaEnturnamientoNueva.$invalid &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cambiarVentana'
                    )
                "
                @click="cambiarVentanaCargue()"
              >
                Cambiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "VentanaOperacionesCambioVentana",
  components: {
    vSelect,
  },
  data() {
    return {
      accionVentana: 0,
      viaje: null,
      form: {
        id: null,
      },
      listasForms: {
        ventanasEnturneDescargue: [],
        ventanasEnturneCargue: [],
        ventanasEnturne2: [],
      },
      ventanaEnturnamientoDescargue: [],
      ventanaEnturnamientoCargue: [],
      ventanaEnturnamientoNueva: [],
    };
  },
  validations: {
    ventanaEnturnamientoNueva: {
      required,
    },
  },
  methods: {
    llenar_modal_cambio_ventana(viaje, ventanasEnturne, ventanaEnturnamiento) {
      this.ventanaEnturnamientoNueva = null;
      this.accionVentana = 0;
      this.form.id = viaje.id;
      this.viaje = viaje;
      this.ventanaEnturnamientoCargue = viaje.ventana_cargue;
      this.listasForms.ventanasEnturneDescargue = ventanasEnturne;
      this.ventanaEnturnamientoDescargue = ventanaEnturnamiento;
      this.buscarVentanasEnturne(viaje.sitio_cargue_id);
    },

    selectVentana(ventana) {
      this.accionVentana = ventana;
    },

    buscarVentanasEnturne(sitio_id) {
      let me = this;
      axios
        .get("api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas", {
          params: {
            sitio_id: sitio_id,
            sitio_type: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturneCargue = respuesta;
        })

        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cambiarVentanaDescargue() {
      if (!this.$v.ventanaEnturnamientoNueva.$invalid) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamientoNueva.id;
        axios({
          method: "PUT",
          url:
            "api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarVentanaDescargue",
          data: this.form,
        }).then(() => {
          this.$parent.getIndex();
          this.$refs.closeModal1.click();
          this.$parent.$refs.VentanaOperacionesVentanas.selectVentanasEnturne();
          this.accionVentana = 0;
          this.ventanaEnturnamientoNueva = [];

          this.$swal({
            icon: "success",
            title: "Se actualizó la ventana de operación exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },

    cambiarVentanaCargue() {
      if (!this.$v.ventanaEnturnamientoNueva.$invalid) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamientoNueva.id;
        axios({
          method: "PUT",
          url:
            "api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarVentanaCargue",
          data: this.form,
        }).then(() => {
          this.$parent.getIndex();
          this.$refs.closeModal1.click();
          this.$parent.$refs.VentanaOperacionesVentanas.selectVentanasEnturne();
          this.accionVentana = 0;
          this.ventanaEnturnamientoNueva = [];

          this.$swal({
            icon: "success",
            title: "Se actualizó la ventana de operación exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },
  },
};
</script>
