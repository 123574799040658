<template>
  <div>
    <!-- Modal Adjunto Foto-->
    <div
      class="modal fade"
      id="modal-escaner-acta"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="width: 380px">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Escáner Acta Bono</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal4"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="card-body rounded"
              :class="
                file_pdf
                  ? 'alert-default-success border border-success'
                  : !file_pdf && escaner_acta
                  ? 'border border-primary'
                  : 'alert-default-warning border border-warning'
              "
            >
              <div v-if="escaner_acta">
                <div class="row">
                  <div class="col-md-6">
                    <b>Viaje: {{ viaje_id }}</b>
                    <br />
                    <b>Guía: {{ numero_guia }}</b>
                    <br />
                    <b>Placa: {{ placa }}</b>
                  </div>
                  <div class="col-md-6 text-right">
                    <a
                      :href="escaner_acta"
                      target="_blank"
                      class="btn btn-sm bg-danger mr-2"
                      ><i class="fas fa-file-pdf text-white"></i
                    ></a>
                    <button
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.deleteActa'
                        )
                      "
                      type="button"
                      class="btn btn-sm bg-danger"
                      @click="destroyfile"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  !escaner_acta &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.saveActa'
                    )
                "
              >
                <label for="link_pdf">Subir PDF</label>
                <br />
                <div class="form-group">
                  <input
                    type="file"
                    id="link_pdf"
                    ref="link_pdf"
                    style="min-width: 320px;"
                    :class="
                      file_pdf
                        ? 'btn btn-sm border border-success alert-success'
                        : 'btn btn-sm border border-warning alert-warning'
                    "
                    accept=".pdf, .PDF"
                    @change="elegirDirectorio()"
                  />
                </div>
                <div
                  v-if="loading"
                  class="spinner-border text-success"
                  role="status"
                ></div>
                <b v-if="loading" class="text-success"> Cargando...</b>
                <button
                  v-if="!loading && file_pdf"
                  id="guardar_documento"
                  type="button"
                  class="btn btn-lg btn-success"
                  data-html="true"
                  title="Guardar Documento"
                  data-toggle="tooltip"
                  @click="saveFiles()"
                >
                  <span>Cargar</span>
                </button>
              </div>
              <div class="progress mt-3" v-if="loading">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  :class="
                    progress < 50
                      ? 'bg-danger'
                      : progress > 50 && progress < 98
                      ? 'bg-warning'
                      : 'bg-success'
                  "
                  role="progressbar"
                  :style="'width:' + progress + '%'"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "VentanaOperacionesEscanerActa",
  components: {},
  data() {
    return {
      cargar: null,
      progress: null,
      loading: false,
      imagenCargada: null,
      escaner_acta: null,
      file_pdf: null,
      viaje_id: null,
      numero_guia: null,
      bono_guia_id: null,
      link: null,
      placa: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    destroyfile() {
      let data = {
        id: this.bono_guia_id,
        viaje_id: this.viaje_id,
        link: this.link,
      };
      this.$swal({
        title: "Esta seguro de eliminar este Archivo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .post(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/bonoGuia/descartarActa",
              data
            )
            .then(() => {
              this.$parent.getIndex();
              this.escaner_acta = null;
              this.link = null;
              this.bono_guia_id = null;
              this.numero_guia = null;
              this.file_pdf = null;
              this.$swal({
                icon: "success",
                title: "El Archivo se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            });
        }
      });
    },

    elegirDirectorio() {
      this.file_pdf = this.$refs.link_pdf.files[0];
    },

    saveFiles() {
      var vm = this;
      vm.loading = true;
      let formData = new FormData();
      if (vm.file_pdf) {
        formData.append("link_pdf", vm.file_pdf);
        formData.append("viaje_id", this.viaje_id);
        axios
          .post(
            "api/hidrocarburos/ventanasEnturnamientoOperacion/bonoGuia/saveActaBono",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                vm.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.escaner_acta = this.uri_docs + response.data.link_soporte;
              this.link = response.data.link_soporte;
              this.viaje_id = response.data.viaje_id;
              this.numero_guia = response.data.viaje.guia.numero;
              this.bono_guia_id = response.data.id;
            }
            vm.progress == 100 ? (vm.loading = false) : (vm.loading = true);
            this.$parent.getIndex();
            vm.$swal({
              icon: "success",
              title: "El Acta " + vm.file_pdf.name + " se cargó correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            vm.file_pdf = null;
            vm.$refs.link_pdf.files = null;
            let inputPdf = document.getElementById("link_pdf");
            inputPdf.value = "";
          })
          .catch(function(error) {
            vm.loading = false;
            vm.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        vm.loading = false;
        vm.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    llenar_modal_escaner_acta(viaje) {
      this.$parent.cargando = true;
      this.file_pdf = null;
      this.escaner_acta = null;
      this.link = null;
      this.viaje_id = null;
      this.bono_guia_id = null;
      this.placa = null;
      this.viaje_id = viaje.id;
      this.placa = viaje.vehiculo.placa;
      axios
        .get(
          "api/hidrocarburos/ventanasEnturnamientoOperacion/bonoGuia/escanerActa",
          {
            params: { viaje_id: viaje.id },
          }
        )
        .then((response) => {
          if (response.data) {
            this.link = response.data.link_soporte;
            this.escaner_acta = this.link
              ? this.uri_docs + response.data.link_soporte
              : null;
            this.numero_guia = response.data.viaje.guia.numero;
            this.bono_guia_id = response.data.id;
          }
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
