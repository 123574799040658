<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-inspeccion"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Inspección</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body p-0">
              <table
                class="table table-striped table-bordered table-hover table-md"
              >
                <thead class="thead-dark">
                  <tr>
                    <th>Inspeccion</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody v-if="inspecciones.length > 0 && tipo == 1">
                  <tr
                    v-for="item of arrayFinal"
                    :key="item.formato_inspeccion_id"
                  >
                    <td
                      v-if="
                        viaje.guia_id != null &&
                          item.info_formato.tipo_operacion == 2
                      "
                    >
                      {{ item.info_formato.nombre }}
                    </td>
                    <td
                      v-else-if="
                        viaje.guia_id == null &&
                          item.info_formato.tipo_operacion == 1
                      "
                    >
                      {{ item.info_formato.nombre }}
                    </td>
                    <td
                      class="text-center"
                      v-if="
                        item.info == null &&
                          viaje.guia_id != null &&
                          item.info_formato.tipo_operacion == 2
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-info float-lg-right"
                        style="cursor: pointer"
                        @click="inspeccion(viaje, item.info_formato)"
                      >
                        <i class="fas fa-plus-square"></i>
                      </button>
                    </td>
                    <td
                      v-else-if="
                        item.info == null &&
                          viaje.inspecciones.length == 0 &&
                          viaje.guia_id == null &&
                          item.info_formato.tipo_operacion == 1
                      "
                    >
                      <button
                        type="button"
                        v-if="item.info == null"
                        class="btn btn-info float-lg-right"
                        style="cursor: pointer"
                        @click="inspeccion(viaje, item.info_formato)"
                      >
                        <i class="fas fa-plus-square"></i>
                      </button>
                      <button
                        type="button"
                        v-else
                        class="btn btn-sm btn-default py-0"
                        style="cursor: pointer"
                        @click="edit(item.info.id)"
                      >
                        {{ item.info.id }}
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <td>Inspección preoperacional</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-info float-lg-right"
                      style="cursor: pointer"
                      @click="inspeccionPreoperacional(viaje)"
                    >
                      <i class="fas fa-plus-square"></i>
                    </button>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "VentanaOperacionesInspeccion",
  components: {},
  data() {
    return {
      inspecciones: {},
      viajeInspecciones: {},
      totalInspecciones: [],
      arrayFinal: [],
      viaje_id: null,
      viaje: null,
      accion: "",
      metodo: "",
      tipo: null,
    };
  },
  methods: {
    cargarInspecciones() {
      let me = this;
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/cargarInspeccion?viaje_id=" +
            this.viaje_id +
            "&tipo=" +
            this.tipo
        )
        .then(async (response) => {
          me.$parent.cargando = false;
          let res = response.data;
          if (res.Msg == "error") {
            //this.$refs.closeModal.click();
            if (res.errors.vehiculo.error !== undefined) {
              await this.$parent.msgDocumentacion(
                res.errors.vehiculo,
                "Vehiculo"
              );
            }
            if (res.errors.remolque.error !== undefined) {
              await this.$parent.msgDocumentacion(
                res.errors.remolque,
                "Remolque"
              );
            }
            if (res.errors.conductor.error !== undefined) {
              await this.$parent.msgDocumentacion(
                res.errors.conductor,
                "Conductor"
              );
            }
            /* this.$swal({
              icon: "error",
              title:
                "No se puede realizar inspeccion hasta que la documentacion cumpla",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            }); */
          }
          me.viajeInspecciones = res.data[0];
          me.totalInspecciones = res.data;
          me.arrayFinal = [];
          await this.cruceInspeccion();
        });
    },

    async cruceInspeccion() {
      this.inspecciones[0].inspecciones.forEach((element) => {
        let cruce = {
          formato_inspeccion_id: element.pivot.formato_inspeccion_id,
          info_formato: element,
          info: null,
        };
        for (let i = 0; i < this.totalInspecciones.length; i++) {
          if (
            element.pivot.formato_inspeccion_id ==
            this.totalInspecciones[i].formato_inspeccion_id
          ) {
            cruce.info = this.totalInspecciones[i];
          }
        }
        let copyCruce = { ...cruce };
        this.arrayFinal.push(copyCruce);
      });
    },

    getInspecciones() {
      let me = this;
      me.$parent.cargando = true;
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/inspeccionViaje?sitio_type=" +
            this.$parent.filtros.sitio_type +
            "&sitio_id=" +
            this.$parent.filtros.sitio_id
        )
        .then((response) => {
          me.inspecciones = response.data;
        });
    },

    // edit() {
    //   var inspeccion = this.viajeInspecciones.id;
    //   alert(inspeccion);
    //   this.$refs.closeModal.click();
    //   return this.$router.push({
    //     name: "/Hidrocarburos/InspeccionForm",
    //     params: { inspeccion_id: inspeccion.id },
    //   });
    // },

    inspeccion(viaje, inspeccion) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          viaje: viaje,
          inspeccion: inspeccion,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio: this.$parent.slct_sitio,
          val: 0,
        },
      });
    },

    inspeccionPreoperacional(viaje) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          viaje: viaje,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio: this.$parent.slct_sitio,
          val: 0,
        },
      });
    },

    async llenar_modal_inspeccion(viaje, tipo) {
      this.viaje_id = viaje.id;
      this.viaje = viaje;
      this.tipo = tipo;
      await this.getInspecciones();
      await this.cargarInspecciones();
    },

    edit(inspeccion_id) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          inspeccion_id: inspeccion_id,
        },
      });
    },
  },
  mounted() {},
};
</script>
