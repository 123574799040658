<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          <i class="fas fa-calculator"></i> Calculadora de Volumenes
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">API</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="API"
                step="any"
                :class="$v.API.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Vehiculo</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="vehiculo.placa"
                @change="buscarVehiculo()"
                @keyup.enter="buscarVehiculo()"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Remolque</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="remolque.placa"
                @change="buscarRemolque()"
                @keyup.enter="buscarRemolque()"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="vehiculo.peso_vacio"
                step="any"
                :class="
                  $v.vehiculo.peso_vacio.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <h5 class="mt-3 text-center">
              Barriles a cargar: <br /><b class="text-danger">{{
                max_barriles
              }}</b>
            </h5>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.peso_vacio"
                step="any"
                 :class="
                  $v.remolque.peso_vacio.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label for="">Ejes</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.numero_ejes"
                step="any"
                :class="
                  $v.remolque.numero_ejes.$invalid ? 'is-invalid' : 'is-valid'
                "
                
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="!$v.vehiculo.$invalid && !$v.remolque.$invalid"
            @click="calcular()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <div class="col-md-11 float-rigth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CalculatorCrudoRegalia",
  data() {
    return {
      API: this.pAPI,
      vehiculo: {
        placa: null,
        peso_vacio: null,
        numero_ejes: null,
      },
      remolque: {
        placa: null,
        peso_vacio: null,
        numero_ejes: null,
      },
      peso_limite2: 48000,
      peso_limite3: 52000,
      max_barriles: 0,
    };
  },
  props: {
    pAPI: {
      type: Number,
      default: 0,
    },

    pVehiculo: {
      type: String,
    },

    pRemolque: {
      type: String,
    },
  },
  validations: {
    API: {
      required,
    },
    vehiculo: {
      peso_vacio: {
        required,
      },
    },
    remolque: {
      numero_ejes: {
        required,
      },
      peso_vacio:{
        required,
      },
    },
  },

  methods: {
    buscarVehiculo() {
      let me = this;
      let search = me.vehiculo;
      let url = "api/admin/vehiculos/lista?placa=" + search;
      axios
        .get(url)
        .then(function (response) {
          if (response.data.length > 0) {
            me.vehiculo = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Vehiculo no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolque() {
      let me = this;
      let search = me.remolque;
      let url = "api/admin/remolques/lista?placa=" + search;
      axios
        .get(url)
        .then(function (response) {
          if (response.data.length > 0) {
            me.remolque = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Remolque no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    calcular() {
      let me = this;
      let pesoTotal = 0;
      if (me.pRemolque) {
        pesoTotal =
          parseFloat(me.vehiculo.peso_vacio) +
          parseFloat(me.remolque.peso_vacio);
      } else {
        pesoTotal = parseFloat(me.vehiculo.peso_vacio);
      }

      let pesoLimite = 0;
      if (me.remolque.numero_ejes == 2) {
        pesoLimite = me.peso_limite2;
      } else if (me.remolque.numero_ejes == 3) {
        pesoLimite = me.peso_limite3;
      }

      let KgXBarril =
        (141.3819577 / (131.5 + parseFloat(me.API) - 0.001199407795)) *
        158.9872949;

      let MaxBarriles = (pesoLimite - pesoTotal) / KgXBarril;
      me.max_barriles = MaxBarriles.toFixed(2);
    },
  },

  mounted() {
    this.vehiculo.placa = this.pVehiculo;
    if (this.pRemolque) {
      this.remolque.placa = this.pRemolque;
    }
    this.API = this.pAPI;
  },
};
</script>
