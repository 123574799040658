<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          <i class="fas fa-calculator"></i> Calculadora de Volumenes Planta
          Externa
        </h5>
      </div>
      <div class="card-body" v-if="vehiculo.tipo_vehiculo_id == 7">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Tipo de Cargue</label>
              <select
                class="form-control form-control-sm"
                v-model="tipo_cargue"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in objLista.tiposCargue"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                  @click="limpiarVariable()"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Vehiculo</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="vehiculo.placa"
                @change="buscarVehiculo()"
                @keyup.enter="buscarVehiculo()"
              />
            </div>
          </div>
          <div class="col-md-6" v-if="tipo_cargue == 1">
            <div class="form-group">
              <label for="">API</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="API"
                step="any"
                :class="$v.API.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="vehiculo.peso_vacio"
                disabled="disabled"
                step="any"
                :class="
                  $v.vehiculo.peso_vacio.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <h5 class="mt-3 text-center">
              Barriles a cargar: <br /><b class="text-danger">{{
                max_barriles
              }}</b>
            </h5>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">API</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="API"
                step="any"
                :class="$v.API.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Vehiculo</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="vehiculo.placa"
                @change="buscarVehiculo()"
                @keyup.enter="buscarVehiculo()"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Remolque</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="remolque.placa"
                @change="buscarRemolque()"
                @keyup.enter="buscarRemolque()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="vehiculo.peso_vacio"
                disabled="disabled"
                step="any"
                :class="
                  $v.vehiculo.peso_vacio.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <h5 class="mt-3 text-center">
              Barriles a cargar: <br /><b class="text-danger">{{
                max_barriles
              }}</b>
            </h5>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.peso_vacio"
                disabled="disabled"
                step="any"
              />
            </div>
            <div class="form-group">
              <label for="">Ejes</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.numero_ejes"
                step="any"
                :class="
                  $v.remolque.numero_ejes.$invalid ? 'is-invalid' : 'is-valid'
                "
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="!$v.vehiculo.$invalid"
            v-if="tipo_cargue == 1 && vehiculo.tipo_vehiculo_id == 7"
            @click="calculoAguaAceitosa()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <button
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="!$v.vehiculo.$invalid"
            v-else-if="tipo_cargue == 2 && vehiculo.tipo_vehiculo_id == 7"
            @click="calculoArenaAceitosa()"
          ></button>
          <button
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="!$v.vehiculo.$invalid"
            v-else
            @click="calculoTractocamion()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <div class="col-md-11 float-rigth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CalculatorPlantaExterna",
  data() {
    return {
      API: this.pAPI,
      vehiculo: {
        placa: null,
      },
      remolque: {
        placa: null,
        numero_ejes: null,
      },
      tipo_cargue: "",
      peso_limite2: 28000,
      peso_limite3: 48000,
      peso_limite4: 52000,
      max_barriles: 0,
      objLista: {
        tiposCargue: [],
      },
    };
  },
  props: {
    pAPI: {
      type: Number,
      default: 0,
    },

    pVehiculo: {
      type: Object,
    },

    pRemolque: {
      type: Object,
    },
  },
  validations: {
    API: {
      required,
    },
    vehiculo: {
      peso_vacio: {
        required,
      },
    },
    remolque: {
      numero_ejes: {
        required,
      },
    },
  },

  methods: {
    buscarVehiculo() {
      let me = this;
      let search = me.vehiculo.placa;
      let url = "api/admin/vehiculos/lista?placa=" + search;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.length > 0) {
            me.vehiculo = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Vehiculo no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolque() {
      let me = this;
      let search = me.remolque.placa;
      let url = "api/admin/remolques/lista?placa=" + search;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.length > 0) {
            me.remolque = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Remolque no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarVariable() {
      this.max_barriles = 0;
    },

    calculoArenaAceitosa() {
      let me = this;
      let pesoTotal = 0;
      let pesoLimite = 0;

      pesoTotal = parseFloat(me.vehiculo.peso_vacio);
      pesoLimite = me.peso_limite2;

      let MaxBarriles = ((pesoLimite - pesoTotal) / 2100) * 6.28981;
      me.max_barriles = MaxBarriles.toFixed(2);
    },

    calculoAguaAceitosa() {
      let me = this;
      let pesoTotal = 0;
      let pesoLimite = 0;

      pesoTotal = parseFloat(me.vehiculo.peso_vacio);
      pesoLimite = me.peso_limite2;

      let densidadCrudo = (141.5 / (131.5 + parseFloat(me.API))) * 999.916;

      let MaxBarriles = ((pesoLimite - pesoTotal) / densidadCrudo) * 6.28981;
      me.max_barriles = MaxBarriles.toFixed(2);
    },

    calculoTractocamion() {
      let me = this;
      let pesoTotal = 0;
      let pesoLimite = 0;

      if (me.remolque.numero_ejes == 2) {
        pesoLimite = me.peso_limite3;
      } else if (me.remolque.numero_ejes == 3) {
        pesoLimite = me.peso_limite4;
      }

      if (me.pRemolque) {
        pesoTotal =
          parseFloat(me.vehiculo.peso_vacio) +
          parseFloat(me.remolque.peso_vacio);
      } else {
        pesoLimite = me.peso_limite2;
        me.remolque.numero_ejes = 2;
        pesoTotal = parseFloat(me.vehiculo.peso_vacio);
      }

      let densidadCrudo = (141.5 / (131.5 + parseFloat(me.API))) * 999.916;
      let MaxBarriles = ((pesoLimite - pesoTotal) / densidadCrudo) * 6.28981;
      me.max_barriles = MaxBarriles.toFixed(2);
    },

    getTiposCarguePlantaExterna() {
      axios.get("/api/lista/73").then((response) => {
        this.objLista.tiposCargue = response.data;
      });
    },
  },

  mounted() {
    this.vehiculo = this.pVehiculo;
    if (this.pRemolque) {
      this.remolque = this.pRemolque;
    }
    this.API = this.pAPI;
    this.getTiposCarguePlantaExterna();
  },
};
</script>
