<template>
  <div v-if="liquidacion" class="card card-light m-0">
    <div class="card-header">
      <h3 class="card-title">
        <i class="fas fa-oil-can"></i>
        {{ liquidacion.nProducto }}
      </h3>
    </div>
    <div class="card-body text-secondary">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <span>API Gravity@60F= </span>
          <strong class="float-right mr-3">{{ liquidacion.api }}</strong>
        </div>
        <div class="col-md-4">
          <span>Temperatura °F = </span>
          <strong class="float-right mr-3">{{
            liquidacion.temperatura
          }}</strong>
        </div>
        <div class="col-md-4">
          <span>Pressure, PSI= </span>
          <strong class="float-right mr-3">{{ liquidacion.presion }}</strong>
        </div>
        <div class="col-md-4">
          <span>G.O.V </span>
          <strong class="float-right mr-3">{{ liquidacion.gov }}</strong>
        </div>
        <div class="col-md-4">
          <span>G.S.V </span>
          <strong class="float-right mr-3">{{ liquidacion.gsv }}</strong>
        </div>
        <div class="col-md-4">
          <span>N.S.V </span>
          <strong class="float-right mr-3">{{ liquidacion.nsv }}</strong>
        </div>
        <div class="col-md-4">
          <span>B.S.W </span>
          <strong class="float-right mr-3">{{ liquidacion.bsw }}</strong>
        </div>
        <div class="col-md-4">
          <span>% Azufre </span>
          <strong class="float-right mr-3">{{ liquidacion.azufre }}</strong>
        </div>
        <div class="col-md-4">
          <span>C.T.L </span>
          <strong class="float-right mr-3">{{ liquidacion.ctl }}</strong>
        </div>
        <div class="col-md-4">
          <span>% Sedimento </span>
          <strong class="float-right mr-3">{{
            liquidacion.porc_sedimento
          }}</strong>
        </div>
        <div class="col-md-4">
          <span>C.S.W </span>
          <strong class="float-right mr-3">{{ liquidacion.csw }}</strong>
        </div>
        <div class="col-md-4">
          <span>R.V.P </span>
          <strong class="float-right mr-3">{{ liquidacion.rvp }}</strong>
        </div>
        <div class="col-md-4">
          <span>% Incertidumbre Expandida U </span>
          <strong class="float-right mr-3">{{
            liquidacion.iincer_exp_porc
          }}</strong>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <span>Incertidumbre Expandida U <small>(Bls)</small> </span>
          <strong class="float-right mr-3">{{
            liquidacion.iincer_exp_bls
          }}</strong>
        </div>
        <div class="col-md-6">
          <span>Responsable</span>
          <strong class="float-right mr-3">{{
            liquidacion.user ? liquidacion.user.name.toUpperCase() : ""
          }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-else>
    <div class="alert alert-warning col-md-12">
      <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
      No se ha realizado la liquidacion de la guía de este Viaje!
    </div>
  </div>
</template>

<script>
export default {
  name: "ViajesInfoLiquidacion",

  data() {
    return {
      liquidacion: null,
    };
  },
  methods: {
    getIndex(viaje) {
      this.liquidacion = viaje.liquidacion_guia
        ? viaje.liquidacion_guia
        : viaje.guia && viaje.guia.liquidacion_guia
        ? viaje.guia.liquidacion_guia
        : null;
    },
  },
};
</script>
