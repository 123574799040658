<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          <i class="fas fa-calculator"></i> Calculadora de Volumenes
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">API</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="API"
                step="any"
                :class="$v.API.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="rounded alert-default-info border border-info p-1">
                <label for="bsw" class="m-0">
                  En caso de que el BSW supere 0.500% seleccione
                </label>
                <input
                  class="ml-2"
                  type="checkbox"
                  v-model="check_bsw"
                  id="bsw"
                  @input="checkBsw"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="check_bsw">
            <div class="form-group">
              <label for="">BSW</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="BSW"
                step="any"
                :class="$v.BSW.$invalid ? 'is-invalid' : 'is-valid'"
                @change="vaidarBsw"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Vehiculo</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="vehiculo.placa"
                @change="buscarVehiculo()"
                @keyup.enter="buscarVehiculo()"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Remolque</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="remolque.placa"
                @change="buscarRemolque()"
                @keyup.enter="buscarRemolque()"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="vehiculo.peso_vacio"
                disabled="disabled"
                step="any"
                :class="
                  $v.vehiculo.peso_vacio.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <h5 class="mt-3 text-center">
              Barriles a cargar: <br /><b class="text-danger">{{
                max_barriles
              }}</b>
            </h5>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.peso_vacio"
                disabled="disabled"
                step="any"
              />
            </div>
            <div class="form-group">
              <label for="">Ejes</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="remolque.numero_ejes"
                step="any"
                :class="
                  $v.remolque.numero_ejes.$invalid ? 'is-invalid' : 'is-valid'
                "
                :disabled="vehiculo.tipo_vehiculo_id != 6"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            v-if="check_bsw == true"
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="
              !$v.vehiculo.$invalid && !$v.remolque.$invalid && !$v.BSW.$invalid
            "
            @click="calcular()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <button
            v-else
            class="btn bg-primary col-md-6 offset-md-3"
            v-show="!$v.vehiculo.$invalid && !$v.remolque.$invalid"
            @click="calcular()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <div class="col-md-11 float-rigth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CalculatorCrudo",
  data() {
    return {
      API: this.pAPI,
      BSW: null,
      check_bsw: false,
      vehiculo: {
        placa: null,
      },
      remolque: {
        placa: null,
      },
      peso_limite2: 48000,
      peso_limite3: 52000,
      max_barriles: 0,
    };
  },
  props: {
    pAPI: {
      type: Number,
      default: 0,
    },

    pVehiculo: {
      type: Object,
    },

    pRemolque: {
      type: Object,
    },
  },
  validations() {
    if (this.check_bsw) {
      return {
        API: {
          required,
        },
        BSW: {
          required,
        },
        vehiculo: {
          peso_vacio: {
            required,
          },
        },
        remolque: {
          numero_ejes: {
            required,
          },
        },
      };
    } else {
      return {
        API: {
          required,
        },
        BSW: {
          required,
        },
        vehiculo: {
          peso_vacio: {
            required,
          },
        },
        remolque: {
          numero_ejes: {
            required,
          },
        },
      };
    }
  },

  methods: {
    buscarVehiculo() {
      let me = this;
      let search = me.vehiculo.placa;
      let url = "api/admin/vehiculos/lista?placa=" + search;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.length > 0) {
            me.vehiculo = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Vehiculo no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolque() {
      let me = this;
      let search = me.remolque.placa;
      let url = "api/admin/remolques/lista?placa=" + search;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.length > 0) {
            me.remolque = response.data[0];
          } else {
            me.$swal({
              icon: "error",
              title: "Remolque no encontrado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    calcular() {
      let me = this;
      let pesoTotal = 0;
      if (me.pRemolque) {
        pesoTotal =
          parseFloat(me.vehiculo.peso_vacio) +
          parseFloat(me.remolque.peso_vacio);
      } else {
        pesoTotal = parseFloat(me.vehiculo.peso_vacio);
      }

      let pesoLimite = 0;
      if (me.remolque.numero_ejes == 2) {
        pesoLimite = me.peso_limite2;
      } else if (me.remolque.numero_ejes == 3) {
        pesoLimite = me.peso_limite3;
      }

      let KgXBarril =
        (141.3819577 / (131.5 + parseFloat(me.API) - 0.001199407795)) *
        158.9872949;

      let MaxBarriles = (pesoLimite - pesoTotal) / KgXBarril;
      me.max_barriles = MaxBarriles.toFixed(2);
      if (me.check_bsw) {
        me.max_barriles = ((1 - parseFloat(me.BSW) / 100) * me.max_barriles).toFixed(2);
      }
    },

    checkBsw() {
      this.BSW = null;
      this.check_bsw = this.check_bsw == false ? true : false;
    },

    vaidarBsw() {
      if (parseFloat(this.BSW) < 0.5) {
        this.BSW = null;
        this.$swal({
          icon: "error",
          title: "El BSW debe ser mayor a 0.500%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.vehiculo = this.pVehiculo;
    if (this.pRemolque) {
      this.remolque = this.pRemolque;
    }
    this.API = this.pAPI;
  },
};
</script>
