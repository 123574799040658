<template>
  <div>
    <div class="card">
      <div class="card-secondary card-outline col-md-12"></div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-3">
            <label for="origen">Ventanas Enturnamiento</label>
            <v-select
              :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
              v-model="ventanaEnturnamiento"
              placeholder="Ventanas Enturnamiento"
              label="nombre"
              :options="listasForms.ventanasEnturne"
              @input="selectVentanasEnturne()"
            ></v-select>
          </div>
          <div class="col-md-3 mt-4" v-if="this.ventanaEnturnamiento !== null">
            <button
              type="button"
              class="btn btn-primary"
              @click="selectVentanasEnturne()"
            >
              <i class="fas fa-sync fa-spin"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              target="_blank"
              @click="pantalla()"
              v-if="
                $store.getters.can('hidrocarburos.ventanasOperaciones.pantalla')
              "
            >
              <i class="fas fa-tv"></i>
            </button>
          </div>
          <div v-else class="col-md-3 mt-4"></div>
          <div class="col-md-4"></div>
          <div class="col-md-2 pt-2">
            <div class="card" style="background-color: #001f3f">
              <h3>
                <strong>
                  <timeless-clock
                    style="
                      color: #daf6ff;
                      font-size: 20px;
                      text-shadow: 0 0 30px rgba(10, 175, 230, 1),
                        0 0 30px rgba(10, 175, 230, 0);
                    "
                    class="text-center pt-1"
                  ></timeless-clock>
                </strong>
              </h3>
            </div>
          </div>
        </div>
        <div
          class="card-navy col-md-12"
          v-for="data in viajesVentanaGroup"
          :key="data.id"
        >
          <div class="card-header pt-2 pb-2">
            <a
              data-toggle="collapse"
              :href="'#ventanas_' + data.id"
              aria-expanded="true"
              id="heading-ventanas"
              class="d-block"
            >
              <div class="row">
                <i class="fa fa-chevron-down pull-right"></i>
                <strong v-if="buscarAgrup(1) !== undefined"
                  >Sitio Cargue: {{ data.sitio_cargue.nombre }} |</strong
                >
                <strong v-if="buscarAgrup(2) !== undefined">
                  Transportadora:
                  {{ data.transportadora.razon_social }} |
                </strong>
                <strong v-if="buscarAgrup(3) !== undefined">
                  Tipo Producto:
                  {{ data.tipo_producto.nombre }} |
                </strong>
                <strong v-if="buscarAgrup(4) !== undefined"
                  >Sitio Descargue: {{ data.sitio_descargue.nombre }} |</strong
                >
                <strong v-if="buscarAgrup(5) !== undefined"
                  >Tipo Ruta: {{ data.tipo_ruta }} |</strong
                >
                <strong v-if="buscarAgrup(6) !== undefined"
                  >Producto: {{ data.producto_liquido.nombre }} |</strong
                >
                <strong>Vehi. Disponibles: {{ data.datos.length }}</strong>
              </div>
            </a>
          </div>
          <div :id="'ventanas_' + data.id" class="collapse">
            <div class="card card-body p-0">
              <table
                id="ventanasViaje"
                class="table table-bordered table-borderless table-striped table-hover table-sm"
                style="font-size: 0.85em;"
              >
                <thead class="thead text-center">
                  <tr>
                    <th></th>
                    <th>No. Viaje</th>
                    <th>Liquidacion Guía</th>
                    <th>No. Guía</th>
                    <th>No. Ticket</th>
                    <th>Tipo</th>
                    <th>Empresa</th>
                    <th>Cabezote</th>
                    <th>Remolque</th>
                    <th>Conductor</th>
                    <th>Barrilaje Calculado</th>
                    <th>Fecha Turno</th>
                    <th>Turno Activo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, indice) of data.datos" :key="item.id">
                    <!-- class="text-center" -->
                    <td>
                      <div class="btn-group dropright">
                        <button
                          type="button"
                          class="btn btn-dark btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-align-justify"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-despacho-manual"
                            v-if="
                              item.estado != 15 &&
                                (valVencimientoGuia(item.guia) < 100 ||
                                  (valVencimientoGuia(item.guia) >= 100 &&
                                    valAutorizacionGuia(
                                      data.det_autorizacion
                                    ))) &&
                                item.guia !== null
                            "
                            @click="
                              llenarModalDespacho(
                                item.id,
                                item.sitio_id,
                                item.ventana_enturnamiento_id,
                                indice + 1
                              )
                            "
                          >
                            <i class="fas fa-people-carry"></i>
                            Despacho Manual
                          </a>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="detGuia(item)"
                            v-if="
                              $parent.filtros.sitio_type == 1 &&
                                (item.tipo_producto.tipo_producto == 1 ||
                                  item.tipo_producto.tipo_producto == 6) &&
                                item.guia == null &&
                                item.guia == undefined &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.diligenciarGuia'
                                )
                            "
                          >
                            <i class="fas fa-digital-tachograph"></i>
                            Diligenciar Guía
                          </a>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="detTicket(item)"
                            v-if="
                              $parent.filtros.sitio_type == 1 &&
                                item.tipo_producto.tipo_producto == 4 &&
                                item.ticket == null &&
                                item.ticket == undefined &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.diligenciarGuia'
                                )
                            "
                          >
                            <i class="fas fa-digital-tachograph"></i>
                            Diligenciar Ticket
                          </a>
                          <a
                            id="botonImp"
                            type="button"
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="
                              $parent.printGuia(item.guia.det_guias[0].id)
                            "
                            v-if="
                              item.guia !== null &&
                                item.guia !== undefined &&
                                (item.tipo_producto.tipo_producto == 1 ||
                                  item.tipo_producto.tipo_producto == 6) &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.printGuia'
                                )
                            "
                          >
                            <i class="fas fa-print"></i>
                            Imprimir Guía
                          </a>
                          <a
                            id="botonImp"
                            type="button"
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="$parent.printBono(item.id)"
                            v-if="
                              item.guia !== null &&
                                item.guia !== undefined &&
                                (item.tipo_producto.tipo_producto == 1 ||
                                  item.tipo_producto.tipo_producto == 6) &&
                                item.bono &&
                                $store.getters.can(
                                  'hidrocarburos.gestionBonos.printBono'
                                )
                            "
                          >
                            <i class="fas fa-print"></i>
                            Imprimir Bono
                          </a>
                          <a
                            id="botonImp"
                            type="button"
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="print(item.ticket_id)"
                            v-if="
                              item.ticket !== null &&
                                item.ticket !== undefined &&
                                item.tipo_producto.tipo_producto == 4 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.printGuia'
                                )
                            "
                          >
                            <i class="fas fa-print"></i>
                            Imprimir Ticket
                          </a>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-cambio-ventana"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.ventanasOperaciones.cambiarVentana'
                              )
                            "
                            @click="
                              llenarModalCambioVentana(
                                item,
                                listasForms.ventanasEnturne,
                                ventanaEnturnamiento
                              )
                            "
                          >
                            <i class="fas fa-exchange-alt"></i>
                            Cambiar Ventana
                          </a>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-liquidacion"
                            v-if="
                              item.estado != 15 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.liquidarGuia'
                                ) &&
                                (item.tipo_producto.tipo_producto == 1 ||
                                  item.tipo_producto.tipo_producto == 6) &&
                                item.guia == null &&
                                item.liquidacion_guia == null
                            "
                            @click="
                              $refs.VentanaOperacionesLiquidacion.getIndex(
                                item,
                                $parent.filtros.tipo_control
                              )
                            "
                          >
                            <i class="fas fa-calculator"></i>
                            Liquidar Guía
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.id }}</td>
                    <td
                      class="text-center"
                      v-if="item.liquidacion_guia"
                      style="font-size: 12px;"
                    >
                      <span class="badge bg-success"
                        >Ya se puede generar guía</span
                      >
                    </td>
                    <td class="text-center" v-else style="font-size: 12px;">
                      <span class="badge bg-danger">Sin Liquidar Guía</span>
                    </td>
                    <td v-if="item.guia !== null && item.guia !== undefined">
                      <span class="badge badge-success">
                        {{ item.guia.numero }}-{{
                          item.guia.digito_verificacion
                        }}
                      </span>
                    </td>
                    <td v-else>
                      <span class="badge badge-danger"> Sin Asignar </span>
                    </td>
                    <td
                      v-if="item.ticket !== null && item.ticket !== undefined"
                    >
                      <span class="badge badge-success">
                        {{ item.ticket.id }}
                      </span>
                    </td>
                    <td v-else>
                      <span class="badge badge-danger"> Sin Asignar </span>
                    </td>
                    <td v-if="$store.state.user.empresas[0] == undefined">
                      Tercero
                    </td>
                    <td v-else>
                      <div v-if="item.guia">
                        <div
                          v-if="
                            item.guia.det_guias[0].transportadora
                              .razon_social ==
                              $store.state.user.empresas[0].razon_social
                          "
                        >
                          Propio
                        </div>

                        <div v-else>Tercero</div>
                      </div>
                      <div v-else>Tercero</div>
                    </td>
                    <td>{{ item.transportadora.razon_social }}</td>
                    <td>
                      {{ item.vehiculo.placa }}
                      <span
                        class="badge"
                        :class="
                          item.vehiculo.tipo_combustible == 1
                            ? 'badge-warning'
                            : 'badge-info'
                        "
                        >{{ item.vehiculo.nCombustible }}</span
                      >
                    </td>
                    <td v-if="item.remolque != null">
                      {{ item.remolque.placa }}
                    </td>
                    <td v-else></td>
                    <td>
                      {{ item.conductor.nombres }}
                      {{ item.conductor.apellidos }}
                      <span class="badge badge-info">
                        {{ item.conductor.numero_documento }}
                      </span>
                    </td>
                    <td v-if="item.volumen_calculado != null">
                      {{ item.volumen_calculado }}
                    </td>
                    <td v-else></td>
                    <td>
                      {{
                        ventanaEnturnamiento.tipo_ventana == 2
                          ? item.fecha_enturne_descargue
                          : item.fecha_enturne_cargue
                      }}
                    </td>
                    <td>{{ indice + 1 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
    <VentanaOperacionesLiquidacion
      ref="VentanaOperacionesLiquidacion"
    ></VentanaOperacionesLiquidacion>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
import TimelessClock from "vuejs-timeless-clock";
import VentanaOperacionesLiquidacion from "./VentanaOperacionesLiquidacion";

export default {
  name: "VentanaOperacionVentanas",
  components: {
    vSelect,
    TimelessClock,
    VentanaOperacionesLiquidacion,
  },
  data() {
    return {
      viajesVentanaGroup: {},
      viajesVentanaGroupDatos: {},
      ventanaEnturnamiento: [],
      ventana_enturnamiento_id: null,
      cantidadVehi: null,
      listasForms: {
        listaSitios: [],
        listaConsultas: [],
        ventanasEnturne: [],
      },
      slct_punto: [],
      puntos: [],
      slct_sitio: [],
      sitios: [],
      sitio_id: null,
      sitio_type2: null,
      tipo_control: null,
    };
  },
  methods: {
    getMomentTime() {
      return moment();
    },

    buscarVentanasEnturne() {
      this.listasForms.ventanasEnturne = [];
      if (this.$parent.filtros.sitio_id) {
        this.sitio_id = this.$parent.filtros.sitio_id;
      } else {
        this.sitio_id = this.$parent.filtros.punto_gestion_id;
      }
      let me = this;
      axios
        .get("api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas", {
          params: {
            sitio_id: this.sitio_id,
            sitio_type: this.$parent.filtros.sitio_type,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturne = respuesta;
          me.ventana_enturnamiento_id = me.listasForms.ventanasEnturne.id;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async selectVentanasEnturne(page = 1) {
      this.$parent.cargando = true;
      this.viajesVentanaGroup = {};
      if (this.ventanaEnturnamiento) {
        this.ventana_enturnamiento_id = this.ventanaEnturnamiento.id;
        let me = this;
        await axios
          .get(
            "/api/hidrocarburos/ventanasEnturnamientoOperacion/mostrarVentana?page=" +
              page,
            {
              params: {
                sitio_type: this.$parent.filtros.sitio_type,
                sitio_id: this.$parent.filtros.sitio_id,
                punto_gestion_id: this.$parent.filtros.punto_gestion_id,
                ventana_enturnamiento_id: this.ventana_enturnamiento_id,
              },
            }
          )
          .then((response) => {
            me.viajesVentanaGroup = response.data;
            me.cantidadVehi = response.data.length;
            this.$parent.cargando = false;
          });
      }
      this.$parent.cargando = false;
    },

    getListaSitio() {
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },

    getListaConsulta() {
      axios.get("/api/lista/47").then((response) => {
        this.listasForms.listaConsultas = response.data;
      });
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarPuntos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/puntos_gestion/lista?punto_gestion=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.puntos = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    print(idTicket) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/detalleTickets/pdf/" + idTicket,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    detGuia(guia) {
      this.$parent.cargando = true;
      let data = {
        sitio_cargue_id: this.$parent.filtros.sitio_id,
        tipo_consulta: this.$parent.filtros.tipo_control,
      };
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/guiasPendientes",
          {
            params: data,
          }
        )
        .then((response) => {
          if (response.data) {
            this.$swal({
              title: "No es posible diligencias guía!!",
              text: "Existen guías sin escáner entre anteayer y ayer...",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.$parent.cargando = false;
          } else {
            return this.$router.push({
              name: "/Hidrocarburos/DetGuiasForm",
              params: {
                accion: "Diligenciar",
                actoGuia: 2,
                data_edit: guia,
                sitio_type: this.$parent.filtros.sitio_type,
                sitio_id: this.$parent.filtros.sitio_id,
                sitio_type2: this.$parent.filtros.sitio_type2,
                tipo_control: this.$parent.filtros.tipo_control,
              },
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelve a intentar...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    detTicket(ticket) {
      return this.$router.push({
        name: "/Hidrocarburos/DetTicketsForm",
        params: {
          accion: "Diligenciar",
          actoGuia: 2,
          data_edit: ticket,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio_id: this.$parent.filtros.sitio_id,
          tipo_control: this.$parent.filtros.tipo_control,
        },
      });
    },

    llenarModalDespacho(viaje, sitio, ventana, turnoAct) {
      this.$parent.$refs.VentanaOperacionesDespacho.llenar_modal_despacho2(
        viaje,
        sitio,
        this.$parent.filtros.sitio_type,
        ventana,
        turnoAct,
        1
      );
    },

    llenarModalCambioVentana(viaje, ventanasEnturne, ventanaEnturnamiento) {
      this.$parent.$refs.VentanaOperacionesCambioVentana.llenar_modal_cambio_ventana(
        viaje,
        ventanasEnturne,
        ventanaEnturnamiento
      );
    },

    buscarAgrup(criterio) {
      if (this.ventanaEnturnamiento !== null) {
        let res = this.ventanaEnturnamiento.agrupamiento.find(
          (n) => n.criterio === criterio
        );
        return res;
      }
      return undefined;
    },

    pantalla() {
      let ruta = this.$router.resolve({
        path:
          "/Hidrocarburos/VentanaOperacionesPantalla?sitio_type=" +
          this.$parent.filtros.sitio_type +
          "&sitio_id=" +
          this.$parent.filtros.sitio_id,
      });
      window.open(ruta.href, "_blank");
    },

    valVencimientoGuia(data) {
      if (data) {
        let fecha_cargue = null;
        let horas_vigencia = null;
        if (this.$route.params.tipo_control == 1) {
          fecha_cargue =
            data.det_guias[0].fecha_cargue +
            " " +
            data.det_guias[0].hora_cargue;
          horas_vigencia = parseInt(data.det_guias[0].horas_vigencia);
          if (data.det_guias[0].hora_adicional != null) {
            horas_vigencia += parseInt(data.det_guias[0].hora_adicional);
          }
        }

        if (this.$route.params.tipo_control == 3) {
          fecha_cargue =
            data.det_guias_regalias[0].fecha_cargue +
            " " +
            data.det_guias_regalias[0].hora_cargue;
          horas_vigencia = parseInt(data.det_guias_regalias[0].horas_vigencia);
          if (data.det_guias_regalias[0].hora_adicional != null) {
            horas_vigencia += parseInt(
              data.det_guias_regalias[0].hora_adicional
            );
          }
        }

        const fechaVencimiento = new Date(fecha_cargue);

        fechaVencimiento.setTime(
          fechaVencimiento.getTime() + horas_vigencia * 60 * 60 * 1000
        );

        const fechaHoy = Date.now();

        const diferencia = fechaHoy - fechaVencimiento;
        const horas = diferencia / (1000 * 60 * 60);

        return (horas / horas_vigencia) * 100 > 100
          ? 100
          : (horas / horas_vigencia) * 100;
      }
    },

    valAutorizacionGuia(det_aut) {
      if (det_aut.length > 0) {
        const filter = det_aut.filter((item) => {
          return item.autorizacion && item.autorizacion.tipo_autorizacion == 19
            ? true
            : false;
        });
        return filter.length > 0 ? true : false;
      }
      return false;
    },
  },
  mounted() {
    this.getListaConsulta();
    this.getListaSitio();
  },
};
</script>
