<template>
  <table
    class="table table-responsive table-sm table-hover"
    style="font-size: 0.85em"
  >
    <loading v-if="cargando" />
    <thead class="thead-dark">
      <tr class="text-center">
        <th class="col-1"># Autoriz</th>
        <th>Tipo Autorización</th>
        <th>Solicita</th>
        <th class="col-3">Justificación</th>
        <th>Autoriza</th>
        <th class="col-3">Observación Autoriza</th>
        <th>Estado</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="aut in $parent.viaje.det_autorizacion" :key="aut.id">
        <td class="text-center">{{ aut.id }}</td>
        <td class="text-nowrap">{{ aut.autorizacion.nTipoAutorizacion }}</td>
        <td>
          {{
            aut.autorizacion.user_solicita
              ? aut.autorizacion.user_solicita.name
              : ""
          }}
        </td>
        <td>{{ aut.autorizacion.justificacion }}</td>
        <td>
          {{
            aut.autorizacion.user_autoriza
              ? aut.autorizacion.user_autoriza.name
              : ""
          }}
        </td>
        <td>{{ aut.autorizacion.observacion_autoriza }}</td>
        <td class="text-center">
          <span class="badge badge-success">{{
            aut.autorizacion.nEstado
          }}</span>
        </td>
        <td class="text-right">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="getPDF(aut)"
            >
              <i class="far fa-file-pdf"></i>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import moment from "moment";

export default {
  name: "ViajeAutorizaciones",

  components: {
    Loading,
  },

  data() {
    return {
      cargando: false,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    getPDF(aut) {
      this.cargando = true;
      let datosAuth = JSON.parse(aut.autorizacion.datos_mostrar_nuevos);
      const data = {
        sitio_cargue: this.$parent.viaje.sitio_cargue
          ? this.$parent.viaje.sitio_cargue.nombre
          : "",
        sitio_descargue: this.$parent.viaje.sitio
          ? this.$parent.viaje.sitio.nombre
          : "",
        vehiculo: this.$parent.viaje.vehiculo
          ? this.$parent.viaje.vehiculo.placa
          : "",
        remolque: this.$parent.viaje.remolque
          ? this.$parent.viaje.remolque.placa
          : "",
        transportadora: this.$parent.viaje.transportadora
          ? this.$parent.viaje.transportadora.razon_social
          : "",
        conductor: datosAuth["Nombre Conductor"],
        doc_conductor: datosAuth["Documento Conductor"],
        producto: this.$parent.viaje.tipo_producto
          ? this.$parent.viaje.tipo_producto.nombre
          : "",
        producto_liquido: this.$parent.viaje.producto_liquido
          ? this.$parent.viaje.producto_liquido.nombre
          : "",
        guia: this.$parent.viaje.guia
          ? `${this.$parent.viaje.guia.numero}${
              this.$parent.viaje.guia.digito_verificacion != null
                ? ` - ${this.$parent.viaje.guia.digito_verificacion}`
                : ""
            } `
          : "",
        fecha_solicitud: moment(aut.autorizacion.created_at).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        fecha_autoriza: moment(aut.autorizacion.updated_at).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        autoriza: aut.autorizacion.user_autoriza.name,
        autoriza_tel: aut.autorizacion.user_autoriza.cel,
        solicita: aut.autorizacion.user_solicita.name,
        solicita_doc: aut.autorizacion.user_solicita.n_document,
        solicita_tel: aut.autorizacion.user_solicita.cel,
        solicita_cargo:
          aut.autorizacion.user_solicita &&
          aut.autorizacion.user_solicita.charge != null
            ? aut.autorizacion.user_solicita.charge
            : "",
        justificacion: aut.autorizacion.justificacion,
      };

      axios({
        method: "PUT",
        url:
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/pdf_autorizaciones",
        data,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
