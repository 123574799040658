<template>
  <div>
    <div
      class="modal-backdrop fade show"
      style="z-index: 10000"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
      v-if="cargando"
    >
      <div class="row justify-content-center h-100">
        <div class="align-self-center">
          <ScaleLoader
            class="text-center"
            color="#fff"
            :height="150"
            :width="10"
            margin="10px"
          />
          <h3 class="text-center text-light">Cargando...</h3>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modal-form-enturne"
      style="background-color: #00000082"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Enturnamiento</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="origen">Ventanas Enturnamiento</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="ventanaEnturnamiento"
                  placeholder="Ventanas Enturnamiento"
                  label="nombre"
                  :options="listasForms.ventanasEnturne"
                  @search="buscarVentanasEnturne"
                  @input="selectVentanasEnturne()"
                ></v-select>
              </div>
              <div class="form-group col-md-6">
                <label for="origen">Fecha</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="origen">Fecha</label>
                <!-- <input
                  type="time"
                  class="form-control form-control-sm"
                  v-model="form.hora"
                /> -->
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.hora"
                  :class="$v.form.hora.$invalid ? 'is-invalid' : 'is-valid'"
                  v-mask="{
                    mask: '99:99',
                    hourFormat: '24',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.ventana_enturnamiento_id.$invalid"
              @click="
                $parent.filtros.tipo_control == 1
                  ? enturnar()
                  : enturnarDiffViaje()
              "
            >
              Enturnar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "VentanaOperacionesEnturne",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        ventana_enturnamiento_id: null,
        fecha: null,
        hora: null,
        viaje_id: null,
        sitio_id: null,
      },
      ventanaEnturnamiento: [],
      listasForms: {
        ventanasEnturne: [],
      },
    };
  },
  validations: {
    form: {
      ventana_enturnamiento_id: {
        required,
      },
      fecha: {
        required,
      },
      hora: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    buscarVentanasEnturne(search, loading) {
      if (search != "") {
        let me = this;
        axios
          .get(
            "api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas?ventana_enturnamiento=" +
              search,
            {
              params: {
                sitio_id: this.form.sitio_id,
                sitio_type: this.$parent.filtros.sitio_type,
              },
            }
          )
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.ventanasEnturne = respuesta;
            me.form.ventana_enturnamiento_id =
              me.listasForms.ventanasEnturne.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    enturnar() {
      let fecha_actual = moment().format("YYYY-MM-DD HH:mm:ss");

      if (this.form.viaje.api == 0 || this.form.viaje.volumen_calculado == 0) {
        this.$swal({
          text: "No se ha calculado el maximo de barriles a cargar",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.$refs.closeModal.click();
      }
      //Valida la fecha de vencimiento de la solicitud diaria con la actual al mometo de enturnar
      else if (
        this.form.viaje.det_postulacion.det_solicitud_diaria.solicitud_diaria
          .fecha_venci_postulacion < fecha_actual &&
        this.ventanaEnturnamiento.tipo_ventana == 1
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar, No se encuentra una programacion activa`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      //Valida al momento de enturnar para cargue si tiene guia y ticket
      else if (
        this.ventanaEnturnamiento.tipo_ventana == 1 &&
        (this.form.viaje.guia_id !== null || this.form.viaje.ticket_id !== null)
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar para cargue ya tiene guia o ticket.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } else if (
        this.ventanaEnturnamiento.tipo_ventana == 2 &&
        this.form.viaje.guia_id == null &&
        this.form.viaje.ticket_id == null
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar para cargue ya tiene guia o ticket.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } else if (
        this.ventanaEnturnamiento.tipo_ventana == 1 &&
        this.form.viaje.inspecciones.length == 0 &&
        this.form.viaje.det_postulacion.inspecciones.length == 0
        //this.$parent.procesoEnturne
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar, hace falta realizar una inspeccion`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } /* else if (
        this.ventanaEnturnamiento.tipo_ventana == 2 &&
        this.form.viaje.inspecciones.length == 0
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar, hace falta realizar una inspeccion`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } */ else {
        let valinspeccioncargue = false;
        let valinspeccion = false;
        if (this.form.viaje.inspecciones.length > 0) {
          for (let i = 0; i < this.form.viaje.inspecciones.length; i++) {
            let insp = this.form.viaje.inspecciones[i];
            if (
              insp["estado_documento"] == 2 &&
              [2, 3, 5].includes(insp["estado"]) &&
              insp["formato_inspeccion_id"] == 16
            ) {
              valinspeccioncargue = true;
              break;
            }
          }
        }

        if (this.form.viaje.det_postulacion.inspecciones.length > 0) {
          for (
            let i = 0;
            i < this.form.viaje.det_postulacion.inspecciones.length;
            i++
          ) {
            let insp = this.form.viaje.det_postulacion.inspecciones[i];
            if (
              insp["estado_documento"] == 2 &&
              [2, 3, 5].includes(insp["estado"]) &&
              insp["formato_inspeccion_id"] == 16
            ) {
              valinspeccioncargue = true;
              break;
            }
          }
        }

        if (
          this.ventanaEnturnamiento.tipo_ventana == 1 &&
          valinspeccioncargue
        ) {
          valinspeccion = true;
        }

        if (this.ventanaEnturnamiento.tipo_ventana == 2) {
          valinspeccion = true;
        }

        if (valinspeccion) {
          this.cargando = true;
          axios
            .post(
              "api/hidrocarburos/ventanasEnturnamientoOperacion/enturnarViaje",
              {
                sitio_type: this.$parent.filtros.sitio_type,
                sitio_id: this.form.sitio_id,
                viaje_id: this.form.viaje_id,
                ventana_enturnamiento_id: this.form.ventana_enturnamiento_id,
                tipo_ventana: this.ventanaEnturnamiento.tipo_ventana,
                fecha_hora_enturne: `${this.form.fecha} ${this.form.hora}`,
              }
            )
            .then(async (response) => {
              let res = response.data;
              this.cargando = false;
              /* if (res.Msg == "error") {
              if (res.errors.vehiculo.error !== undefined) {
                await this.$parent.msgDocumentacion(
                  res.errors.vehiculo,
                  "Vehiculo"
                );
              }
              if (res.errors.remolque.error !== undefined) {
                await this.$parent.msgDocumentacion(
                  res.errors.remolque,
                  "Remolque"
                );
              }
              if (res.errors.conductor.error !== undefined) {
                await this.$parent.msgDocumentacion(
                  res.errors.conductor,
                  "Conductor"
                );
              }
              this.$swal({
                icon: "error",
                title: "No se pudo realizar enturne",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else { */
              this.$refs.closeModal.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "Se enturno el viaje exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              //}
            });
        } else {
          this.$swal({
            icon: "error",
            title: `No se puede enturnar ya que los estados de la inspeccion no son validos.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    enturnarDiffViaje() {
      let valSave = true;
      if (this.form.viaje.api == 0 || this.form.viaje.volumen_calculado == 0) {
        this.$swal({
          text: "No se ha calculado el maximo de barriles a cargar",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.$refs.closeModal.click();
        valSave = false;
      } //Valida al momento de enturnar para cargue si tiene guia y ticket
      else if (
        this.ventanaEnturnamiento.tipo_ventana == 1 &&
        this.form.viaje.guia_id !== null
      ) {
        this.$swal({
          icon: "error",
          title: `No se puede enturnar para cargue ya tiene guia.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        valSave = false;
      }

      if (valSave) {
        axios
          .post(
            "api/hidrocarburos/ventanasEnturnamientoOperacion/enturnarDiffViaje",
            {
              viaje_id: this.form.viaje_id,
              ventana_enturnamiento_id: this.form.ventana_enturnamiento_id,
              fecha_hora_enturne: `${this.form.fecha} ${this.form.hora}`,
            }
          )
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se enturno el viaje exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectVentanasEnturne() {
      this.form.ventana_enturnamiento_id = "";
      if (this.ventanaEnturnamiento) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamiento.id;
      }
    },

    llenar_modal_Enturne(viaje, sitio, item) {
      let ahora = new Date();
      let fecha = this.ajustarFecha(ahora);
      let horas = ahora
        .getHours()
        .toString()
        .padStart(2, "0");
      let minutos = ahora
        .getMinutes()
        .toString()
        .padStart(2, "0");

      let hora = `${horas}:${minutos}`;

      this.form.viaje_id = viaje;
      this.form.sitio_id = sitio;
      this.form.viaje = item;
      this.form.fecha = fecha;
      this.form.hora = hora;
    },

    ajustarFecha(ahora) {
      // Se organiza el dia
      let dia = ahora.getDate();
      if (dia < 10) {
        dia = `0${dia}`;
      }
      // Se organiza el mes
      let mes = ahora.getMonth() + 1;
      if (mes < 10) {
        mes = `0${mes}`;
      }
      // Se organiza el año
      let year = ahora.getFullYear();
      return `${year}-${mes}-${dia}`;
    },
  },
  mounted() {},
};
</script>
