<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          <i class="fas fa-calculator"></i> Calculadora de Volumenes
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">API</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="API"
                step="any"
                :class="$v.API.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Vehiculo</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="postulacion.vehiculo"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Remolque</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="postulacion.remolque"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Peso</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="postulacion.peso_vacio"
                step="any"
              />
            </div>
            <h5 class="mt-3 text-center">
              Barriles a cargar: <br /><b class="text-danger">{{
                max_barriles
              }}</b>
            </h5>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Ejes</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="postulacion.numero_ejes"
                step="any"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            class="btn bg-primary col-md-6 offset-md-3"
            @click="calcular()"
          >
            <i class="fas fa-calculator"></i> Calcular
          </button>
          <div class="col-md-11 float-rigth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CalculatorVentasTerceros",
  data() {
    return {
      API: this.pAPI,
      tipo_cargue: "",
      postulacion: this.$parent.postulacion,
      peso_limite2: 48000,
      peso_limite3: 52000,
      max_barriles: 0,
      objLista: {
        tiposCargue: [],
      },
    };
  },
  props: {
    pAPI: {
      type: Number,
      default: 0,
    },
  },
  validations: {
    API: {
      required,
    },
  },

  methods: {
    calcular() {
      let me = this;
      let pesoTotal = 0;

      if (me.postulacion.peso_vacio != null) {
        pesoTotal = parseFloat(me.postulacion.peso_vacio);
      }

      let pesoLimite = 0;
      if (me.postulacion.numero_ejes != null) {
        if (me.postulacion.numero_ejes == 2) {
          pesoLimite = me.peso_limite2;
        } else if (me.postulacion.numero_ejes == 3) {
          pesoLimite = me.peso_limite3;
        }
      }

      let KgXBarril =
        (141.3819577 / (131.5 + parseFloat(me.API) - 0.001199407795)) *
        158.9872949;

      let MaxBarriles = (pesoLimite - pesoTotal) / KgXBarril;
      me.max_barriles = MaxBarriles.toFixed(2);
    },
  },

  mounted() {
    this.API = this.pAPI;
  },
};
</script>
