<template>
  <div class="hold-transition">
    <div
      class="modal-backdrop fade show"
      style="z-index: 10000"
      v-if="cargando"
    >
      <div class="row justify-content-center h-100">
        <div class="align-self-center">
          <ScaleLoader
            class="text-center"
            color="#fff"
            :height="150"
            :width="10"
            margin="10px"
          />
          <h3 class="text-center text-light">Cargando...</h3>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ventanas Operaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Ventanas Operaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="listaSitio">Tipo de sitio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.sitio_type"
                        @change="obtenerSitioType"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="listaSitio in listasForms.listaSitios"
                          :key="listaSitio.numeracion"
                          :value="listaSitio.numeracion"
                        >
                          {{ listaSitio.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="filtros.sitio_type == 2">
                    <label for="puntos">Punto Gestión</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="slct_sitio"
                      placeholder="Punto Gestión"
                      label="nombre"
                      :options="puntos"
                      @input="buildProcess()"
                    ></v-select>
                    <div class="error" v-show="!filtros.sitio_id">
                      Ingrese un punto de Gestión
                    </div>
                  </div>
                  <div class="col-md-3" v-if="filtros.sitio_type == 1">
                    <label for="sitios">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="slct_sitio"
                      placeholder="Sitio"
                      label="nombre"
                      :options="sitios"
                      :filterable="true"
                      :clearable="true"
                      @input="buildProcess()"
                    ></v-select>
                    <div class="error" v-show="!filtros.sitio_id">
                      Ingrese un sitio
                    </div>
                  </div>
                  <div class="col-md-3" v-if="filtros.sitio_type">
                    <div class="form-group">
                      <label for="listaConsulta">Tipo Consulta</label>
                      <!-- <router-link
                        :to="
                          '/Hidrocarburos/VentanasOperaciones/' +
                          filtros.sitio_id +
                          '/' +
                          filtros.sitio_type +
                          '/' +
                          filtros.tipo_control
                        "
                      > -->
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_control"
                        @change="getIndex()"
                      >
                        <option
                          v-for="listaConsulta in listasForms.listaConsultas"
                          :key="listaConsulta.numeracion"
                          :value="listaConsulta.numeracion"
                        >
                          {{ listaConsulta.descripcion }}
                        </option>
                      </select>
                      <!-- </router-link> -->
                    </div>
                  </div>
                  <div
                    class="col-md-3 mt-4"
                    v-if="
                      filtros.tipo_control &&
                      filtros.sitio_type &&
                      filtros.sitio_id
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-gradient-primary"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync fa-spin"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="tab-Viajes"
                          data-toggle="tab"
                          href="#Viajes"
                          >Viajes</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          v-if="
                            this.filtros.sitio_type &&
                            this.filtros.sitio_id &&
                            this.filtros.tipo_control == 1 &&
                            this.filtros.tipo_control
                          "
                          class="nav-link"
                          id="tab-Ventanas"
                          data-toggle="tab"
                          href="#Ventanas"
                          >Ventanas</a
                        >
                      </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade active show" id="Viajes">
                        <VentanaOperacionesViajes
                          ref="VentanaOperacionesViajes"
                        />
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Ventanas"
                        v-show="
                          this.filtros.sitio_type &&
                          this.filtros.sitio_id &&
                          this.filtros.tipo_control
                        "
                      >
                        <VentanaOperacionesVentanas
                          ref="VentanaOperacionesVentanas"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <VentanaOperacionesValidateViaje
          ref="VentanaOperacionesValidateViaje"
        />
        <VentanaOperacionesCambios ref="VentanaOperacionesCambios" />
        <VentanaOperacionesMapa ref="VentanaOperacionesMapa" />
        <ViajesDetViaje ref="ViajesDetViaje" />
        <VentanaOperacionesInspeccion ref="VentanaOperacionesInspeccion" />
        <VentanaOperacionesAdjuntos ref="VentanaOperacionesAdjuntos" />
        <VentanaOperacionesEnturne ref="VentanaOperacionesEnturne" />
        <VentanaOperacionesViajesEstado ref="VentanaOperacionesViajesEstado" />
        <VentanaOperacionesDespacho ref="VentanaOperacionesDespacho" />
        <VentanaOperacionesCambioVentana
          ref="VentanaOperacionesCambioVentana"
        />
        <VentanaOperacionesCambioPostulacion
          ref="VentanaOperacionesCambioPostulacion"
        />
        <VentanaOperacionesLlegada ref="VentanaOperacionesLlegada" />
        <VentanaOperacionesInspeccionVal
          ref="VentanaOperacionesInspeccionVal"
        />
        <VentanaOperacionesEscaner ref="VentanaOperacionesEscaner" />
        <VentanaOperacionesEscanerActa ref="VentanaOperacionesEscanerActa" />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import { ScaleLoader } from "@saeris/vue-spinners";
import VentanaOperacionesVentanas from "./VentanaOperacionesVentanas";
import VentanaOperacionesViajes from "./VentanaOperacionesViajes";
import VentanaOperacionesValidateViaje from "./VentanaOperacionesValidateViaje";
import VentanaOperacionesCambios from "./VentanaOperacionesCambios";
import VentanaOperacionesMapa from "./VentanaOperacionesMapa";
import ViajesDetViaje from "../viajes/ViajesDetViaje";
import VentanaOperacionesInspeccion from "./VentanaOperacionesInspeccion";
import VentanaOperacionesAdjuntos from "./VentanaOperacionesAdjuntos";
import VentanaOperacionesEnturne from "./VentanaOperacionesEnturne";
import VentanaOperacionesViajesEstado from "./VentanaOperacionesViajesEstado";
import VentanaOperacionesDespacho from "./VentanaOperacionesDespacho";
import VentanaOperacionesCambioVentana from "./VentanaOperacionesCambioVentana";
import VentanaOperacionesCambioPostulacion from "./VentanaOperacionesCambioPostulacion";
import VentanaOperacionesLlegada from "./VentanaOperacionesLlegada";
import VentanaOperacionesInspeccionVal from "./VentanaOperacionesInspeccionVal";
import VentanaOperacionesEscaner from "./VentanaOperacionesEscaner";
import VentanaOperacionesEscanerActa from "./VentanaOperacionesEscanerActa";

export default {
  name: "VentanaOperacionIndex",
  components: {
    ScaleLoader,
    VentanaOperacionesVentanas,
    VentanaOperacionesViajes,
    VentanaOperacionesValidateViaje,
    VentanaOperacionesCambios,
    VentanaOperacionesMapa,
    ViajesDetViaje,
    VentanaOperacionesInspeccion,
    VentanaOperacionesAdjuntos,
    VentanaOperacionesEnturne,
    VentanaOperacionesViajesEstado,
    VentanaOperacionesDespacho,
    VentanaOperacionesCambioVentana,
    VentanaOperacionesLlegada,
    VentanaOperacionesCambioPostulacion,
    VentanaOperacionesInspeccionVal,
    VentanaOperacionesEscaner,
    VentanaOperacionesEscanerActa,
    vSelect,
  },
  data() {
    return {
      filtros: {
        sitio_id: null,
        sitio_type: null,
        tipo_control: null,
        sitio_type2: null,
      },
      cargando: false,
      listasForms: {
        listaSitios: [],
        listaConsultas: [],
      },
      slct_punto: [],
      puntos: [],
      slct_sitio: [],
      sitios: [],
      procesos_sitio: [],
      procesoEnturne: null,
      procesoInspeccion: null,
      procesoValidarInspeccion: null,
      procesoCrearViaje: null,
      procesoCrearViajeRegTerc: null,
      polling: null,
      page: 1,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      this.page = page;
      this.$refs.VentanaOperacionesViajes.viajes = {};
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
      } else {
        this.filtros.sitio_id = null;
        this.$refs.VentanaOperacionesViajes.ventanaEnturnamiento = [];
      }
      this.filtros.cabezote =
        this.$refs.VentanaOperacionesViajes.filtros.cabezote;
      this.filtros.idViaje =
        this.$refs.VentanaOperacionesViajes.filtros.idViaje;
      this.filtros.nRemolque =
        this.$refs.VentanaOperacionesViajes.filtros.nRemolque;
      this.filtros.nConductor =
        this.$refs.VentanaOperacionesViajes.filtros.nConductor;
      this.filtros.nTransportadora =
        this.$refs.VentanaOperacionesViajes.filtros.nTransportadora;
      this.filtros.nSitioCargue =
        this.$refs.VentanaOperacionesViajes.filtros.nSitioCargue;
      this.filtros.nSitioDescargue =
        this.$refs.VentanaOperacionesViajes.filtros.nSitioDescargue;
      this.filtros.nUltPunto =
        this.$refs.VentanaOperacionesViajes.filtros.nUltPunto;
      this.filtros.guia = this.$refs.VentanaOperacionesViajes.filtros.guia;
      this.filtros.ticket = this.$refs.VentanaOperacionesViajes.filtros.ticket;
      this.filtros.ruta = this.$refs.VentanaOperacionesViajes.filtros.ruta;
      this.filtros.producto =
        this.$refs.VentanaOperacionesViajes.filtros.producto;
      this.filtros.sitioCargue =
        this.$refs.VentanaOperacionesViajes.filtros.sitioCargue;
      this.filtros.sitio =
        this.$refs.VentanaOperacionesViajes.filtros.sitioDescargue;
      this.filtros.fExpedicionGuia =
        this.$refs.VentanaOperacionesViajes.filtros.fExpedicionGuia;
      this.filtros.tipo_combustible =
        this.$refs.VentanaOperacionesViajes.filtros.tipo_combustible;
      this.filtros.estado = this.$refs.VentanaOperacionesViajes.filtros.estado;
      //Filtros Comercialización
      this.filtros.cmViaje =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmViaje;
      this.filtros.cmGuia =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmGuia;
      this.filtros.cmVehiculo =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmVehiculo;
      this.filtros.cmRemolque =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmRemolque;
      this.filtros.cmCliente =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmCliente;
      this.filtros.cmTransportadora =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmTransportadora;
      this.filtros.cmConductor =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmConductor;
      this.filtros.cmSitioDestino =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmSitioDestino;
      this.filtros.cmSitio =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmSitio;
      this.filtros.cmFechaCargue =
        this.$refs.VentanaOperacionesViajes.filtrosTerceros.cmFechaCargue;
      if (
        this.filtros.sitio_type &&
        this.filtros.sitio_id &&
        this.filtros.tipo_control
      ) {
        let me = this;
        await axios
          .get(
            "/api/hidrocarburos/ventanasEnturnamientoOperacion/index?page=" +
              page,
            {
              params: this.filtros,
            }
          )
          .then((response) => {
            if (this.filtros.tipo_control != 4) {
              me.$refs.VentanaOperacionesViajes.viajes = response.data;
            } else {
              me.$refs.VentanaOperacionesViajes.$refs.VentanaOperacionesPostuladosTerceros.postuladoTerceros =
                response.data;
            }
            me.$refs.VentanaOperacionesVentanas.buscarVentanasEnturne();
            me.cargando = false;
            const sitioID = this.filtros.sitio_id;
            const sitioType = this.filtros.sitio_type;
            const tipoControl = this.filtros.tipo_control;
            this.$router.replace({
              path: `/Hidrocarburos/VentanasOperaciones/${sitioID}/${sitioType}/${tipoControl}`,
            });
          });
      }
    },

    async getListaSitio() {
      await axios.get("/api/lista/25").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },
    async getListaConsulta() {
      await axios.get("/api/lista/47").then((response) => {
        this.listasForms.listaConsultas = response.data;
      });
    },

    obtenerSitioType() {
      if (this.filtros.sitio_type == null) {
        this.filtros.sitio_type2 = null;
      }
      if (this.filtros.sitio_type == 1) {
        this.filtros.sitio_type2 = "App\\Sitio";
      }

      if (this.filtros.sitio_type == 2) {
        this.filtros.sitio_type2 = "App\\PuntoGestion";
      }
    },

    async buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista?estado=1";
      await axios
        .get(url)
        .then(function (response) {
          let respuesta = response.data;
          me.sitios = respuesta;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarPuntos() {
      let me = this;
      var url = "api/admin/puntos_gestion/lista?estado=1";
      await axios
        .get(url)
        .then(function (response) {
          let respuesta = response.data;
          me.puntos = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    printGuia(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guia/" + idDetGuia,
        "_blank"
      );
    },

    printBono(viaje_id) {
      axios
        .get(`/api/hidrocarburos/bonoGuia/printBono`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    printGuiaRegalias(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guiaRegalias/" + idDetGuia,
        "_blank"
      );
    },

    buildProcess() {
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
        this.procesos_sitio = this.slct_sitio.procesos;
        this.buscarProcesos();
      } else {
        this.filtros.sitio_id = null;
        this.procesos_sitio = [];
        this.$refs.VentanaOperacionesViajes.viajes = {};
        this.$refs.VentanaOperacionesViajes.ventanaEnturnamiento = [];
      }
    },

    buscarProcesos() {
      this.procesoInspeccion = null;
      this.procesoValidarInspeccion = null;
      this.procesoEnturne = null;
      this.procesoCrearViaje = null;
      this.procesoCrearViajeRegTerc = null;
      for (let i = 0; i < this.procesos_sitio.length; i++) {
        if (this.procesos_sitio[i].id == 69) {
          this.procesoInspeccion = this.procesos_sitio[i].id;
        }
        if (this.procesos_sitio[i].id == 70) {
          this.procesoEnturne = this.procesos_sitio[i].id;
        }
        if (this.procesos_sitio[i].id == 566) {
          this.procesoCrearViaje = this.procesos_sitio[i].id;
        }
        if (this.procesos_sitio[i].id == 735) {
          this.procesoCrearViajeRegTerc = this.procesos_sitio[i].id;
        }
        if (this.procesos_sitio[i].id == /* 4208 */ 4237) {
          this.procesoValidarInspeccion = this.procesos_sitio[i].id;
        }
      }
    },

    async msgDocumentacion(res, entidad) {
      if (res.error) {
        let erroHtml = "<div>";
        if (res.error.documentos) {
          if (res.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.error.documentos.obligatorios.join("<br>");
          }
          if (res.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (let i = 0; i < res.error.documentos.vencidos.length; i++) {
              erroHtml +=
                res.error.documentos.vencidos[i].documento +
                " - " +
                res.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.error.documentos.no_verificados.join("<br>");
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "info",
          title: "Aviso en documentacion " + entidad,
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'>El " +
            entidad +
            " no pasa las validaciones documentales, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    pollData() {
      this.polling = setInterval(() => {
        this.getIndex(this.page);
      }, 120000);
    },
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  created() {
    this.pollData();
  },

  async mounted() {
    await this.getListaSitio();
    await this.getListaConsulta();
    await this.buscarSitios();
    await this.buscarPuntos();

    if (this.$route.params.sitio) {
      this.filtros.sitio_type = this.$route.params.sitio_type;
      this.filtros.tipo_control = 1;
      this.filtros.slct_sitio = {
        id: this.$route.params.sitio_id,
        nombre: this.$route.params.sitio,
      };
      await this.getIndex();
    }

    if (
      this.$route.params.sitio_id &&
      this.$route.params.sitio_type &&
      this.$route.params.tipo_control
    ) {
      this.filtros.sitio_id = this.$route.params.sitio_id;
      this.filtros.sitio_type = this.$route.params.sitio_type;
      this.filtros.tipo_control = this.$route.params.tipo_control;

      if (this.filtros.sitio_type == 1) {
        let sitio_n = await this.sitios.find(
          (n) => n.id === parseInt(this.filtros.sitio_id)
        );
        this.slct_sitio = sitio_n;
      }
      if (this.filtros.sitio_type == 2) {
        let sitio_n = await this.puntos.find(
          (n) => n.id === parseInt(this.filtros.sitio_id)
        );
        this.slct_sitio = sitio_n;
      }
      await this.obtenerSitioType();

      await this.getIndex();

      await this.buildProcess();
    }
  },
};
</script>
