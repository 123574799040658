<template>
  <div class="card">
    <div class="card-body p-0" style="hight: 20px">
      <div v-if="$parent.filtros.tipo_control != 4">
        <table
          style="font-size: 9px; overflow-x: auto"
          id="viajes"
          class="table table-bordered table-striped table-hover table-sm team-roster-table table-responsive"
        >
          <thead class="text-center">
            <tr>
              <th>
                <div>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#modal-form-validate-viaje"
                    style="cursor: pointer"
                    v-if="
                      (($parent.procesoCrearViaje &&
                        $parent.filtros.tipo_control == 1) ||
                        ($parent.procesoCrearViajeRegTerc &&
                          $parent.filtros.tipo_control == 3)) &&
                        $parent.filtros.tipo_control &&
                        $parent.filtros.sitio_id &&
                        $parent.filtros.sitio_type
                    "
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.idViaje"
                  placeholder="# Viaje"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th style="min-width: 7%">Api-Barriles</th>
              <th>Liquidación Guía</th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.guia"
                  placeholder="# Guia"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.ticket"
                  placeholder="Ticket"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>Tipo</th>
              <th>Tipo Operación</th>
              <th>Tipo Producto</th>
              <th>Ventana cargue</th>
              <th>Ventana descargue</th>
              <th colspan="2">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.cabezote"
                  placeholder="Vehículo"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <select
                  class="form-control form-control-sm"
                  v-model="filtros.tipo_combustible"
                  @change="$parent.getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_combustible in objLista.tiposCombustible"
                    :key="tipo_combustible.numeracion"
                    :value="tipo_combustible.numeracion"
                  >
                    {{ tipo_combustible.descripcion }}
                  </option>
                </select>
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nRemolque"
                  placeholder="Remolque"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nConductor"
                  placeholder="Conductor"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nTransportadora"
                  placeholder="Transportadora"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.ruta"
                  placeholder="Ruta"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.producto"
                  placeholder="Producto"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nSitioCargue"
                  placeholder="Sitio Cargue"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nSitioDescargue"
                  placeholder="Sitio Descargue"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.nUltPunto"
                  placeholder="Ult. Punto"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th>Cita</th>
              <th>
                <input
                  type="date"
                  v-b-popover.hover.left="'Fecha Expedicion'"
                  class="form-control form-control-sm"
                  v-model="filtros.fExpedicionGuia"
                  placeholder="Expedición Guia"
                  @keyup.enter="$parent.getIndex()"
                />
              </th>
              <th v-if="$parent.filtros.tipo_control == 1">
                <select
                  class="form-control form-control-sm"
                  v-model="filtros.estado"
                  @change="$parent.getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in objLista.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </th>
              <th v-else>
                <select
                  class="form-control form-control-sm"
                  v-model="filtros.estado"
                  @change="$parent.getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in objLista.estadosPostulacion"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in viajes.data" :key="item.id">
              <td>
                <!-- Split dropright button -->
                <div
                  class="btn-group"
                  :class="viajes.data.length == 1 ? 'dropop' : 'dropright'"
                  style="position: static; z-index: auth"
                >
                  <button
                    type="button"
                    class="btn btn-dark btn-sm"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-align-justify"></i>
                  </button>
                  <div class="dropdown-menu">
                    <!-- Inspección Sitio -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-inspeccion"
                      style="cursor: pointer"
                      v-if="
                        $parent.filtros.tipo_control == 1 &&
                          [1, 2, 4, 7].includes(item.estado) &&
                          item.estado != 15 &&
                          $parent.procesoInspeccion &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.inspeccionar'
                          )
                      "
                      @click="llenarModalInspeccion(item, 1)"
                    >
                      <i class="fas fa-binoculars"></i>
                      Inspección
                    </a>
                    <!-- Inspeccion Punto Gestión -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-inspeccion"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 2 &&
                          $parent.procesoInspeccion &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.inspeccionar'
                          )
                      "
                      @click="llenarModalInspeccion(item, 2)"
                    >
                      <i class="fas fa-binoculars"></i>
                      Inspección
                    </a>
                    <!-- Foto Orden Cargue -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-subir-foto-guia"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.fotoOrden'
                          ) &&
                          ($parent.filtros.tipo_control == 1 ||
                            $parent.filtros.tipo_control == 2)
                      "
                      @click="modalFotoOrden(item)"
                    >
                      <i class="fas fa-camera-retro"></i>
                      Foto Orden De Cargue
                    </a>
                    <!-- Autorizacion Descargue -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-autorizacion-formato"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 1 &&
                          ($store.getters.can(
                            'hidrocarburos.ventanasOperaciones.cambiarVehiculo'
                          ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.cambiarRemolque'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.cambiarVencimientoGuia'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.cambiarConductor'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.autNovedadPrecintosSellos'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.cambiarDigitacionGuia'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.autOmisionPuntoControl'
                            ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.cambiarRuta'
                            ))
                      "
                      @click="
                        $parent.$refs.VentanaOperacionesCambios.getIndex(
                          item,
                          1,
                          null
                        )
                      "
                    >
                      <i class="fas fa-check"></i>
                      Autorización Descargue
                    </a>
                    <!-- Cambio Conductor -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-cambio-trinomios"
                      style="cursor: pointer"
                      @click="llenarModalEntidad(item, 'Conductor')"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarConductor'
                        ) && $parent.filtros.tipo_control == 1
                      "
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Cambiar Conductor
                    </a>
                    <!-- Cambio Vehículo -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-cambio-trinomios"
                      style="cursor: pointer"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarVehiculo'
                        ) && $parent.filtros.tipo_control == 1
                      "
                      @click="llenarModalEntidad(item, 'Vehículo')"
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Cambiar Vehículo
                    </a>
                    <!-- Cambio Remolque -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-cambio-trinomios"
                      style="cursor: pointer"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarRemolque'
                        ) && $parent.filtros.tipo_control == 1
                      "
                      @click="llenarModalEntidad(item, 'Remolque')"
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Cambiar Remolque
                    </a>
                    <!-- Cambio Ruta -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-cambio-trinomios"
                      style="cursor: pointer"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarRuta'
                        ) && $parent.filtros.tipo_control == 1
                      "
                      @click="llenarModalEntidad(item, 'Ruta')"
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Cambiar Ruta
                    </a>
                    <!-- Anular Postulación -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-estado"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 2 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.liberarVehiculo'
                          )
                      "
                      @click="
                        llenarModalEstado(
                          item.id,
                          item.estado,
                          3,
                          'Anular Postulación',
                          3
                        )
                      "
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Anular postulación
                    </a>
                    <!-- Cambiar Postulación -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-cambio-postulacion"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 2 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.cambiarPostulacion'
                          )
                      "
                      @click="llenarModalCambioPostulacion(item)"
                    >
                      <i class="fas fa-exchange-alt"></i>
                      Cambiar postulación
                    </a>
                    <!-- Actualizar Guía -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          ($parent.filtros.tipo_control == 1 ||
                            $parent.filtros.tipo_control == 3) &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia != null &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.actualizarGuia'
                          )
                      "
                      @click="editGuia(item)"
                    >
                      <i class="fas fa-marker"></i>
                      Actualizar Guía
                    </a>
                    <!-- Actualizar Ticket -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 1 &&
                          item.tipo_producto.tipo_producto == 4 &&
                          item.ticket != null &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.actualizarGuia'
                          )
                      "
                      @click="editTicket(item)"
                    >
                      <i class="fas fa-marker"></i>
                      Actualizar Ticket
                    </a>
                    <!-- Calculo Crudo -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#Modal_calc_crudo"
                      @click="
                        (modalCalcCrudo = true),
                          llenarModalCalculatorCrudo(item)
                      "
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.calcularApi'
                          ) &&
                          ($parent.filtros.tipo_control == 1 ||
                            $parent.filtros.tipo_control == 3) &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia == null
                      "
                    >
                      <i class="fas fa-calculator"></i>
                      Calculo Crudo
                    </a>
                    <!-- Liquidar Guía Regalias y ventas a terceros -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#modal-form-liquidacionR"
                      v-if="
                        item.estado != 15 &&
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.liquidarGuia'
                        ) &&
                        $parent.filtros.tipo_control ==
                          3 /* ||
                          $parent.filtros.tipo_control == 4 */ &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia == null &&
                          item.ventana_enturne_cargue_id != null &&
                          !item.liquidacion_guia
                      "
                      @click="
                        $refs.VentanaOperacionesLiquidacion.getIndex(
                          item,
                          $parent.filtros.tipo_control
                        )
                      "
                    >
                      <i class="fas fa-calculator"></i>
                      Liquidar Guía
                    </a>
                    <!-- Calculo Planta Externa -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#Modal_calc_planta_externa"
                      @click="
                        item.estado != 15 && (modalCalcPlantaExterna = true),
                          llenarModalCalculatorPlantaExterna(item)
                      "
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.calcularApi'
                        ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.tipo_producto.tipo_producto == 4 &&
                          item.ticket == null
                      "
                    >
                      <i class="fas fa-calculator"></i>
                      Calculo Planta Externa
                      <i
                        v-if="
                          item.volumen_calculado != null &&
                            item.volumen_calculado > 0
                        "
                        class="fas fa-check-circle"
                      ></i>
                      <i v-else class="fas fa-times-circle"></i>
                    </a>
                    <!-- Cierre Manual Sitio-->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-estado"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.cierreManual'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          ((item.guia_id != null &&
                            (valVencimientoGuia(item.guia) < 100 ||
                              (valVencimientoGuia(item.guia) >= 100 &&
                                valAutorizacionGuia(item.det_autorizacion)))) ||
                            item.ticket_id != null)
                      "
                      @click="
                        llenarModalEstado(
                          item.id,
                          item.estado,
                          12,
                          'Cierre manual',
                          1
                        )
                      "
                    >
                      <i class="fas fa-lock"></i>
                      Cierre Manual
                    </a>
                    <!-- Cierre Manual Punto Gestión -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.diligenciarGuiaRegalias'
                          ) &&
                          $parent.filtros.tipo_control == 3 &&
                          (item.guia_id != null || item.ticket_id != null)
                      "
                      @click="cierreManualRegalias(item)"
                    >
                      <i class="fas fa-lock"></i>
                      Cierre Manual
                    </a>
                    <!-- Liberar Vehículo -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-estado"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.liberarVehiculo'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.guia_id == null &&
                          item.ticket_id == null
                      "
                      @click="
                        llenarModalEstado(
                          item.id,
                          item.estado,
                          13,
                          'Anular viaje',
                          2
                        )
                      "
                    >
                      <i class="fa fa-times-circle"></i>
                      Liberar Vehículo
                    </a>
                    <!-- Mapa Sitio -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-mapa"
                      style="cursor: pointer"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.generarMapa'
                        ) && $parent.filtros.tipo_control == 1
                      "
                      @click="llenarModalMapa(item, item.cita_cargue)"
                    >
                      <i class="fa fa-map-marker-alt"></i>
                      Mapa
                    </a>
                    <!-- Mapa PuntoGestión -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-mapa"
                      style="cursor: pointer"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.generarMapa'
                          ) &&
                          $parent.filtros.tipo_control == 2
                      "
                      @click="
                        llenarModalMapa(
                          item,
                          item.det_solicitud_diaria.solicitud_diaria.fecha +
                            item.det_solicitud_diaria.cita
                        )
                      "
                    >
                      <i class="fa fa-map-marker-alt"></i>
                      Mapa
                    </a>
                    <!-- Enturnamiento Viajes -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-enturne"
                      style="cursor: pointer"
                      @click="llenarModalEnturne(item.id, item.sitio_id, item)"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 1 &&
                          $parent.procesoEnturne &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.enturnar'
                          ) &&
                          [1, 2, 4, 7].includes(item.estado) &&
                          item.sitio.id === $parent.filtros.sitio_id &&
                          item.sitio_type == $parent.filtros.sitio_type2 &&
                          item.volumen_calculado != null
                      "
                    >
                      <i class="fas fa-sort"></i>
                      Enturnamiento
                    </a>
                    <!-- Enturnamiento Regalias -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-enturne"
                      style="cursor: pointer"
                      @click="llenarModalEnturne(item.id, item.sitio_id, item)"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 3 &&
                          $parent.procesoEnturne &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.enturnarDiffViaje'
                          ) &&
                          [1, 2, 4, 7].includes(item.estado) &&
                          item.sitio.id === $parent.filtros.sitio_id &&
                          item.sitio_type == $parent.filtros.sitio_type2 &&
                          item.volumen_calculado != null &&
                          item.guia_id == null
                      "
                    >
                      <i class="fas fa-sort"></i>
                      Enturnamiento
                    </a>
                    <!-- Marcar LLegada -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#modal-form-marcar-llegada"
                      @click="llenarModalLlegada(item.id)"
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 1 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.llegada'
                          ) &&
                          [6, 9].includes(item.estado)
                      "
                    >
                      <i class="fas fa-people-carry"></i>
                      Marcar llegada
                    </a>
                    <!-- Despacho Manual -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="llenarModalDespacho(item.id, item.sitio_id)"
                      data-toggle="modal"
                      data-target="#modal-form-despacho-manual"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.despachar'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          !$parent.procesoEnturne &&
                          [1, 2, 4, 7].includes(item.estado)
                      "
                    >
                      <i class="fas fa-people-carry"></i>
                      Despacho Manual
                    </a>
                    <!-- Diligenciar Guía -->
                    <!-- <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="detGuia(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.diligenciarGuia'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          !$parent.procesoEnturne &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia == null &&
                          [1, 2, 4, 7].includes(item.estado)
                      "
                    >
                      <i class="fas fa-digital-tachograph"></i>
                      Diligenciar Guía
                    </a> -->
                    <!-- Diligenciar Guía Regalias -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="detGuiaRegalias(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.diligenciarGuiaRegalias'
                          ) &&
                          $parent.filtros.tipo_control == 3 &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia == null &&
                          [1, 2, 4, 5, 7].includes(item.estado) &&
                          item.ventana_enturne_cargue_id != null
                      "
                    >
                      <i class="fas fa-digital-tachograph"></i>
                      Diligenciar Guía
                    </a>
                    <!-- Diligenciar Ticket -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="detTicket(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.diligenciarTicket'
                          ) &&
                          (($parent.filtros.tipo_control == 1 &&
                            !$parent.procesoEnturne &&
                            item.tipo_producto.tipo_producto == 4 &&
                            item.ticket == null &&
                            [1, 2, 4, 7].includes(item.estado)) ||
                            ($parent.filtros.tipo_control == 3 &&
                              item.tipo_producto.tipo_producto == 4))
                      "
                    >
                      <i class="fas fa-digital-tachograph"></i>
                      Diligenciar Ticket
                    </a>
                    <!-- Imprimir Guía -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="$parent.printGuia(item.guia.det_guias[0].id)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.printGuia'
                        ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.guia !== null &&
                          item.guia !== undefined &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6)
                      "
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Guía
                    </a>
                    <!-- Imprimir Bono -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="$parent.printBono(item.id)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.gestionBonos.printBono'
                        ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.guia !== null &&
                          item.guia !== undefined &&
                          item.bono !== null &&
                          item.bono !== undefined
                      "
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Bono
                    </a>
                    <!-- Imprimir Guía Regalias -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="$parent.printGuiaRegalias(item.id)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.printGuiaRegalias'
                        ) &&
                          $parent.filtros.tipo_control == 3 &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6) &&
                          item.guia !== null
                      "
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Guía
                    </a>
                    <!-- Imprimir Ticket -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="printTiket(item.ticket_id)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.generarPdfTickets'
                        ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.ticket !== null &&
                          item.ticket !== undefined &&
                          item.tipo_producto.tipo_producto == 4
                      "
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Ticket
                    </a>
                    <!-- Anular Guía -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="anularCambioGuia(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.anularCambioGuia'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.guia !== null &&
                          item.guia !== undefined &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6)
                      "
                    >
                      <i class="fas fa-ban"></i>
                      Anular Guia - Cambio
                    </a>
                    <!-- Anular Guía Regalias -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="anularCambioGuiaRegalias(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.anularCambioGuia'
                          ) &&
                          $parent.filtros.tipo_control == 3 &&
                          item.guia !== null &&
                          item.guia !== undefined &&
                          (item.tipo_producto.tipo_producto == 1 ||
                            item.tipo_producto.tipo_producto == 6)
                      "
                    >
                      <i class="fas fa-ban"></i>
                      Anular Guia - Cambio
                    </a>
                    <!-- Anular Ticket -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="anularCambioTicket(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.anularCambioGuia'
                          ) &&
                          $parent.filtros.tipo_control == 1 &&
                          item.ticket !== null &&
                          item.ticket !== undefined &&
                          item.tipo_producto.tipo_producto == 4
                      "
                    >
                      <i class="fas fa-ban"></i>
                      Anular Ticket - Cambio
                    </a>
                    <!-- Validar Inspección -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-form-val-inspeccion"
                      style="cursor: pointer"
                      @click="
                        llenarModalInspeccionVal(
                          item.id,
                          item.sitio_id,
                          item.vehiculo.placa,
                          item.transportadora.n_identificacion,
                          item.transportadora_id,
                          item
                        )
                      "
                      v-if="
                        item.estado != 15 &&
                          $parent.filtros.tipo_control == 1 &&
                          [1, 2, 4, 7].includes(item.estado) &&
                          ((item.inspecciones &&
                            item.inspecciones.length == 0 &&
                            item.det_postulacion &&
                            item.det_postulacion.inspecciones.length == 0) ||
                            [1, 2, 3].includes(item.val_inspeccion)) &&
                          $parent.procesoInspeccion &&
                          $parent.procesoValidarInspeccion &&
                          ($store.getters.can(
                            'hidrocarburos.ventanasOperaciones.validarInspeccion'
                          ) ||
                            $store.getters.can(
                              'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                            ))
                      "
                    >
                      <i class="fas fa-file-invoice"></i>
                      Validar Inspección
                    </a>
                    <!-- Escaner Guía -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-escaner-guia"
                      style="cursor: pointer"
                      @click="modalEscanerGuía(item)"
                      v-if="
                        item.estado != 15 &&
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.indexPdf'
                          )
                      "
                    >
                      <i class="fas fa-file-pdf"></i>
                      Escáner Guía
                    </a>
                    <!-- Imprimir Acta -->
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="printActa(item.id)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.printActa'
                        ) &&
                          item.bono != null &&
                          !item.bono.link_soporte
                      "
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Acta
                    </a>
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      target="_blank"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.printActa'
                        ) &&
                          item.bono != null &&
                          item.bono.link_soporte
                      "
                      :href="uri_docs + item.bono.link_soporte"
                    >
                      <i class="fas fa-print"></i>
                      Imprimir Acta
                    </a>
                    <!-- Escaner Acta -->
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#modal-escaner-acta"
                      style="cursor: pointer"
                      @click="modalEscanerActa(item)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.getActa'
                        ) && item.bono != null
                      "
                    >
                      <i class="fas fa-file-pdf"></i>
                      Escáner Acta
                    </a>
                  </div>
                </div>
              </td>
              <td
                v-if="
                  $parent.filtros.tipo_control == 1 ||
                    $parent.filtros.tipo_control == 3
                "
                class="text-center"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-default py-0"
                  data-toggle="modal"
                  data-target="#modal-form-detViaje"
                  @click="llenarModalDetViaje(item.id, item.tipo_operacion)"
                >
                  {{ item.id }}
                </button>
              </td>
              <td v-else></td>
              <td
                class="text-center"
                v-if="item.volumen_calculado && item.api"
                style="font-size: 12px"
              >
                <span class="badge bg-info">{{ item.api }}</span>
                -
                <span class="badge bg-success">{{
                  item.volumen_calculado
                }}</span>
              </td>
              <td class="text-center" v-else style="font-size: 12px">
                <span class="badge bg-danger">Sin Asignar</span>
              </td>
              <td
                class="text-center"
                v-if="item.liquidacion_guia"
                style="font-size: 12px"
              >
                <span class="badge bg-success">Ya se puede generar guía</span>
              </td>
              <td class="text-center" v-else style="font-size: 12px">
                <span class="badge bg-danger">Sin Liquidar Guía</span>
              </td>
              <td
                v-if="item.guia !== null && item.guia !== undefined"
                style="font-size: 12px"
              >
                <span
                  :class="
                    valVencimientoGuia(item.guia) == 100
                      ? 'badge badge-warning'
                      : 'badge badge-success'
                  "
                >
                  {{ item.guia.numero }}-{{ item.guia.digito_verificacion }}
                </span>
                <div class="progress progress-xxs">
                  <div
                    :class="
                      valVencimientoGuia(item.guia) == 100
                        ? 'progress-bar bg-danger progress-bar-striped'
                        : 'progress-bar progress-bar-danger progress-bar-striped'
                    "
                    role="progressbar"
                    :aria-valuenow="valVencimientoGuia(item.guia)"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="'width:' + valVencimientoGuia(item.guia) + '%'"
                  >
                    <span class="sr-only">60% Complete (warning)</span>
                  </div>
                </div>
              </td>
              <td v-else style="font-size: 12px">
                <span class="badge badge-danger"> Sin Asignar </span>
              </td>
              <td
                v-if="item.ticket !== null && item.ticket !== undefined"
                style="font-size: 11px"
              >
                <span class="badge badge-success">
                  {{ item.numero_ticket }}
                </span>
              </td>
              <td v-else style="font-size: 12px">
                <span class="badge badge-danger"> Sin Asignar </span>
              </td>
              <td v-if="$parent.filtros.tipo_control == 1">
                <div v-if="item.vehiculo.empresa_id !== item.transportadora_id">
                  Tercero
                </div>
                <div v-else>Propio</div>
              </td>
              <td v-else></td>
              <td>
                {{ item.tipoOperacion }}
              </td>
              <td>
                <div
                  v-if="
                    item.tipo_producto !== null &&
                      item.tipo_producto !== undefined
                  "
                >
                  {{ item.tipo_producto.nombre }}
                </div>
                <div v-else></div>
              </td>
              <td>
                <div
                  v-if="
                    item.ventana_cargue !== null &&
                      item.ventana_cargue !== undefined
                  "
                >
                  {{ item.ventana_cargue.nombre }}
                </div>
                <div v-else></div>
              </td>
              <td>
                <div
                  v-if="
                    item.ventana_descargue !== null &&
                      item.ventana_descargue !== undefined
                  "
                >
                  {{ item.ventana_descargue.nombre }}
                </div>
                <div v-else></div>
              </td>
              <td
                style="min-width: 60px; font-size: 12px"
                class="text-center"
                colspan="1"
                v-if="
                  (item.vehiculo != null &&
                    $parent.filtros.tipo_control == 1) ||
                    $parent.filtros.tipo_control == 2
                "
              >
                <span
                  :class="
                    item.liquidacion_guia && item.liquidacion_guia.nsv
                      ? 'badge shadow badge badge-pill badge-success'
                      : 'badge shadow badge badge-pill badge-secondary'
                  "
                >
                  {{ item.vehiculo.placa }}
                </span>
                <span class="d-block mt-2">
                  {{
                    item.liquidacion_guia
                      ? "NSV: " + item.liquidacion_guia.nsv
                      : ""
                  }}
                </span>
              </td>
              <td
                colspan="1"
                class="text-nowrap"
                v-else-if="
                  item.vehiculo != null && $parent.filtros.tipo_control == 3
                "
              >
                {{ item.vehiculo }}
              </td>
              <td v-else></td>
              <td colspan="1">
                <div
                  v-if="
                    item.vehiculo.ultimo_registro &&
                      item.vehiculo.ultimo_registro !== undefined
                  "
                >
                  <i
                    v-if="
                      item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte <
                        fechaCorte
                    "
                    class="fa fa-times-circle text-danger"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="
                      item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte
                    "
                  ></i>

                  <i
                    v-else
                    class="fa fa-check text-success"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="
                      item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte
                    "
                  ></i>
                </div>
                <div v-else>
                  <i
                    class="fa fa-times-circle text-danger"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Sin fecha de reporte"
                  ></i>
                </div>
              </td>
              <td>
                <span
                  v-if="
                    (item.vehiculo != null &&
                      $parent.filtros.tipo_control == 1) ||
                      $parent.filtros.tipo_control == 2
                  "
                  class="badge ml-4 mr-4"
                  :class="
                    item.vehiculo.tipo_combustible == 1
                      ? 'badge-warning'
                      : 'badge-info'
                  "
                  >{{ item.vehiculo.nCombustible }}</span
                >
              </td>
              <td
                v-if="
                  ($parent.filtros.tipo_control == 2 ||
                    $parent.filtros.tipo_control == 1) &&
                    item.remolque != null
                "
              >
                {{ item.remolque.placa }}
              </td>
              <td
                v-else-if="
                  item.remolque != null && $parent.filtros.tipo_control == 3
                "
              >
                {{ item.remolque }}
              </td>
              <td v-else></td>
              <td
                v-if="
                  $parent.filtros.tipo_control == 1 ||
                    $parent.filtros.tipo_control == 2
                "
              >
                {{ item.conductor.nombres }} {{ item.conductor.apellidos }}
                <span class="badge badge-info">
                  {{ item.conductor.numero_documento }}
                </span>
              </td>
              <td v-else-if="$parent.filtros.tipo_control == 3">
                {{ item.conductor }}
                <span class="badge badge-info">
                  {{ item.documento_conductor }}
                </span>
              </td>
              <td v-if="$parent.filtros.tipo_control == 1">
                <div v-if="item.transportadora != null">
                  {{ item.transportadora.razon_social }}
                </div>
              </td>
              <td v-else-if="$parent.filtros.tipo_control == 3">
                <div v-if="item.transportadora_id != null">
                  {{ item.transportadora.razon_social }}
                </div>
              </td>
              <td v-else>
                {{ item.det_solicitud_diaria.empresa.razon_social }}
              </td>
              <td
                v-if="
                  item.ruta != null &&
                    ($parent.filtros.tipo_control == 1 ||
                      $parent.filtros.tipo_control == 2)
                "
              >
                {{ item.ruta.nombre }}
              </td>
              <td v-else></td>
              <td v-if="item.producto_liquido != null">
                {{ item.producto_liquido.nombre }}
              </td>
              <td v-else-if="item.ticket">
                {{ item.ticket.producto.nombre }}
              </td>
              <td v-else></td>
              <td class="text-nowrap">
                <div
                  v-if="
                    item.sitio_cargue != null &&
                      ($parent.filtros.tipo_control == 1 ||
                        $parent.filtros.tipo_control == 3)
                  "
                >
                  {{ item.sitio_cargue.nombre }}
                </div>
                <div v-if="$parent.filtros.tipo_control == 2">
                  {{ item.det_solicitud_diaria.solicitud_diaria.sitio.nombre }}
                </div>
              </td>
              <td v-if="item.sitio_descargue != null" class="text-nowrap">
                {{ item.sitio_descargue.nombre }}
              </td>
              <td v-else></td>
              <td
                v-if="
                  $parent.filtros.tipo_control == 1 ||
                    $parent.filtros.tipo_control == 3
                "
                class="text-nowrap"
              >
                {{ item.sitio.nombre }}
              </td>
              <td v-else></td>
              <td v-if="$parent.filtros.tipo_control == 1" class="text-nowrap">
                {{ item.cita_cargue }}
              </td>
              <td
                v-else-if="$parent.filtros.tipo_control == 2"
                class="text-nowrap"
              >
                {{ item.det_solicitud_diaria.solicitud_diaria.fecha }}
                {{ item.det_solicitud_diaria.cita }}
              </td>
              <td v-else></td>
              <td v-if="$parent.filtros.tipo_control == 1">
                <div v-if="item.guia && item.guia.det_guias[0]">
                  {{ item.guia ? item.guia.det_guias[0].fecha_expedicion : "" }}
                  {{ item.ticket ? item.ticket.fecha_cargue : "" }}
                </div>
              </td>
              <td v-else>
                {{
                  item.guia
                    ? item.guia.det_guias_regalias[0].fecha_expedicion
                    : ""
                }}
                {{ item.ticket ? item.ticket.fecha_cargue : "" }}
              </td>
              <td class="text-center" style="font-size: 12px">
                <span
                  class="badge"
                  :class="
                    item.estado == 1 || item.estado == 7
                      ? 'badge-primary'
                      : item.estado == 6 || item.estado == 9
                      ? 'bg-navy'
                      : item.estado == 5 || item.estado == 10
                      ? 'badge-info'
                      : item.estado == 4 || item.estado == 2
                      ? 'badge-success'
                      : item.estado == 3
                      ? 'badge-danger'
                      : 'badge-dark'
                  "
                >
                  {{ item.estado }}. {{ item.estadoViaje }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <div class="float-left" v-if="viajes.total">
            Mostrando del <b>{{ viajes.from }}</b> al <b>{{ viajes.to }}</b> de
            un total: <b>{{ viajes.total }}</b> Registros
          </div>
          <div class="float-left" v-else>
            <span class="badge badge-danger">
              No hay registros para mostrar
            </span>
          </div>
          <pagination
            :data="viajes"
            @pagination-change-page="$parent.getIndex"
            :limit="5"
            class="float-right m-0"
          ></pagination>
        </div>
      </div>
      <!-- Componente Postulaciones Terceros -->
      <div v-if="$parent.filtros.tipo_control == 4">
        <VentanaOperacionesPostuladosTerceros
          ref="VentanaOperacionesPostuladosTerceros"
        />
      </div>
    </div>
    <!-- Modal Calculo Volumenes Crudo / Derivados -->
    <div
      class="modal fade"
      id="Modal_calc_crudo"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Calcular volumenes crudo y derivados
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
              @click="limpiarModalCalculatorCrudo()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body" v-if="$parent.filtros.tipo_control == 1">
            <calculator-crudo
              v-if="modalCalcCrudo"
              :pVehiculo="form.vehiculo"
              :pRemolque="form.remolque"
              ref="calculatorCrudo"
            />
          </div>
          <div class="modal-body" v-if="$parent.filtros.tipo_control == 3">
            <calculator-crudo-regalias
              v-if="modalCalcCrudo"
              :pVehiculo="form.vehiculo"
              :pRemolque="form.remolque"
              ref="CalculatorCrudoRegalias"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="selectVolumenCrudo()"
            >
              <i class="fas fa-check-circle"></i>Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Calculo Planta Externa -->
    <div
      class="modal fade"
      id="Modal_calc_planta_externa"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Calcular volumenes planta externa
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal2"
              @click="limpiarModalCalculatorPlantaExterna()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <calculator-planta-externa
              v-if="modalCalcPlantaExterna"
              :pVehiculo="form.vehiculo"
              :pRemolque="form.remolque"
              ref="calculatorPlantaExterna"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="selectVolumenPlantaExterna()"
            >
              <i class="fas fa-check-circle"></i>Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <VentanaOperacionesLiquidacion
      ref="VentanaOperacionesLiquidacion"
    ></VentanaOperacionesLiquidacion>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import moment from "moment";
import CalculatorCrudo from "../../../../components/CalculatorCrudo.vue";
import CalculatorPlantaExterna from "../../../../components/CalculatorPlantaExterna.vue";
import { VBPopover } from "bootstrap-vue";
import CalculatorCrudoRegalias from "../../../../components/CalculatorCrudoRegalias.vue";
import VentanaOperacionesPostuladosTerceros from "./VentanaOperacionesPostuladosTerceros.vue";
import VentanaOperacionesLiquidacion from "./VentanaOperacionesLiquidacion.vue";

export default {
  name: "VentanaOperacionesViajes",
  components: {
    pagination,
    CalculatorPlantaExterna,
    CalculatorCrudo,
    CalculatorCrudoRegalias,
    VentanaOperacionesLiquidacion,
    VentanaOperacionesPostuladosTerceros,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      form: {
        vehiculo: null,
        remolque: null,
      },
      viajes: {},
      viaje: {},
      postuladoTerceros: {},
      filtros: {},
      filtrosTerceros: {},
      sitio_type: null,
      fechaAct: null,
      solicitudDiaria: [],
      objLista: {
        estados: [],
        estadosPostulacion: [],
        tiposCombustible: [],
      },
      sitio_id: null,
      modalCalcCrudo: false,
      modalCalcPlantaExterna: false,
      modalCalcVentasTerceros: false,
      volumen_calculado: 0,
      peso_vehiculo: 0,
      peso_remolque: 0,
      api: 0,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    valVencimientoGuia(data) {
      let fecha_cargue = null;
      let horas_vigencia = null;
      if (data) {
        if (this.$route.params.tipo_control == 1) {
          if (data.det_guias[0]) {
            fecha_cargue =
              data.det_guias[0].fecha_cargue +
              " " +
              data.det_guias[0].hora_cargue;
            horas_vigencia = parseInt(data.det_guias[0].horas_vigencia);
            if (data.det_guias[0].hora_adicional != null) {
              horas_vigencia += parseInt(data.det_guias[0].hora_adicional);
            }
          }
        }

        if (this.$route.params.tipo_control == 3) {
          fecha_cargue =
            data.det_guias_regalias[0].fecha_cargue +
            " " +
            data.det_guias_regalias[0].hora_cargue;
          horas_vigencia = parseInt(data.det_guias_regalias[0].horas_vigencia);
          if (data.det_guias_regalias[0].hora_adicional != null) {
            horas_vigencia += parseInt(
              data.det_guias_regalias[0].hora_adicional
            );
          }
        }

        const fechaVencimiento = new Date(fecha_cargue);

        fechaVencimiento.setTime(
          fechaVencimiento.getTime() + horas_vigencia * 60 * 60 * 1000
        );

        const fechaHoy = Date.now();

        const diferencia = fechaHoy - fechaVencimiento;
        const horas = diferencia / (1000 * 60 * 60);

        return (horas / horas_vigencia) * 100 > 100
          ? 100
          : (horas / horas_vigencia) * 100;
      }
    },

    valAutorizacionGuia(det_aut) {
      if (det_aut.length > 0) {
        const filter = det_aut.filter((item) => {
          return item.autorizacion && item.autorizacion.tipo_autorizacion == 19
            ? true
            : false;
        });
        return filter.length > 0 ? true : false;
      }
      return false;
    },

    fechaActual() {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaAct)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    async selectVolumenCrudo() {
      if (this.$route.params.tipo_control == 3) {
        this.volumen_calculado = await this.$refs.CalculatorCrudoRegalias
          .max_barriles;
        this.peso_vehiculo = await this.$refs.CalculatorCrudoRegalias.vehiculo
          .peso_vacio;
        this.peso_remolque = await this.$refs.CalculatorCrudoRegalias.remolque
          .peso_vacio;
        this.api = await this.$refs.CalculatorCrudoRegalias.API;
        if (this.volumen_calculado) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/guardarVolumenesRegalias",
              {
                params: {
                  api: this.api,
                  volumen_calculado: this.volumen_calculado,
                  peso_vehiculo: this.peso_vehiculo,
                  peso_remolque: this.peso_remolque,
                  viaje_id: this.form.viaje_id,
                },
              }
            )
            .then(() => {
              this.$refs.closeModal1.click();
              this.modalCalcCrudo = false;
              this.form = {};
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "Se guardaron correctamente los valor GSV Y API",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$refs.closeModal1.click();
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: "No ha calculado los barriles.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        this.volumen_calculado = await this.$refs.calculatorCrudo.max_barriles;
        let api = await this.$refs.calculatorCrudo.API;
        if (this.volumen_calculado) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/guardarVolumenes",
              {
                params: {
                  volumen_calculado: this.volumen_calculado,
                  api: api,
                  viaje_id: this.form.viaje_id,
                },
              }
            )
            .then(() => {
              this.$refs.closeModal1.click();
              this.modalCalcCrudo = false;
              this.form = {};
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "Se guardaron correctamente los valor GSV Y API",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$refs.closeModal1.click();
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: "No ha calculado los barriles.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async selectVolumenPlantaExterna() {
      this.volumen_calculado = await this.$refs.calculatorPlantaExterna
        .max_barriles;
      let api = await this.$refs.calculatorPlantaExterna.API;
      axios
        .put(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/guardarVolumenes",
          {
            params: {
              volumen_calculado: this.volumen_calculado,
              api: api,
              viaje_id: this.form.viaje_id,
            },
          }
        )
        .then(() => {
          this.$refs.closeModal2.click();
          this.modalCalcCrudo = false;
          this.form = {};
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: "Se guardaron correctamente los valor GSV Y API",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$refs.closeModal2.click();
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    printTiket(idTicket) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/detalleTickets/pdf/" + idTicket,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    limpiarModalCalculatorCrudo() {
      this.modalCalcCrudo = false;
      this.form = {};
      this.volumen_calculado = 0;
    },

    limpiarModalCalculatorPlantaExterna() {
      this.modalCalcPlantaExterna = false;
      this.form = {};
      this.volumen_calculado = 0;
    },

    actViaje(id) {
      return this.$router.push({
        name: "/Hidrocarburos/VentanasOperaciones",
        params: {
          viaje_id: id,
        },
      });
    },

    llenarModalEntidad(viaje, entidad) {
      this.$parent.$refs.VentanaOperacionesCambios.getIndex(viaje, 2, entidad);
    },

    llenarModalEstado(id, estado, estadoCierre, mensaje, opcionVentana) {
      this.$parent.$refs.VentanaOperacionesViajesEstado.llenar_modal_estado(
        id,
        estado,
        estadoCierre,
        mensaje,
        opcionVentana
      );
    },

    llenarModalCalculatorCrudo(viaje) {
      this.form = {};
      if (viaje.vehiculo) {
        this.form.vehiculo = viaje.vehiculo;
      }

      this.form.viaje_id = viaje.id;
      if (viaje.remolque) {
        this.form.remolque = viaje.remolque;
      }
    },

    llenarModalCalculatorPlantaExterna(viaje) {
      this.form = {};
      this.form.vehiculo = viaje.vehiculo;
      this.form.viaje_id = viaje.id;
      if (viaje.remolque) {
        this.form.remolque = viaje.remolque;
      }
    },

    llenarModalCalculatorVentasTerceros(viaje) {
      this.modalCalcVentasTerceros = true;
      this.form = {};
      this.form.viaje_id = viaje.id;
      this.viaje = viaje;
    },

    llenarModalInspeccion(viaje, tipo) {
      this.$parent.$refs.VentanaOperacionesInspeccion.llenar_modal_inspeccion(
        viaje,
        tipo
      );
    },

    llenarModalMapa(item, fecha) {
      this.$parent.$refs.VentanaOperacionesMapa.llenar_modal_mapa(item, fecha);
    },

    llenarModalDetViaje(viaje, tipo_control) {
      this.$parent.$refs.ViajesDetViaje.llenar_modal_detViaje(
        viaje,
        tipo_control
      );
    },

    llenarModalEnturne(viaje, sitio, item) {
      this.$parent.$refs.VentanaOperacionesEnturne.llenar_modal_Enturne(
        viaje,
        sitio,
        item
      );
    },

    llenarModalDespacho(viaje, sitio) {
      this.$parent.$refs.VentanaOperacionesDespacho.llenar_modal_despacho(
        viaje,
        sitio,
        this.$parent.filtros.sitio_type,
        null
      );
    },

    async llenarModalInspeccionVal(
      viajeId,
      sitio,
      vehiculo,
      numEmpresa,
      empresa_id,
      viaje
    ) {
      await this.$parent.$refs.VentanaOperacionesInspeccionVal.llenar_modal_inspeccion_support(
        viajeId,
        sitio,
        vehiculo,
        numEmpresa,
        empresa_id,
        viaje
      );
    },

    modalFotoOrden(viaje) {
      this.$parent.$refs.VentanaOperacionesAdjuntos.llenar_modal_foto_orden(
        viaje
      );
    },

    modalEscanerGuía(viaje) {
      let viaje_type =
        this.$parent.filtros.tipo_control == 1
          ? "App\\Viaje"
          : this.$parent.filtros.tipo_control == 3
          ? "App\\DetGuiaRegalias"
          : null;
      this.$parent.$refs.VentanaOperacionesEscaner.llenar_modal_escaner_guia(
        viaje,
        viaje_type
      );
    },

    modalEscanerActa(viaje) {
      this.$parent.$refs.VentanaOperacionesEscanerActa.llenar_modal_escaner_acta(
        viaje
      );
    },

    cierreManualRegalias(viajeRegal) {
      this.$parent.cargando = true;
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/detalleGuiasRegalias/cierreManual",
        data: viajeRegal,
      })
        .then(() => {
          this.$parent.getIndex();
          this.$parent.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se realizó el cambio de estado exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    editGuia(guia) {
      if (this.$parent.filtros.tipo_control == 1) {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasForm",
          params: {
            accion: "Actualizar",
            actoGuia: 4,
            data_edit: guia,
            sitio_type: this.$parent.filtros.sitio_type,
            sitio_id: this.$parent.filtros.sitio_id,
          },
        });
      } else {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasRegaliasForm",
          params: {
            accion: "Actualizar",
            actoGuia: 4,
            data_edit: guia,
            sitio_type: this.$parent.filtros.sitio_type,
            sitio_id: this.$parent.filtros.sitio_id,
          },
        });
      }
    },

    detGuia(guia) {
      this.$parent.cargando = true;
      let data = {
        sitio_cargue_id: this.$parent.filtros.sitio_id,
        tipo_consulta: this.$parent.filtros.tipo_control,
      };
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/guiasPendientes",
          {
            params: data,
          }
        )
        .then((response) => {
          if (response.data) {
            this.$swal({
              title: "No es posible diligencias guía!!",
              text: "Existen guías sin escáner entre anteayer y ayer...",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.$parent.cargando = false;
          } else {
            return this.$router.push({
              name: "/Hidrocarburos/DetGuiasForm",
              params: {
                accion: "Diligenciar",
                actoGuia: 2,
                data_edit: guia,
                sitio_type: this.$parent.filtros.sitio_type,
                sitio_id: this.$parent.filtros.sitio_id,
                sitio_type2: this.$parent.filtros.sitio_type2,
                tipo_control: this.$parent.filtros.tipo_control,
              },
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelve a intentar...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    detGuiaRegalias(guia) {
      this.$parent.cargando = true;
      let data = {
        sitio_cargue_id: this.$parent.filtros.sitio_id,
        tipo_consulta: this.$parent.filtros.tipo_control,
      };
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/guiasPendientes",
          {
            params: data,
          }
        )
        .then((response) => {
          if (response.data) {
            this.$swal({
              title: "No es posible diligencias guía!!",
              text: "Existen guías sin escaner del día anterior...",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.$parent.cargando = false;
          } else {
            return this.$router.push({
              name: "/Hidrocarburos/DetGuiasRegaliasForm",
              params: {
                accion: "Diligenciar",
                actoGuia: 2,
                data_edit: guia,
                sitio_type: this.$parent.filtros.sitio_type,
                sitio_id: this.$parent.filtros.sitio_id,
                sitio_type2: this.$parent.filtros.sitio_type2,
                tipo_control: this.$parent.filtros.tipo_control,
              },
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelve a intentar...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    editTicket(ticket) {
      return this.$router.push({
        name: "/Hidrocarburos/DetTicketsForm",
        params: {
          accion: "Actualizar",
          actoTicket: 4,
          data_edit: ticket,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio_id: this.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.filtros.tipo_control,
        },
      });
    },

    detTicket(ticket) {
      return this.$router.push({
        name: "/Hidrocarburos/DetTicketsForm",
        params: { accion: "Diligenciar", actoTicket: 2, data_edit: ticket },
      });
    },

    llenarModalLlegada(viaje) {
      var sitio_type;
      if (this.$parent.filtros.sitio_type == 1) {
        sitio_type = `App\\Sitio`;
      } else {
        sitio_type = `App\\PuntoGestion`;
      }
      this.$parent.$refs.VentanaOperacionesLlegada.llenar_modal_llegada(
        sitio_type,
        this.$parent.slct_sitio.id,
        viaje
      );
    },

    llenarModalCambioPostulacion(postulacion) {
      this.$parent.$refs.VentanaOperacionesCambioPostulacion.llenar_modal_cambiar_postulacion(
        postulacion
      );
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.objLista.estados = response.data;
      });
    },

    getEstadosPostulacion() {
      axios.get("/api/lista/65").then((response) => {
        this.objLista.estadosPostulacion = response.data;
      });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.objLista.tiposCombustible = response.data;
      });
    },

    anularCambioGuia(guia) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasForm",
        params: {
          accion: "Cambiar",
          actoGuia: 3,
          data_edit: guia,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio_id: this.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.filtros.tipo_control,
        },
      });
    },

    anularCambioGuiaRegalias(guia) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasRegaliasForm",
        params: {
          accion: "Cambiar",
          actoGuia: 3,
          data_edit: guia,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio_id: this.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.filtros.tipo_control,
        },
      });
    },

    anularCambioTicket(ticket) {
      return this.$router.push({
        name: "/Hidrocarburos/DetTicketsForm",
        params: {
          accion: "Cambiar",
          actoGuia: 3,
          data_edit: ticket,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio_id: this.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.filtros.tipo_control,
        },
      });
    },

    printActa(viaje_id) {
      axios
        .get(`/api/hidrocarburos/detalleGuias/printActa`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.fechaActual();
    this.getEstados();
    this.getEstadosPostulacion();
    this.getTiposCombustibles();
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
