<template>
  <div
    class="modal fade"
    id="modal-form-detViaje"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h4 class="modal-title text-white">Detalle Viaje</h4>
          <button
            type="button"
            class="close text-white"
            @click="limpiarModal"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            ref="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0 pt-1">
          <ul v-if="viaje" class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="tab-InfoViaje"
                data-toggle="tab"
                href="#InfoViaje"
                >Información Viaje</a
              >
            </li>
            <li class="nav-item" v-if="viaje.tipo_operacion == 1">
              <a
                class="nav-link"
                id="tab-PuntosGestion"
                v-on:click="getDatosPuntos()"
                data-toggle="tab"
                href="#PuntosGestion"
                >Puntos Gestión</a
              >
            </li>
            <li class="nav-item" v-if="viaje.tipo_operacion == 1">
              <a
                class="nav-link"
                id="tab-Inspecciones"
                @click="getDatosInspecciones()"
                data-toggle="tab"
                href="#Inspecciones"
                >Inspecciones</a
              >
            </li>
            <li
              class="nav-item"
              v-if="
                viaje &&
                viaje.tipo_operacion &&
                ((viaje.tipo_operacion == 1 && viaje.liquidacion_guia) ||
                  (viaje.tipo_operacion == 3 &&
                    viaje.guia &&
                    viaje.guia.liquidacion_guia))
              "
            >
              <a
                class="nav-link"
                id="tab-Liquidacion"
                @click="$refs.ViajesInfoLiquidacion.getIndex(viaje)"
                data-toggle="tab"
                href="#Liquidacion"
                >Liquidación</a
              >
            </li>
            <li class="nav-item" v-if="viaje.tipo_operacion == 1">
              <a
                class="nav-link"
                id="tab-Mapa"
                @click="getDatosMapa()"
                data-toggle="tab"
                href="#Mapa"
                >Mapa</a
              >
            </li>
            <li class="nav-item" v-if="viaje.tipo_operacion == 1 && link">
              <a
                class="nav-link"
                id="tab-escaner"
                @click="getEscanerGuia()"
                data-toggle="tab"
                href="#Escaner"
                >Escáner Guía</a
              >
            </li>
            <li
              class="nav-item"
              v-if="
                viaje &&
                viaje.det_autorizacion &&
                viaje.det_autorizacion.length > 0
              "
            >
              <a
                class="nav-link"
                id="tab-autorizaciones"
                data-toggle="tab"
                href="#Autorizaciones"
                >Autorizaciones Formato 015</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade active show" id="InfoViaje">
              <ViajesInfoViaje
                v-if="viaje_id != null && viaje"
                ref="ViajesInfoViaje"
              />
            </div>
            <div class="tab-pane" id="PuntosGestion">
              <div class="card-body">
                <ViajesInfoPuntos
                  v-if="viaje_id != null && accion == 2"
                  ref="ViajesInfoPuntos"
                />
              </div>
            </div>
            <div class="tab-pane" id="Inspecciones">
              <div class="card-body">
                <ViajesInfoInspecciones
                  v-if="viaje_id != null && accion == 3"
                  ref="ViajesInfoInspecciones"
                />
              </div>
            </div>
            <div class="tab-pane" id="Liquidacion">
              <div class="card-body">
                <ViajesInfoLiquidacion
                  v-if="
                    viaje &&
                    viaje.tipo_operacion &&
                    ((viaje.tipo_operacion == 1 && viaje.liquidacion_guia) ||
                      (viaje.tipo_operacion == 3 &&
                        viaje.guia &&
                        viaje.guia.liquidacion_guia))
                  "
                  ref="ViajesInfoLiquidacion"
                />
              </div>
            </div>
            <div class="tab-pane" id="Mapa">
              <div class="card-body">
                <ViajesMapa
                  v-if="viaje_id != null && accion == 5"
                  ref="ViajesMapa"
                />
              </div>
            </div>
            <div
              class="tab-pane fade active show"
              id="Escaner"
              v-if="visualizacion"
            >
              <div class="card-body">
                <iframe
                  v-if="tipo_archivo == 'pdf'"
                  :src="visualizacion"
                  style="width: 100%; height: 600px"
                  frameborder="0"
                ></iframe>
                <img
                  v-if="tipo_archivo == 'img'"
                  :src="visualizacion"
                  style="width: 100%; height: 600px"
                />
              </div>
            </div>
            <div class="tab-pane" id="Autorizaciones">
              <div class="card-body">
                <ViajeAutorizaciones
                  v-if="
                    viaje &&
                    viaje.det_autorizacion &&
                    viaje.det_autorizacion.length > 0
                  "
                  ref="ViajeAutorizaciones"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ViajesInfoViaje from "./ViajesInfoViaje";
import ViajesInfoPuntos from "./ViajesInfoPuntos";
import ViajesInfoInspecciones from "./ViajesInfoInspecciones";
import ViajesInfoLiquidacion from "./ViajesInfoLiquidacion";
import ViajesMapa from "./ViajesMapa";
import ViajeAutorizaciones from "./ViajeAutorizaciones";

export default {
  name: "ViajesDetViaje",
  components: {
    ViajesInfoViaje,
    ViajesInfoPuntos,
    ViajesInfoInspecciones,
    ViajesInfoLiquidacion,
    ViajesMapa,
    ViajeAutorizaciones,
  },

  data() {
    return {
      accion: 1,
      viaje_id: null,
      viaje: null,
      link: null,
      visualizacion: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      det_puntos: {},
      det_inspecciones: {},
      det_enturnes: {},
      det_mapa: [],
      det_ruta: [],
    };
  },

  methods: {
    llenar_modal_detViaje(viaje, tipo_control) {
      this.getDatosViaje(viaje, 1, tipo_control);
    },

    async getDatosViaje(viaje, accion, tipo_control) {
      this.accion = accion;
      this.viaje_id = viaje;
      this.viaje = null;
      await axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              viaje_id: viaje,
              accion: accion,
              tipo_control: tipo_control,
            },
          }
        )
        .then((response) => {
          this.viaje = response.data[0];
          this.link = this.viaje.escaner_guia;
          this.visualizacion = null;
        });
      this.$refs.ViajesInfoViaje.tipo_control = tipo_control;
    },

    getDatosPuntos() {
      this.accion = 2;
      this.det_puntos = {};
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              viaje_id: this.viaje_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_puntos = response.data;
        });
    },

    getDatosInspecciones() {
      this.accion = 3;
      this.det_inspecciones = {};
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              viaje_id: this.viaje_id,
              det_postulacion_id: this.viaje.det_postulacion_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_inspecciones = response.data;
        });
    },

    limpiarModal() {
      this.viaje = {};
      this.viaje_id = null;
      this.visualizacion = null;
    },

    getEscanerGuia() {
      this.visualizacion = null;
      this.tipo_archivo = null;
      if (
        this.link.toLowerCase().endsWith(".pdf") ||
        this.link.toLowerCase().endsWith(".PDF")
      ) {
        this.tipo_archivo = "pdf";
      }
      if (
        this.link.toLowerCase().endsWith(".jpg") ||
        this.link.toLowerCase().endsWith(".png") ||
        this.link.toLowerCase().endsWith(".JPG") ||
        this.link.toLowerCase().endsWith(".PNG")
      ) {
        this.tipo_archivo = "img";
      }
      this.visualizacion =
        this.tipo_archivo == "pdf"
          ? "https://docs.google.com/gview?url=" +
            this.uri_docs +
            this.link +
            "&embedded=true"
          : this.tipo_archivo == "img"
          ? this.uri_docs + this.link
          : null;
    },

    getDatosMapa() {
      this.accion = 5;
      this.det_mapa = [];
      this.det_ruta = [];
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              viaje_id: this.viaje_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_mapa = response.data.posiciones;
          this.det_ruta = response.data.ruta;
        });
    },
  },
};
</script>
