var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tipo_autorizacion == 1)?_c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-autorizacion-formato","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Autorización Descargue")]),_c('button',{ref:"closeModalAut",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('label',[_vm._v("Tipo Autorización")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
              !_vm.slct_aut_desc ? 'is-invalid' : 'is-valid',
              _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
            ],attrs:{"label":"descripcion","options":_vm.listasForms.autorizaciones_descargue},on:{"input":function($event){_vm.form.justificacion = null}},model:{value:(_vm.slct_aut_desc),callback:function ($$v) {_vm.slct_aut_desc=$$v},expression:"slct_aut_desc"}})],1),(_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 2)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Vehículo Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehiculoPlacaAnt),expression:"form.vehiculoPlacaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"vehiculo","readonly":""},domProps:{"value":(_vm.form.vehiculoPlacaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "vehiculoPlacaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Vehículo Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.vehiculos},on:{"search":_vm.buscarVehiculos,"input":_vm.validarTipoVehiculo},model:{value:(_vm.form.vehiculo),callback:function ($$v) {_vm.$set(_vm.form, "vehiculo", $$v)},expression:"form.vehiculo"}}),(!_vm.$v.form.vehiculo.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Placa ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.slct_aut_desc &&
              _vm.slct_aut_desc.numeracion == 2 &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.vehiculo.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioVehiculo()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 3)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"remolque"}},[_vm._v("Remolque Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remolquePlacaAnt),expression:"form.remolquePlacaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"remolque","readonly":""},domProps:{"value":(_vm.form.remolquePlacaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "remolquePlacaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Remolque Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.remolques},on:{"search":_vm.buscarRemolques},model:{value:(_vm.form.remolque),callback:function ($$v) {_vm.$set(_vm.form, "remolque", $$v)},expression:"form.remolque"}}),(!_vm.$v.form.remolque.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Placa ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.slct_aut_desc &&
              _vm.slct_aut_desc.numeracion == 3 &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.remolque.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioRemolque()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 4)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Conductor Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.conductorAnt),expression:"form.conductorAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"conductor","readonly":""},domProps:{"value":(_vm.form.conductorAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "conductorAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Conductor Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Nombre Conductor","label":"nombre","options":_vm.listasForms.conductores,"filterable":false},on:{"search":_vm.buscarConductores},model:{value:(_vm.form.conductor),callback:function ($$v) {_vm.$set(_vm.form, "conductor", $$v)},expression:"form.conductor"}}),(!_vm.$v.form.conductor.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese un Conductor ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.slct_aut_desc &&
              _vm.slct_aut_desc.numeracion == 4 &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.conductor.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioConductor()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 5)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"ruta"}},[_vm._v("Ruta actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rutaAnt),expression:"form.rutaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"ruta","readonly":""},domProps:{"value":(_vm.form.rutaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rutaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"ruta"}},[_vm._v("Ruta sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.ruta.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Ruta","label":"nombre","options":_vm.listasForms.rutas},on:{"search":_vm.buscarRutas,"input":_vm.validarTarifaRuta},model:{value:(_vm.form.ruta),callback:function ($$v) {_vm.$set(_vm.form, "ruta", $$v)},expression:"form.ruta"}}),(!_vm.$v.form.ruta.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una ruta ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.slct_aut_desc &&
              _vm.slct_aut_desc.numeracion == 5 &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.ruta.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioRuta()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(
            (_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 19) ||
              (_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 20) ||
              (_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 21) ||
              (_vm.slct_aut_desc && _vm.slct_aut_desc.numeracion == 22)
          )?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.slct_aut_desc &&
              (_vm.slct_aut_desc.numeracion == 19 ||
                _vm.slct_aut_desc.numeracion == 20 ||
                _vm.slct_aut_desc.numeracion == 21 ||
                _vm.slct_aut_desc.numeracion == 22) &&
              !_vm.$v.form.justificacion.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioAutorizacion()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e()])])])]):_c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-cambio-trinomios","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Cambio "+_vm._s(_vm.entidad))]),_c('button',{ref:"closeModalAut",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),(_vm.entidad && _vm.entidad == 'Vehículo')?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Vehículo Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehiculoPlacaAnt),expression:"form.vehiculoPlacaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"vehiculo","readonly":""},domProps:{"value":(_vm.form.vehiculoPlacaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "vehiculoPlacaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Vehículo Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.vehiculos},on:{"search":_vm.buscarVehiculos,"input":_vm.validarTipoVehiculo},model:{value:(_vm.form.vehiculo),callback:function ($$v) {_vm.$set(_vm.form, "vehiculo", $$v)},expression:"form.vehiculo"}}),(!_vm.$v.form.vehiculo.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Placa ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.entidad &&
              _vm.entidad == 'Vehículo' &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.vehiculo.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioVehiculo()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.entidad && _vm.entidad == 'Remolque')?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"remolque"}},[_vm._v("Remolque Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remolquePlacaAnt),expression:"form.remolquePlacaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"remolque","readonly":""},domProps:{"value":(_vm.form.remolquePlacaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "remolquePlacaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"vehículo"}},[_vm._v("Remolque Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.remolques},on:{"search":_vm.buscarRemolques},model:{value:(_vm.form.remolque),callback:function ($$v) {_vm.$set(_vm.form, "remolque", $$v)},expression:"form.remolque"}}),(!_vm.$v.form.remolque.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Placa ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.entidad &&
              _vm.entidad == 'Remolque' &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.remolque.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioRemolque()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.entidad && _vm.entidad == 'Conductor')?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Conductor Actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.conductorAnt),expression:"form.conductorAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"conductor","readonly":""},domProps:{"value":(_vm.form.conductorAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "conductorAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Conductor Sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Nombre Conductor","label":"nombre","options":_vm.listasForms.conductores},on:{"search":_vm.buscarConductores},model:{value:(_vm.form.conductor),callback:function ($$v) {_vm.$set(_vm.form, "conductor", $$v)},expression:"form.conductor"}}),(!_vm.$v.form.conductor.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese un Conductor ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.entidad &&
              _vm.entidad == 'Conductor' &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.conductor.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioConductor()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e(),(_vm.entidad && _vm.entidad == 'Ruta')?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"ruta"}},[_vm._v("Ruta actual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rutaAnt),expression:"form.rutaAnt"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"ruta","readonly":""},domProps:{"value":(_vm.form.rutaAnt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rutaAnt", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"ruta"}},[_vm._v("Ruta sustituye")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.ruta.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Ruta","label":"nombre","options":_vm.listasForms.rutas},on:{"search":_vm.buscarRutas,"input":_vm.validarTarifaRuta},model:{value:(_vm.form.ruta),callback:function ($$v) {_vm.$set(_vm.form, "ruta", $$v)},expression:"form.ruta"}}),(!_vm.$v.form.ruta.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una ruta ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}}),(!_vm.$v.form.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificación ")]):_vm._e()])])]):_vm._e(),(
            _vm.entidad &&
              _vm.entidad == 'Ruta' &&
              !_vm.$v.form.justificacion.$invalid &&
              !_vm.$v.form.ruta.$invalid
          )?_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.cambioRuta()}}},[_vm._v(" Realizar Cambio ")])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }