<template>
  <div>
    <!-- Modal Cambio Vehiculo-->
    <div
      class="modal fade"
      id="modal-form-mapa"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Mapa Trazabilidad Vehículo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiar()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="btn-group float-right pl-2">
                <button
                  type="button"
                  class="btn btn-success btn-lg"
                  @click="generarListadoExcel()"
                >
                  <i class="far fa-file-excel"></i>
                </button>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col">
                <gmap-map
                  :center="center"
                  :zoom="14"
                  style="width: 100%; height: 400px"
                  ref="map"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position.sync="m.position"
                    :icon="m.icon"
                    :clickable="true"
                    :draggable="false"
                    @click="
                      (center = m.position),
                        toggleInfoWindow(m.position, m.id, m.info)
                    "
                  ></gmap-marker>
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                  >
                    <div v-html="infoContent"></div>
                  </gmap-info-window>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "VentanaOperacionesMapa",

  data() {
    return {
      viaje: null,
      fecha: null,
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      markers: [],
      path: [],
      currentPlace: null,
      center: {
        lat: 3.780728474,
        lng: -71.62614209,
      },
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  methods: {
    getTrazabilidad() {
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/generarTrazabilidadMapa?vehiculo_id=" +
            this.vehiculo_id +
            "&fecha=" +
            this.fecha
        )
        .then((response) => {
          this.puntosTraza = response.data;
          this.trazaViaje();
        });
    },

    toggleInfoWindow: function (position, idx, ventana) {
      this.infoWindowPos = position;
      this.infoContent = ventana;

      //compruebe si es el mismo marcador que se seleccionó en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si es un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    trazaViaje() {
      if (this.puntosTraza.length > 0) {
        for (let index = 0; index < this.puntosTraza.length; index++) {
          const marker = {
            lat: parseFloat(this.puntosTraza[index].latitud),
            lng: parseFloat(this.puntosTraza[index].longitud),
          };
          this.markers.push({
            id: this.puntosTraza[index].id,
            position: marker,
            icon: "/img/icon_punt_ruta.png",
            info: this.puntosTraza[index].ventana,
          });
        }
        this.puntoInicialLat = parseFloat(this.puntosTraza[0].latitud);
        this.puntoInicialLng = parseFloat(this.puntosTraza[0].longitud);
        this.infoPuntoInicial = this.puntosTraza[0].ventana;

        this.markers.push({
          id: this.puntosTraza[0].id,
          position: {
            lat: this.puntoInicialLat,
            lng: this.puntoInicialLng,
          },
          icon: "/img/icon_punto_g_ok.png",
          info: this.infoPuntoInicial,
        });

        this.puntofin = this.puntosTraza.slice(-1);
        this.puntoFinalLat = parseFloat(this.puntofin[0].latitud);
        this.puntoFinalLng = parseFloat(this.puntofin[0].longitud);
        this.infoPuntoFinal = this.puntofin[0].ventana;

        this.markers.push({
          id: this.puntofin[0].id,
          position: {
            lat: this.puntoFinalLat,
            lng: this.puntoFinalLng,
          },
          icon: "/img/icon_punto_g.png",
          info: this.infoPuntoFinal,
        });

        const marker1 = {
          lat: parseFloat(this.puntosTraza[0].latitud),
          lng: parseFloat(this.puntosTraza[0].longitud),
        };
        this.center = marker1;
      } else {
        this.$refs.closeModal.click();
        this.$swal({
          title: "Tramas no encontradas!",
          text: "No se encontraron tramas para este viaje",
          icon: "warning",
        });
      }
    },

    limpiar() {
      this.markers = [];
      this.vehiculo_id = null;
    },

    llenar_modal_mapa(item, fecha) {
      this.vehiculo_id = item.vehiculo_id;
      this.fecha = fecha.slice(0, 10);
      this.viaje = item;
      this.getTrazabilidad();
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/viajes/trazaExport",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {},
};
</script>
