<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-despacho-manual"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Despacho Manual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="fecha">Fecha</label>
              <input
                type="date"
                class="form-control form-control-sm"
                id="fecha_inicial"
                v-model="form.fecha"
                :class="$v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'"
              />
              <div class="error" v-if="!$v.form.fecha.required">
                Ingrese una Fecha
              </div>
            </div>
            <div class="form-group">
              <label for="hora">Hora</label>
              <!-- <input
                type="time"
                class="form-control form-control-sm"
                id="fecha_inicial"
                v-model="form.hora"
                :class="$v.form.hora.$invalid ? 'is-invalid' : 'is-valid'"
              /> -->
              <input
                type="text"
                class="form-control form-control-sm"
                id="fecha_inicial"
                v-model="form.hora"
                :class="$v.form.hora.$invalid ? 'is-invalid' : 'is-valid'"
                v-mask="{
                  mask: '99:99',
                  hourFormat: '24',
                }"
              />
              <div class="error" v-if="!$v.form.hora.required">
                Ingrese una Hora
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.fecha.$invalid && !$v.form.hora.$invalid"
              @click="despachoManual()"
            >
              Realizar despacho manual
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength,helpers } from "vuelidate/lib/validators";
import moment from "moment";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "VentanaOperacionesDespacho", //llegada tambien
  components: {},
  data() {
    return {
      form: {
        fecha: null,
        hora: null,
      },
      tipo: 0,
    };
  },
  validations: {
    form: {
      fecha: {
        required,
      },
      hora: {
        required,timeFormat,
      },
    },
  },
  methods: {
    despachoManual(viaje, sitio) {
      if (!this.$v.form.$invalid)
        axios({
          method: "POST",
          url:
            "api/hidrocarburos/ventanasEnturnamientoOperacion/despachoManual",
          data: this.form,
        }).then(() => {
          if (this.tipo == 1) {
            this.$parent.$refs.VentanaOperacionesVentanas.selectVentanasEnturne();
          } else {
            this.$parent.getIndex();
          }

          this.$refs.closeModal1.click();
          this.$swal({
            icon: "success",
            title: "Se despacho el vehiculo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenar_modal_despacho(viaje, sitio, sitio_type, ventana_enturnamiento_id) {
      this.form.viaje_id = viaje;
      this.form.sitio_id = sitio;
      this.form.sitio_type = sitio_type;
      this.form.fecha = moment().format("YYYY-MM-DD");
      this.form.hora = moment().format("HH:mm:ss");
      this.form.ventana_enturnamiento_id = ventana_enturnamiento_id;
      this.tipo = 2;
    },

    llenar_modal_despacho2(viaje, sitio, sitio_type, ventana, turnoAct, tipo) {
      this.form.viaje_id = viaje;
      this.form.sitio_id = sitio;
      this.form.sitio_type = sitio_type;
      this.form.ventana_enturnamiento_id = ventana;
      this.form.turnoAct = turnoAct;
      this.form.fecha = moment().format("YYYY-MM-DD");
      this.form.hora = moment().format("HH:mm:ss");
      this.tipo = tipo;
    },
  },
};
</script>
