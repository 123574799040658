<template>
  <div class="row" v-if="$parent.det_puntos.length > 0">
    <table class="table table-sm table-hover" style="font-size: 0.85em;">
      <thead class="thead-dark">
        <tr class="text-center">
          <th scope="col">Nombre Sitio/Punto gestión</th>
          <th scope="col">Llegada</th>
          <th scope="col">Salida</th>
          <th scope="col">Tiempo en punto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in $parent.det_puntos" :key="item.id">
          <th>{{ item.sitio.nombre }}</th>
          <td class="text-center">{{ item.hora_llegada }}</td>
          <td class="text-center">
            {{ item.actividad == 2 ? item.hora_salida : "N/A" }}
          </td>
          <td class="text-center">{{ item.tiempo_punto }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" v-else>
    <div class="alert alert-warning col-md-12">
      <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
      No se encontraron Puntos de Gestión para este Viaje!
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViajesInfoPuntos",

  data() {
    return {
      det_puntos: {},
    };
  },
  methods: {},

  mounted() {},
};
</script>
