var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-escaner-guia","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog",staticStyle:{"width":"380px"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Escáner Guía")]),_c('button',{ref:"closeModal4",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-body rounded",class:_vm.file_pdf
                ? 'alert-default-success border border-success'
                : !_vm.file_pdf && _vm.escaner_guia
                ? 'border border-primary'
                : 'alert-default-warning border border-warning'},[(_vm.escaner_guia)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b',[_vm._v("Guía: "+_vm._s(_vm.numero_guia))]),_c('br'),_c('b',[_vm._v("Placa: "+_vm._s(_vm.placa))])]),_c('div',{staticClass:"col-md-6 text-right"},[(
                      _vm.$store.getters.can(
                        'hidrocarburos.ventanasOperaciones.eliminarEscanerGuia'
                      )
                    )?_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":_vm.destroyfile}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])])]):_vm._e(),(
                !_vm.escaner_guia &&
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.cargarEscaner'
                  )
              )?_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"link_pdf"}},[_vm._v("Subir PDF")]),_c('br'),_c('div',{staticClass:"form-group"},[_c('input',{ref:"link_pdf",staticClass:"form-control-file",class:_vm.file_pdf
                      ? 'btn btn-sm border border-success alert-success'
                      : 'btn btn-sm border border-warning alert-warning',staticStyle:{"min-width":"320px"},attrs:{"type":"file","id":"link_pdf","accept":".pdf, .PDF, .jpg, .JPG, .png, .PNG"},on:{"change":function($event){return _vm.elegirDirectorio()}}})]),(_vm.loading)?_c('div',{staticClass:"spinner-border text-success",attrs:{"role":"status"}}):_vm._e(),(_vm.loading)?_c('b',{staticClass:"text-success"},[_vm._v(" Cargando...")]):_vm._e(),(!_vm.loading && _vm.file_pdf)?_c('button',{staticClass:"btn btn-lg btn-success",attrs:{"id":"guardar_documento","type":"button","data-html":"true","title":"Guardar Documento","data-toggle":"tooltip"},on:{"click":function($event){return _vm.saveFiles()}}},[_c('span',[_vm._v("Cargar")])]):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"progress mt-3"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated bg-success",class:_vm.progress < 50
                    ? 'bg-danger'
                    : _vm.progress > 50 && _vm.progress < 98
                    ? 'bg-warning'
                    : 'bg-success',style:('width:' + _vm.progress + '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progress)+"% ")])]):_vm._e()])]),_c('div',{staticClass:"modal-footer"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }