<template>
  <div>
    <!-- Modal Adjunto Foto-->
    <div
      class="modal fade"
      id="modal-subir-foto-guia"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="width: 380px">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Foto Orden Cargue</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal4"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="conductor">Numero de viaje</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="conductor"
                      disabled="disabled"
                      v-model="form.viaje_id"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="orden_cargue">N° Orden de Cargue</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="form.orden_de_cargue"
                      :class="
                        $v.form.orden_de_cargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="!$v.form.$invalid"
                    />
                  </div>
                </div>
              </div>
              <!-- <div v-if="imagenCargada == null">
                <div class="form-group col-md-12">
                  <label for="link_imagen">Link imagen</label>
                  <div class="form-group">
                    <input
                      type="file"
                      id="link_imagen"
                      ref="link_imagen"
                      class="form-control btn btn-light"
                      accept=".jpg, .JPG, .png, .PNG"
                      @change="elegirDirectorio()"
                      :class="$v.form.file.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                </div>
              </div> -->
              <div class="button-container">
                <img
                  :src="imagenCargada"
                  class="img-fluid mb-2"
                  border="1"
                  alt="Imagen Orden de carga"
                  width="360"
                  height="360"
                />
                <a :href="imagenCargada" target="_blank" class="btn btn-link"
                  >Ver imagen</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!--  <div class="row">
              <button
                id="guardar_foto"
                type="button"
                class="btn btn-lg btn-success"
                data-html="true"
                title="Guardar Imagen"
                data-toggle="tooltip"
                @click="saveImagen()"
                v-if="imagenCargada == null"
                v-show="!$v.form.$invalid"
              >
                <i class="fa fa-file-upload"></i> <br />
                Subir
              </button>
              <div align="center">
                <button
                  id="borrar_foto"
                  type="button"
                  class="btn btn-lg btn-danger"
                  data-html="true"
                  title="Borrar Imagen"
                  data-toggle="tooltip"
                  @click="destroyImage()"
                  v-if="imagenCargada != null"
                >
                  <i class="fa fa-trash-alt"></i> <br />
                  Eliminar
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "VentanaOperacionesAdjuntos",
  components: {},
  data() {
    return {
      cargar: null,
      imagenCargada: null,
      form: {
        viaje_id: null,
        orden_de_cargue: null,
        file: null,
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      orden_de_cargue: {
        required,
      },
      file: {
        required,
      },
    },
  },

  methods: {
    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/imagen/" +
                this.form.viaje_id
            )
            .then(() => {
              this.imagenCargada = null;
              this.form.file = null;
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch(function (error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    saveImagen() {
      let formData = new FormData();
      formData.append("imagen", this.form.file);
      formData.append("tipo_adjunto", 1); //orden de cargue
      formData.append("viaje_id", this.form.viaje_id);
      formData.append("orden_de_cargue", this.form.orden_de_cargue);
      if (this.form.file != null) {
        axios
          .post(
            "/api/hidrocarburos/ventanasEnturnamientoOperacion/saveImagenOrden",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.imagenCargada = this.uri_docs + response.data.path;
            this.form.link_fotografia = response.data.path;
            this.$swal({
              icon: "success",
              title: "Las imagen se guardo correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar al menos una imagen",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    elegirDirectorio() {
      this.form.file = this.$refs.link_imagen.files[0];
    },
    llenar_modal_foto_orden(viaje) {
      this.form.viaje_id = viaje.id;
      this.form.orden_de_cargue = viaje.orden_de_cargue;
      this.form.link_fotografia = viaje.link_orden_cargue;
      this.imagenCargada = this.uri_docs + viaje.link_orden_cargue;
    },
  },
};
</script>
