<template>
  <div>
    <!-- Modal Cambio Vehiculo-->
    <div
      class="modal fade"
      id="modal-form-cambio-postulacion"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Postulación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="vehiculo"
                    readonly
                    v-model="form.vehiculoPlacaAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Sustituye</label>
                  <v-select
                    :class="[
                      $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="form.vehiculo"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.vehiculos"
                    @search="buscarVehiculos"
                  ></v-select>
                  <div class="error" v-if="!$v.form.vehiculo.required">
                    Ingrese una Placa
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="remolque">Remolque Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="remolque"
                    readonly
                    v-model="form.remolquePlacaAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Remolque Sustituye</label>
                  <v-select
                    :class="[
                      $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="form.remolque"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :filterable="false"
                    :options="listasForms.remolques"
                    @search="buscarRemolques"
                  ></v-select>
                  <div class="error" v-if="!$v.form.remolque.required">
                    Ingrese una Placa
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="conductor">Conductor Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="conductor"
                    readonly
                    v-model="form.conductorAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="conductor">Conductor Sustituye</label>
                  <v-select
                    :class="[
                      $v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="form.conductor"
                    placeholder="nombre"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :filterable="false"
                    :options="listasForms.conductores"
                    @search="buscarConductores"
                  ></v-select>
                  <div class="error" v-if="!$v.form.conductor.required">
                    Ingrese una nombre
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="orden_cargue_anterior"
                    >Orden de cargue anterior:</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="orden_cargue_anterior"
                    v-model="form.orden_cargueAnt"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="orden_cargue">Orden de cargue:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="orden_cargue"
                    v-model="form.orden_cargue"
                    :class="
                      $v.form.orden_cargue.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                  <div class="error" v-if="!$v.form.orden_cargue.required">
                    Ingrese el número de orden de cargue
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="adjunto_orden_cargue"
                    >Adjunto Orden de cargue:</label
                  >
                  <div v-if="file === null">
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      class="form-control-file"
                      accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                      @change="elegirDirectorio()"
                    />
                    <div class="error" v-if="file === null">
                      Seleccione la orden de cargue
                    </div>
                  </div>
                  <div v-else>
                    <div class="btn-group float-left">
                      <button
                        type="button"
                        class="btn btn-sm bg-danger"
                        @click="borrarFotoParcial()"
                      >
                        Eliminar
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificación</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificación
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-dark"
              v-show="!$v.form.$invalid && file !== null"
              @click="cambioPostulacion()"
            >
              <i class="fas fa-marker"> Realizar Cambio</i>
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "VentanaOperacionesCambioPostulacion",
  components: {
    vSelect,
  },
  data() {
    return {
      file: null,
      form: {
        vehiculo: null,
        conductor: null,
        remolque: null,
        vehiculoPlacaAnt: null,
        remolquePlacaAnt: null,
        conductorAnt: null,
        orden_cargueAnt: null,
        orden_cargue: null,
        link_orden_cargue: null,
      },
      listasForms: {
        conductores: [],
        vehiculos: [],
        remolques: [],
        rutas: [],
      },
    };
  },
  validations: {
    form: {
      vehiculo: {
        required,
      },
      remolque: {
        required,
      },
      conductor: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
      orden_cargue: {
        required,
      },
    },
  },
  methods: {
    cambioPostulacion() {
      this.$swal({
        title: "Esta seguro de cambiar de postulación?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("vehiculoAnt_id", this.form.vehiculo_id);
          formData.append("vehiculoAnt_placa", this.form.vehiculoPlacaAnt);
          formData.append("remolqueAnt_id", this.form.remolque_id);
          formData.append("remolqueAnt_placa", this.form.remolquePlacaAnt);
          formData.append("conductorAnt_id", this.form.conductor_id);
          formData.append("conductorAnt_nombre", this.form.conductorAnt);
          formData.append("orden_cargueAnt", this.form.orden_cargueAnt);
          formData.append("link_orden_cargueAnt", this.form.link_orden_cargue);
          formData.append("id", this.form.postulacion_id);
          formData.append("vehiculo_id", this.form.vehiculo.id);
          formData.append("vehiculo_placa", this.form.vehiculo.placa);
          formData.append("remolque_id", this.form.remolque.id);
          formData.append("remolque_placa", this.form.remolque.placa);
          formData.append("conductor_id", this.form.conductor.id);
          formData.append("conductor_nombre", this.form.conductor.nombre);
          formData.append("orden_cargue", this.form.orden_cargue);
          formData.append("link_orden_cargue", this.file);
          formData.append("justificacion", this.form.justificacion);
          let res = null;
          axios
            .post(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarPostulacion",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              this.$refs.closeModal1.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                conductorAnt: null,
                orden_cargueAnt: null,
                orden_cargue: null,
                link_orden_cargue: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    buscarVehiculos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRemolques(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/remolques/lista?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.remolques = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductores(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRutas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/admin/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.rutas = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    elegirDirectorio() {
      let vm = this;
      vm.file = vm.$refs.file.files[0];
    },
    borrarFotoParcial() {
      this.file = null;
    },

    llenar_modal_cambiar_postulacion(postulacion) {
      this.form.postulacion_id = postulacion.id;
      this.form.vehiculo_id = postulacion.vehiculo_id;
      this.form.vehiculoPlacaAnt = postulacion.vehiculo.placa;
      this.form.conductor_id = postulacion.conductor_id;
      this.form.conductorAnt = `${postulacion.conductor.numero_documento} - ${postulacion.conductor.nombres} ${postulacion.conductor.apellidos}`;
      this.form.remolque_id = postulacion.remolque_id;
      this.form.remolquePlacaAnt = postulacion.remolque.placa;
      this.form.orden_cargueAnt = postulacion.orden_cargue;
      this.form.link_orden_cargue = postulacion.link_orden_cargue;
    },
  },
};
</script>
