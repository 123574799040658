var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-inspeccion","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Inspección")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-striped table-bordered table-hover table-md"},[_vm._m(0),(_vm.inspecciones.length > 0 && _vm.tipo == 1)?_c('tbody',_vm._l((_vm.arrayFinal),function(item){return _c('tr',{key:item.formato_inspeccion_id},[(
                      _vm.viaje.guia_id != null &&
                        item.info_formato.tipo_operacion == 2
                    )?_c('td',[_vm._v(" "+_vm._s(item.info_formato.nombre)+" ")]):(
                      _vm.viaje.guia_id == null &&
                        item.info_formato.tipo_operacion == 1
                    )?_c('td',[_vm._v(" "+_vm._s(item.info_formato.nombre)+" ")]):_vm._e(),(
                      item.info == null &&
                        _vm.viaje.guia_id != null &&
                        item.info_formato.tipo_operacion == 2
                    )?_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-info float-lg-right",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.inspeccion(_vm.viaje, item.info_formato)}}},[_c('i',{staticClass:"fas fa-plus-square"})])]):(
                      item.info == null &&
                        _vm.viaje.inspecciones.length == 0 &&
                        _vm.viaje.guia_id == null &&
                        item.info_formato.tipo_operacion == 1
                    )?_c('td',[(item.info == null)?_c('button',{staticClass:"btn btn-info float-lg-right",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.inspeccion(_vm.viaje, item.info_formato)}}},[_c('i',{staticClass:"fas fa-plus-square"})]):_c('button',{staticClass:"btn btn-sm btn-default py-0",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.edit(item.info.id)}}},[_vm._v(" "+_vm._s(item.info.id)+" ")])]):_vm._e()])}),0):_c('tbody',[_c('td',[_vm._v("Inspección preoperacional")]),_c('td',[_c('button',{staticClass:"btn btn-info float-lg-right",staticStyle:{"cursor":"pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.inspeccionPreoperacional(_vm.viaje)}}},[_c('i',{staticClass:"fas fa-plus-square"})])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Inspeccion")]),_c('th',[_vm._v("Acción")])])])
}]

export { render, staticRenderFns }