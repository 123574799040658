<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-val-inspeccion"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082;"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Validación Inspección</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body p-0">
              <!-- Con Inspeccion Support / tiene el permiso-->
              <div
                v-if="
                  Object.keys(inspeccionesSupport).length > 0 &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.validarInspeccion'
                    )
                "
              >
                <div
                  class="alert"
                  :class="
                    inspeccionesSupport.estado != 3
                      ? 'alert-default-success'
                      : 'alert-default-warning'
                  "
                >
                  <h5>
                    <i
                      class="icon"
                      :class="
                        inspeccionesSupport.estado != 3
                          ? 'fas fa-check'
                          : 'fas fa-exclamation-triangle'
                      "
                    ></i>
                    <strong>Atención.</strong>
                  </h5>
                  <p class="pt-2 mt-2">
                    {{
                      inspeccionesSupport.estado != 3
                        ? "Se encuentra con una inspección vigente."
                        : "Se encuentra con una inspección vigente, pero con novedad."
                    }}
                  </p>
                </div>
                <table
                  class="table table-striped table-bordered table-hover table-md"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Inspección</th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ inspeccionesSupport.nFormato }}
                        <p>
                          Fecha Inicial :
                          <span class="badge badge-info">
                            {{ inspeccionesSupport.fecha_inicial }}</span
                          >
                          - Fecha Final :
                          <span class="badge badge-info">
                            {{ inspeccionesSupport.fecha_final }}</span
                          >
                        </p>
                      </td>
                      <td class="text-center">
                        <a
                          class="btn btn-danger"
                          style="cursor: pointer"
                          :href="linkIn + inspeId"
                          target="_blank"
                        >
                          <i class="fas fa-file-pdf"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Con Inspección Support / no tiene el permiso -->
              <div
                v-else-if="
                  Object.keys(inspeccionesSupport).length > 0 &&
                    !$store.getters.can(
                      'hidrocarburos.ventanasOperaciones.validarInspeccion'
                    )
                "
              >
                <div class="alert alert-default-warning">
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i>
                    <strong>Atención.</strong>
                  </h5>
                  <p>No existe solicitud de inspección.</p>
                </div>
              </div>
              <!-- Sin Inspeccion Support -->
              <div v-else>
                <div
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                    ) ||
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                      )
                  "
                >
                  <!-- Solicitud Enviada -->
                  <div
                    class="alert alert-default-primary"
                    v-if="viaje.val_inspeccion == 1"
                  >
                    <h5>
                      <i class="icon fas fa-envelope-open"></i>
                      <strong>Atención.</strong>
                      <p class="mt-2 pt-2">Solicitud inspección enviada.</p>
                    </h5>
                  </div>
                  <!-- Solicitud Aceptada -->
                  <div
                    class="alert alert-default-success"
                    v-else-if="viaje.val_inspeccion == 2"
                  >
                    <h5>
                      <i class="icon fas fa-check"></i>
                      <strong>Atención.</strong>
                      <p class="mt-2 pt-2">Solicitud inspección aceptada.</p>
                    </h5>
                  </div>
                  <!-- Solicitud Rechazada-->
                  <div
                    class="alert alert-default-danger"
                    v-else-if="viaje.val_inspeccion == 3"
                  >
                    <h5>
                      <i class="icon fas fa-times-circle"></i>
                      <strong>Atención.</strong>
                      <p class="mt-2 pt-2">Solicitud inspección rechazada.</p>
                    </h5>
                  </div>
                  <!-- Sin Inspección -->
                  <div v-else>
                    <div class="alert alert-default-warning">
                      <h5>
                        <i class="icon fas fa-exclamation-triangle"></i>
                        <strong>Atención.</strong>
                      </h5>
                      <p>
                        No se encuentra con una inspección vigente.
                        <span
                          v-if="viaje.val_inspeccion == null"
                          type="button"
                          class="bagde bg-gradient-info btn-sm"
                          style="cursor: pointer"
                          @click="mailSolicitar(viaje.id)"
                        >
                          <i class="fas fa-envelope"></i> Solicitar Inspección
                        </span>
                      </p>
                    </div>

                    <div
                      v-if="correosEmpresa.length && correosEmpresa.length > 0"
                    >
                      <table
                        class="table table-striped table-bordered table-hover table-md"
                      >
                        <thead class="bg-dark">
                          <tr>
                            <td class="text-center" colspan="2">
                              {{ viaje.transportadora.razon_social }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-center">#</th>
                            <th class="text-center">Correos</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(correoEmpresa, index) in correosEmpresa"
                            :key="index"
                          >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              {{ correoEmpresa.correo }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span class="badge bg-warning text-center" v-else>
                      No se encontrarón correos asociados, comunicarse con los
                      administradores.
                    </span>
                  </div>
                  <!-- Tabla Para realizar la inspección pre-operacional -->
                  <table
                    class="table table-striped table-bordered table-hover table-md"
                    v-show="viaje.val_inspeccion == 2"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th>Inspección</th>
                        <th
                          class="text-center"
                          v-if="
                            viaje.inspecciones && viaje.inspecciones.length > 0
                          "
                        >
                          PDF
                        </th>
                        <th class="text-center">Acción</th>
                      </tr>
                    </thead>
                    <!-- Datos Realizar Inspección Preoperacional -->
                    <tbody
                      v-if="
                        ($store.getters.can(
                          'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                        ) ||
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                          )) &&
                          (!viaje.inspecciones ||
                            viaje.inspecciones.length == 0)
                      "
                    >
                      <td>Inspección Preoperacional</td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-info"
                          style="cursor: pointer"
                          @click="inspeccionPreoperacional(viaje)"
                        >
                          <i class="fas fa-plus-square"></i>
                        </button>
                      </td>
                    </tbody>
                    <!-- Datos Permiso Puntos Verificación -->
                    <tbody
                      v-else-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                        ) &&
                          viaje.inspecciones &&
                          viaje.inspecciones.length > 0
                      "
                    >
                      <td>
                        {{ viaje.inspecciones[0].formato_inspeccion.nombre }}
                        <p>
                          Fecha Inicial :
                          <span class="badge badge-info">
                            {{ viaje.inspecciones[0].fecha_inicio }}
                            {{ viaje.inspecciones[0].hora_inicio }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm bg-danger"
                          @click="verPDF(viaje.inspecciones[0].id)"
                        >
                          <i class="fas fa-file-pdf"></i>
                        </button>
                      </td>
                      <td>
                        <div
                          class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="vencimiento"
                            v-model="form.verifica"
                            :disabled="!habilitarCheck"
                          />
                          <label class="custom-control-label" for="vencimiento"
                            >Verificar <span></span
                          ></label>
                          <span v-if="!habilitarCheck" class="badge bg-warning"
                            >Abrir Pdf</span
                          >
                          <span v-else class="badge bg-success"
                            >Ya puedes Verificar</span
                          >
                        </div>
                      </td>
                    </tbody>
                    <!-- Datos Permiso Visualización Inspección Transportadora  -->
                    <tbody
                      v-else-if="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.inspeccionTransportadora'
                        ) && viaje.inspecciones.length > 0
                      "
                    >
                      <td>
                        {{ viaje.inspecciones[0].formato_inspeccion.nombre }}
                        <p>
                          Fecha Inicial :
                          <span class="badge badge-info">
                            {{ viaje.inspecciones[0].fecha_inicio }}
                            {{ viaje.inspecciones[0].hora_inicio }}</span
                          >
                        </p>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-danger"
                          @click="verPDF(viaje.inspecciones[0].id)"
                        >
                          <i class="fas fa-file-pdf"></i>
                        </button>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          style="cursor: pointer"
                          @click="edit(viaje, viaje.inspecciones[0].id)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </td>
                    </tbody>
                  </table>
                  <div class="col-12" v-show="habilitarCheck">
                    <label for="">Observación</label>
                    <textarea
                      v-model="form.observacion"
                      cols="30"
                      rows="5"
                      class="form-control form-control-sm"
                      :class="
                        form.observacion == null || form.observacion == ''
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
                <div v-else>
                  <!-- Sin permiso transportadora inspección -->
                  <div class="alert alert-default-warning">
                    <h5>
                      <i class="icon fas fa-exclamation-triangle"></i>
                      <strong>Atención.</strong>
                      <p class="mt-2 pt-2">
                        No cuenta con permisos, Por favor comunicarse con los
                        administradores.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="Object.keys(inspeccionesSupport).length > 0"
              type="button"
              class="btn btn-primary"
              @click="crearInspeccion()"
            >
              {{
                inspeccionesSupport.estado != 3
                  ? "Crear Inspección"
                  : "Enviar Correo"
              }}
            </button>

            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                form.observacion &&
                  $store.getters.can(
                    'hidrocarburos.ventanasOperaciones.verificacionInspeccion'
                  )
              "
            >
              Aprobar Inspección
            </button>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cerrar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
//import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "VentanaOperacionesInspeccionVal",
  components: {
    //vSelect,
  },
  data() {
    return {
      linkIn: "https://www.supportsos7-24.com/inspeciones_llenar/imp/",
      inspeccionesSupport: {},
      correosEmpresa: {},
      viaje: {},
      inspeId: null,
      habilitarCheck: false,
      form: {
        viaje_id: null,
        sitio_id: null,
        vehiculo: null,
        num_empresa: null,
        verifica: false,
      },
    };
  },
  validations: {},
  methods: {
    crearInspeccion() {
      this.$swal({
        title: "Esta seguro de crear una inspección?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/solicitarInspeccionarSupp?viaje_id=" +
                this.form.viaje_id,
              {
                placa: this.viaje.vehiculo.placa,
                vehiculo_id: this.viaje.vehiculo_id,
                remolque_id: this.viaje.remolque_id,
                conductor_id: this.viaje.conductor_id,
                fecha_inicio: moment().format("YYYY-MM-DD"),
                hora_inicio: moment().format("HH:mm:ss"),
                sitio_id: this.$parent.filtros.sitio_id,
                sitio_type: this.$parent.filtros.sitio_type,
                empresa_id: this.viaje.transportadora_id,
                estadoInsSupp: this.inspeccionesSupport.estado,
                observaciones: this.linkIn + this.inspeId,
              }
            )
            .then((response) => {
              if (response.data.accion == 1) {
                this.$swal({
                  icon: "warning",
                  title: "Advertencia!",
                  text: response.data.mensaje,
                  confirmButtonText: "Aceptar!",
                }).then((result) => {
                  if (result.value) {
                    this.$refs.closeModal.click();
                    this.$parent.getIndex();
                    this.form = {};
                  }
                });
              } else {
                this.$refs.closeModal.click();
                this.$parent.getIndex();
                this.form = {};
                this.$swal({
                  icon: "success",
                  title: response.data.mensaje,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            });
        }
      });
    },

    async llenar_modal_inspeccion_support(
      viajeId,
      sitio,
      vehiculo,
      numEmpresa,
      empresa_id,
      viaje
    ) {
      this.viaje = viaje;
      this.habilitarCheck = false;

      this.form = {
        viaje_id: viajeId,
        sitio_id: sitio,
        vehiculo: vehiculo,
        empresa_id: empresa_id,
        num_empresa: numEmpresa,
      };
      await this.getInspeSupport();
    },

    async getInspeSupport() {
      let me = this;
      me.$parent.cargando = true;
      await axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/inspeccionSupport",
          {
            params: me.form,
          }
        )
        .then(async (response) => {
          me.correosEmpresa = await response.data.correoEmpresa;
          me.inspeccionesSupport = await response.data.insSupport;
          if (response.data.insSupport) {
            me.inspeId = me.inspeccionesSupport.id;
            me.inspeccionesSupport = await response.data.insSupport;
          } else {
            me.inspeccionesSupport = {};
          }
          me.$parent.cargando = false;
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      me.$parent.cargando = false;
    },

    inspeccionPreoperacional(viaje) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          viaje: viaje,
          sitio_type: this.$parent.filtros.sitio_type,
          sitio: this.$parent.slct_sitio,
          val: 1,
        },
      });
    },

    mailSolicitar(viaje_id) {
      let me = this;
      me.$parent.cargando = true;
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/mailSolicitarInspeccion",
          {
            params: {
              viaje_id: viaje_id,
              accion: 1,
            },
          }
        )
        .then((response) => {
          me.$parent.cargando = false;
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          this.form = {};
          if (response.data.msg) {
            me.$swal({
              icon: "success",
              title: response.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else if (response.data.error) {
            me.$swal({
              icon: "warning",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.$parent.cargando = false;
        });
    },

    verPDF(inspeccion_id) {
      this.$swal({
        customClass: {
          container: "my-swal",
        },
        title:
          "Por favor revisar minuciosamente la inspección del vehículo. Es importante destacar que usted será responsable del vehículo una vez que se complete la inspección.",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Ver!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios({
            method: "get",
            url: "/api/hidrocarburos/inspecciones/pdf/" + inspeccion_id,
          })
            .then((response) => {
              let data = response.data;
              this.$parent.cargando = false;
              this.habilitarCheck = true;
              window.open(data.url, "_blank");
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            });
        }
      });
    },

    save() {
      this.form.inspeccion_id = this.viaje.inspecciones[0].id;
      let text = null;
      if (this.form.verifica === true) {
        text = "Esta seguro de Aprobar la inspección ?";
      } else {
        text = "Esta seguro de Rechazar la inspección ?";
      }

      this.$swal({
        customClass: {
          container: "my-swal",
        },
        title: text,
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      })
        .then((result) => {
          if (result.value) {
            axios({
              method: "POST",
              url:
                "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarEstadoInspeccion",
              data: this.form,
            }).then((response) => {
              this.$refs.closeModal.click();
              this.$parent.getIndex();
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          }
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    edit(viaje, inspeccion_id) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          inspeccion_id: inspeccion_id,
          viaje: viaje,
        },
      });
    },
  },
  mounted() {},
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
