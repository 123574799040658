var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-detViaje","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Detalle Viaje")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"},on:{"click":_vm.limpiarModal}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body p-0 pt-1"},[(_vm.viaje)?_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(0),(_vm.viaje.tipo_operacion == 1)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-PuntosGestion","data-toggle":"tab","href":"#PuntosGestion"},on:{"click":function($event){return _vm.getDatosPuntos()}}},[_vm._v("Puntos Gestión")])]):_vm._e(),(_vm.viaje.tipo_operacion == 1)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Inspecciones","data-toggle":"tab","href":"#Inspecciones"},on:{"click":function($event){return _vm.getDatosInspecciones()}}},[_vm._v("Inspecciones")])]):_vm._e(),(
              _vm.viaje &&
              _vm.viaje.tipo_operacion &&
              ((_vm.viaje.tipo_operacion == 1 && _vm.viaje.liquidacion_guia) ||
                (_vm.viaje.tipo_operacion == 3 &&
                  _vm.viaje.guia &&
                  _vm.viaje.guia.liquidacion_guia))
            )?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Liquidacion","data-toggle":"tab","href":"#Liquidacion"},on:{"click":function($event){return _vm.$refs.ViajesInfoLiquidacion.getIndex(_vm.viaje)}}},[_vm._v("Liquidación")])]):_vm._e(),(_vm.viaje.tipo_operacion == 1)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Mapa","data-toggle":"tab","href":"#Mapa"},on:{"click":function($event){return _vm.getDatosMapa()}}},[_vm._v("Mapa")])]):_vm._e(),(_vm.viaje.tipo_operacion == 1 && _vm.link)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-escaner","data-toggle":"tab","href":"#Escaner"},on:{"click":function($event){return _vm.getEscanerGuia()}}},[_vm._v("Escáner Guía")])]):_vm._e(),(
              _vm.viaje &&
              _vm.viaje.det_autorizacion &&
              _vm.viaje.det_autorizacion.length > 0
            )?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-autorizaciones","data-toggle":"tab","href":"#Autorizaciones"}},[_vm._v("Autorizaciones Formato 015")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"InfoViaje"}},[(_vm.viaje_id != null && _vm.viaje)?_c('ViajesInfoViaje',{ref:"ViajesInfoViaje"}):_vm._e()],1),_c('div',{staticClass:"tab-pane",attrs:{"id":"PuntosGestion"}},[_c('div',{staticClass:"card-body"},[(_vm.viaje_id != null && _vm.accion == 2)?_c('ViajesInfoPuntos',{ref:"ViajesInfoPuntos"}):_vm._e()],1)]),_c('div',{staticClass:"tab-pane",attrs:{"id":"Inspecciones"}},[_c('div',{staticClass:"card-body"},[(_vm.viaje_id != null && _vm.accion == 3)?_c('ViajesInfoInspecciones',{ref:"ViajesInfoInspecciones"}):_vm._e()],1)]),_c('div',{staticClass:"tab-pane",attrs:{"id":"Liquidacion"}},[_c('div',{staticClass:"card-body"},[(
                  _vm.viaje &&
                  _vm.viaje.tipo_operacion &&
                  ((_vm.viaje.tipo_operacion == 1 && _vm.viaje.liquidacion_guia) ||
                    (_vm.viaje.tipo_operacion == 3 &&
                      _vm.viaje.guia &&
                      _vm.viaje.guia.liquidacion_guia))
                )?_c('ViajesInfoLiquidacion',{ref:"ViajesInfoLiquidacion"}):_vm._e()],1)]),_c('div',{staticClass:"tab-pane",attrs:{"id":"Mapa"}},[_c('div',{staticClass:"card-body"},[(_vm.viaje_id != null && _vm.accion == 5)?_c('ViajesMapa',{ref:"ViajesMapa"}):_vm._e()],1)]),(_vm.visualizacion)?_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Escaner"}},[_c('div',{staticClass:"card-body"},[(_vm.tipo_archivo == 'pdf')?_c('iframe',{staticStyle:{"width":"100%","height":"600px"},attrs:{"src":_vm.visualizacion,"frameborder":"0"}}):_vm._e(),(_vm.tipo_archivo == 'img')?_c('img',{staticStyle:{"width":"100%","height":"600px"},attrs:{"src":_vm.visualizacion}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"tab-pane",attrs:{"id":"Autorizaciones"}},[_c('div',{staticClass:"card-body"},[(
                  _vm.viaje &&
                  _vm.viaje.det_autorizacion &&
                  _vm.viaje.det_autorizacion.length > 0
                )?_c('ViajeAutorizaciones',{ref:"ViajeAutorizaciones"}):_vm._e()],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-InfoViaje","data-toggle":"tab","href":"#InfoViaje"}},[_vm._v("Información Viaje")])])
}]

export { render, staticRenderFns }