<template>
  <div v-if="$parent.det_mapa.length > 0">
    <br />
    <div class="row">
      <div class="btn-group float-right pl-2">
        <button
          type="button"
          class="btn btn-default btn-lg"
          style="cursor: pointer"
          data-toggle="tooltip"
          data-placement="right"
          title="Traza Viaje"
        >
          <h3 style="font-size: 15px">
            <input
              type="checkbox"
              v-model="form.traza"
              @change="getTrazabilidad()"
            />
            <b>
              <i style="font-size: 25px" class="fas fa-map-marked-alt p-1"></i>
            </b>
          </h3>
        </button>
      </div>
      <div class="btn-group float-right pl-2">
        <button
          type="button"
          class="btn btn-default btn-lg"
          style="cursor: pointer"
          data-toggle="tooltip"
          data-placement="right"
          title="Traza Novedades"
        >
          <h3 style="font-size: 15px">
            <input
              type="checkbox"
              v-model="form.novedades"
              @change="getNovedades()"
            />
            <b>
              <i
                style="font-size: 25px"
                class="fas fa-exclamation-triangle"
              ></i>
            </b>
          </h3>
        </button>
      </div>
      <div class="btn-group float-right pl-2">
        <button
          type="button"
          class="btn btn-success btn-lg"
          @click="generarListadoExcel()"
        >
          <i class="far fa-file-excel"></i>
        </button>
      </div>
    </div>
    <hr />
    <div class="col">
      <gmap-map :center="center" :zoom="15" style="width: 100%; height: 480px">
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="m.icon"
          :animation="2"
          @click="toggleInfoWindow(m, index, m.info)"
        ></gmap-marker>
        <gmap-marker
          :key="index"
          v-for="(m, index) in markersAd"
          :position="m.position"
          :icon="m.icon"
          :animation="2"
          @click="toggleInfoWindow(m, index, m.info)"
        ></gmap-marker>
        <gmap-marker
          :key="index"
          v-for="(m, index) in markersEm"
          :position="m.position"
          :icon="m.icon"
          :animation="2"
          @click="toggleInfoWindow(m, index, m.info)"
        ></gmap-marker>
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>
        <gmap-polygon
          :options="{ fillColor: '#FF0000' }"
          v-for="poligono in poligonos"
          :key="poligono.id"
          :paths="poligono.path"
          :editable="false"
          :draggable="false"
          @click="toggleInfoWindowPoli(poligono, poligono.id, poligono.info)"
        />
        <gmap-circle
          v-for="circular in circunferencias"
          :key="circular.id"
          :center="circular.center"
          :radius="circular.radius"
          :info="circular.info"
          @click="toggleInfoWindowCir(circular, circular.id, circular.info)"
        ></gmap-circle>
        <gmap-polyline
          v-if="pathRuta.length > 0"
          :options="polylineOptions"
          :path="pathRuta"
          :editable="false"
          ref="polyline"
        >
        </gmap-polyline>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Mapas",

  data() {
    return {
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      form: {
        geocercas: false,
        traza: false,
        novedades: false,
      },
      polylineOptions: {
        strokeColor: "#ff1c79",
        strokeOpacity: "0.7",
        strokeWeight: 8,
      },
      center: { lat: 4.651371, lng: -74.071509 },
      markers: [],
      markersAd: [],
      markersEm: [],
      pathRuta: [],
      pathPoli: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      path: [],
    };
  },

  methods: {
    async getTrazabilidad() {
      this.puntosTraza = [];
      this.markers = [];
      if (this.form.traza) {
        this.puntosTraza = await this.$parent.det_mapa;
        this.trazaViaje();
        await this.getRuta();
      }
    },

    trazaViaje() {
      if (this.puntosTraza.length > 0) {
        for (let index = 0; index < this.puntosTraza.length; index++) {
          const marker = {
            lat: parseFloat(this.puntosTraza[index].latitud),
            lng: parseFloat(this.puntosTraza[index].longitud),
          };
          this.markers.push({
            id: this.puntosTraza[index].id,
            position: marker,
            icon: "/img/icon_punt_ruta.png",
            info: this.puntosTraza[index].ventana,
          });
        }
        this.puntoInicialLat = parseFloat(this.puntosTraza[0].latitud);
        this.puntoInicialLng = parseFloat(this.puntosTraza[0].longitud);
        this.infoPuntoInicial = this.puntosTraza[0].ventana;

        this.markers.push({
          id: this.puntosTraza[0].id,
          position: {
            lat: this.puntoInicialLat,
            lng: this.puntoInicialLng,
          },
          icon: "/img/icon_punto_g_ok.png",
          info: this.infoPuntoInicial,
        });

        this.puntofin = this.puntosTraza.slice(-1);
        this.puntoFinalLat = parseFloat(this.puntofin[0].latitud);
        this.puntoFinalLng = parseFloat(this.puntofin[0].longitud);
        this.infoPuntoFinal = this.puntofin[0].ventana;

        this.markers.push({
          id: this.puntofin[0].id,
          position: {
            lat: this.puntoFinalLat,
            lng: this.puntoFinalLng,
          },
          icon: "/img/icon_punto_g.png",
          info: this.infoPuntoFinal,
        });

        const marker1 = {
          lat: this.puntoFinalLat,
          lng: this.puntoFinalLng,
        };
        this.center = marker1;
      } else {
        this.$refs.closeModal.click();
        this.$swal({
          title: "Tramas no encontradas!",
          text: "No se encontraron tramas para este viaje",
          icon: "warning",
        });
      }
    },

    async getNovedades() {
      this.path = [];
      this.markersAd = [];
      this.markersEm = [];
      this.$parent.cargando = true;
      let me = this;
      await axios
        .get("/api/hidrocarburos/reportenovedades/Mapa", {
          params: { viaje: this.$parent.viaje_id },
        })
        .then(async function(response) {
          me.markersAd = [];
          me.markersEm = [];

          const datosNovedades = response.data.advertencia;
          if (me.form.novedades == true) {
            await datosNovedades.forEach((nov) => {
              if (nov.latitud != null && nov.longitud != null) {
                let ventana =
                  '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
                  nov.vehiculo.placa +
                  "</h3><hr><b>Fecha novedad: </b> " +
                  nov.fecha_novedad +
                  "<br> <b>Viaje: </b>" +
                  nov.viaje_id +
                  "<br> <b>Transportadora: </b>" +
                  (nov.viaje.transportadora
                    ? nov.viaje.transportadora.razon_social
                    : "Sin asignar") +
                  "<br> <b>Posicion: </b>" +
                  nov.latitud +
                  ", " +
                  nov.longitud +
                  "<br> <b>Velocidad: </b> " +
                  nov.velocidad +
                  " Km/h" +
                  "<br> <b>Novedad: </b>" +
                  nov.nTipoNovedad +
                  " - " +
                  nov.nSubNovedad;
                const marker = {
                  lat: parseFloat(nov.latitud),
                  lng: parseFloat(nov.longitud),
                };
                me.markersAd.push({
                  position: marker,
                  icon: nov.icon,
                  info: ventana,
                  center: {
                    marker,
                  },
                });
              }
            });

            const datosNovedadesEmerg = response.data.emergencia;
            await datosNovedadesEmerg.forEach((nov) => {
              if (nov.latitud != null && nov.longitud != null) {
                let ventana =
                  '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
                  nov.vehiculo.placa +
                  "</h3><hr><b>Fecha novedad: </b> " +
                  nov.fecha_novedad +
                  "<br> <b>Viaje: </b>" +
                  nov.viaje_id +
                  "<br> <b>Transportadora: </b>" +
                  (nov.viaje.transportadora
                    ? nov.viaje.transportadora.razon_social
                    : "Sin asignar") +
                  "<br> <b>Posicion: </b>" +
                  nov.latitud +
                  ", " +
                  nov.longitud +
                  "<br> <b>Velocidad: </b> " +
                  nov.velocidad +
                  " Km/h" +
                  "<br> <b>Novedad: </b>" +
                  nov.nTipoNovedad +
                  " - " +
                  nov.nSubNovedad;
                const marker = {
                  lat: parseFloat(nov.latitud),
                  lng: parseFloat(nov.longitud),
                };
                me.markersEm.push({
                  position: marker,
                  icon: nov.icon,
                  info: ventana,
                  center: {
                    marker,
                  },
                });
              }
            });
          }
          me.$parent.cargando = false;
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getRuta() {
      this.pathRuta = [];
      this.pathPoli = await this.$parent.det_ruta;
      if (this.pathPoli.length > 0) {
        for (let i = 0; i < this.pathPoli.length; i++) {
          if (i == 0) {
            this.pathRuta.push({
              lat: parseFloat(this.pathPoli[i].lat_ini),
              lng: parseFloat(this.pathPoli[i].lon_ini),
            });
          }
          this.pathRuta.push({
            lat: parseFloat(this.pathPoli[i].lat_fin),
            lng: parseFloat(this.pathPoli[i].lon_fin),
          });
        }
      }
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    generarListadoExcel() {
      let viaje_id = this.$parent.viaje_id;
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/viajes/trazaExport",
        data: { viaje_id },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
